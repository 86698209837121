/* RemixDesign | woaichidapi@163.com | Redesigned by JimmyCheung */

.audioplayer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 1em 0;
  padding: 0 24px;
  width: 100%;
  height: 96px;
  align-items: center;
  border: 1px solid #dde2e6;
  border-radius: 50px;
  background: #fff;
}

.audioplayer-playpause {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause {
  background: rgba(91, 130, 255, 0);
  border: 1px solid #5b82ff;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause:hover {
  background: rgba(91, 130, 255, 0.1);
}

.audioplayer-playing .audioplayer-playpause {
  background: rgba(253, 79, 26, 0);
  border: 1px solid #fd4f1a;
}

.audioplayer-playing .audioplayer-playpause:hover {
  background: rgba(235, 79, 26, 0.1);
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
  content: "";
  justify-content: center;
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 7px solid transparent;
  border-right: none;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #0059ff;
}

.audioplayer-playing .audioplayer-playpause a {
  content: "";
  display: flex;
  justify-content: space-between;
  width: 12px;
  height: 14px;
}

.audioplayer-playing .audioplayer-playpause a::before,
.audioplayer-playing .audioplayer-playpause a::after {
  content: "";
  width: 4px;
  height: 14px;
  background-color: #fd4f1a;
}

.audioplayer-time {
  display: flex;
  width: 40px;
  justify-content: center;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
}

.audioplayer-time-current {
  margin-left: 24px;
}

.audioplayer-time-duration {
  margin-right: 24px;
}

.audioplayer-bar {
  position: relative;
  display: flex;
  margin: 0 12px;
  height: 12px;
  flex-basis: 0;
  flex-grow: 1;
  cursor: pointer;
}

.audioplayer-bar::before {
  content: "";
  position: absolute;
  top: 5px;
  width: 100%;
  height: 2px;
  background-color: #dde2e6;
}

.audioplayer-bar > div {
  position: absolute;
  left: 0;
  top: 5px;
}
.audioplayer-bar-loaded {
  z-index: 1;
  height: 2px;
  background: #bec8d2;
}

.audioplayer-bar-played {
  flex-direction: row-reverse;
  z-index: 2;
  height: 2px;
  background: -webkit-linear-gradient(left, #0059ff, #09b1fa);
}

.audioplayer-bar-played::after {
  display: flex;
  position: absolute;
  content: "";
  box-sizing: border-box;
  top: -5px;
  right: -1px;
  margin-right: -5px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 6px;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-bar-played::after {
  border: 2px solid #bec8d2;
}

.audioplayer-playing .audioplayer-bar-played::after {
  border: 2px solid #0059ff;
}

.audioplayer-volume {
  display: flex;
  align-items: center;
}

.audioplayer-volume-button {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.audioplayer-volume-button a {
  display: flex;
  width: 6px;
  height: 8px;
  background-color: #9a9fb0;
  position: relative;
}

.audioplayer-volume-button a:before,
.audioplayer-volume-button a:after {
  content: "";
  position: absolute;
}

.audioplayer-volume-button a:before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 9px solid #9a9fb0;
  border-bottom: 8px solid transparent;
  border-left: none;
  top: -4px;
}

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
  left: 10px;
  top: -2px;
  width: 6px;
  height: 6px;
  border: 6px double #9a9fb0;
  border-width: 6px 6px 0 0;
  border-radius: 0 12px 0 0;
  transform: rotate(45deg);
}

.audioplayer-mute .audioplayer-volume-button a {
  background-color: #fd4f1a;
}

.audioplayer-mute .audioplayer-volume-button a:before {
  border-right: 9px solid #fd4f1a;
}

.audioplayer-volume-adjust {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.audioplayer-volume-adjust > div {
  position: relative;
  display: flex;
  width: 60px;
  height: 2px;
  cursor: pointer;
  background-color: #bec8d2;
}

.audioplayer-volume-adjust div div {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-color: #0059ff;
}

/* responsive | you can change the max-width value to match your theme */

@media screen and (max-width: 679px) {
  .audioplayer-volume-adjust {
    display: none;
  }
}
