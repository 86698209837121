/** ================================= Table Of Contents =================================== /
1. Abstract Css 
    1.1 Function Css 
    1.2 Variables Css 
    1.3 Mixins Css 


2. Component Css
    2.1 Preloader Css
    2.2 Buttons Css
    2.3 Badge Css
    2.4 Table Css
    2.5 Form Css
    2.6 Card Css
    2.7 Modal Css
    2.8 Accordion Css
    2.9 Nav tabs Css
    2.10 Pagination Css
    2.11 Avatar Css
    2.12 List Style Css
    2.13 Apex Chart Css
    2.14 Progress Bar Css
    2.15 Vector map Css
    2.16 Scroll Css
    2.17 Slider Css
    2.18 Dropdown Css
    2.19 Tooltip.scss Css
    2.20 Image upload Css
    2.21 Calendar Css
    2.22 Auth Css
    2.23 Pricing Css
    2.24 Theme Css
    2.25 Date Picker Css
    2.26 Email Css
    2.27 Faq Css
    2.28 Editor Css
    2.29 Chat Css


3. Layout Css
    3.1 Navbar Css
    3.2 Sidebar Css
    3.3 Breadcrumb Css
    3.4 Dashboard Body Css
    3.5 Footer Css


4. Utilities Css
    4.1 Reset Css
    4.2 Color Css
    4.3 Bg Css
    4.4 Shadow Css
    4.5 Size Css
    4.6 Spacing Css
    4.7 Radius Css
    4.8 Animation Css
    4.9 Typography Css
    4.10 Text Align Css
    4.11 Border Css
    4.12 Hover Css
    4.13 Overlay Css
    4.14 Position Css
/ ================================= Table Of Contents =================================== **/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
:root {
  --default-font: ;
  --h1: clamp(2rem, 1.2rem + 4vw, 4.5rem);
  --h2: clamp(1.75rem, 1.11rem + 3.2vw, 3.75rem);
  --h3: clamp(1.5rem, 1.02rem + 2.4vw, 3rem);
  --h4: clamp(1.375rem, 1.095rem + 1.4vw, 2.25rem);
  --h5: clamp(1.25rem, 1.05rem + 1vw, 1.875rem);
  --h6: clamp(1.125rem, 1.005rem + 0.6vw, 1.5rem);
  --size-2: 0.125rem;
  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-9: 0.5625rem;
  --size-10: 0.625rem;
  --size-11: 0.6875rem;
  --size-12: 0.75rem;
  --size-13: 0.8125rem;
  --size-16: 1rem;
  --size-20: 1.25rem;
  --size-24: 1.5rem;
  --size-28: 1.5rem;
  --size-32: 2rem;
  --size-36: 2rem;
  --size-40: 2.5rem;
  --size-44: 2.75rem;
  --size-48: 3rem;
  --size-50: 3.125rem;
  --size-56: 3.5rem;
  --size-60: 3.75rem;
  --size-64: 4rem;
  --size-72: 4.5rem;
  --size-76: 4.75rem;
  --size-80: 5rem;
  --size-90: 5.625rem;
  --size-110: 6.875rem;
  --size-120: 7.5rem;
  --size-144: 9rem;
  --size-160: 10rem;
  --size-170: 10.625rem;
  --size-190: 11.875rem;
  --size-200: 12.5rem;
  --size-240: 15rem;
  --size-440: 27.5rem;
  --rounded-2: 0.125rem;
  --rounded-4: 0.25rem;
  --rounded-6: 0.375rem;
  --rounded-8: 0.5rem;
  --rounded-9: 0.5625rem;
  --rounded-10: 0.625rem;
  --rounded-11: 0.6875rem;
  --rounded-12: 0.75rem;
  --rounded-13: 0.8125rem;
  --rounded-16: 1rem;
  --rounded-20: 1.25rem;
  --rounded-24: 1.5rem;
  --rounded-28: 1.5rem;
  --rounded-32: 2rem;
  --rounded-36: 2rem;
  --rounded-40: 2.5rem;
  --rounded-44: 2.75rem;
  --rounded-48: 3rem;
  --rounded-50: 3.125rem;
  --rounded-56: 3.5rem;
  --rounded-60: 3.75rem;
  --rounded-64: 4rem;
  --rounded-72: 4.5rem;
  --rounded-76: 4.75rem;
  --rounded-80: 5rem;
  --rounded-90: 5.625rem;
  --rounded-110: 6.875rem;
  --rounded-120: 7.5rem;
  --rounded-144: 9rem;
  --rounded-160: 10rem;
  --rounded-170: 10.625rem;
  --rounded-190: 11.875rem;
  --rounded-200: 12.5rem;
  --rounded-240: 15rem;
  --rounded-440: 27.5rem;
  --shadow-1: 0 4px 60px 0 rgba(4, 6, 15, 0.8);
  --shadow-2: 0 4px 60px 0 rgba(4, 6, 15, 0.5);
  --shadow-3: 0 20px 100px 0 rgba(4, 6, 15, 0.8);
  --shadow-4: 4px 8px 24px 0 rgba(182, 182, 182, 0.2);
  --shadow-5: 4px 12px 32px 0 rgba(0, 169, 158, 0.1);
  --shadow-6: 4px 16px 32px 0 rgba(0, 169, 158, 0.1);
  --font-2xxl: 2rem;
  --font-2xl: 1.75rem;
  --font-xxl: 1.5rem;
  --font-xl: 1.25rem;
  --font-lg: 1.125rem;
  --font-md: 1rem;
  --font-sm: 0.875rem;
  --font-xs: 0.75rem;
  --font-xxs: 0.625rem;
  --primary-50: #e4f1ff;
  --primary-100: #bfdcff;
  --primary-200: #95c7ff;
  --primary-300: #6bb1ff;
  --primary-400: #519fff;
  --primary-500: #458eff;
  --primary-600: #487fff;
  --primary-700: #486cea;
  --primary-800: #4759d6;
  --primary-900: #4536b6;
  --neutral-50: #f5f6fa;
  --neutral-100: #f3f4f6;
  --neutral-200: #ebecef;
  --neutral-300: #d1d5db;
  --neutral-400: #9ca3af;
  --neutral-500: #6b7280;
  --neutral-600: #4b5563;
  --neutral-700: #374151;
  --neutral-800: #1f2937;
  --neutral-900: #111827;
  --danger-50: #fef2f2;
  --danger-100: #fee2e2;
  --danger-200: #fecaca;
  --danger-300: #fca5a5;
  --danger-400: #f87171;
  --danger-500: #ef4444;
  --danger-600: #dc2626;
  --danger-700: #b91c1c;
  --danger-800: #991b1b;
  --danger-900: #7f1d1d;
  --success-50: #f0fdf4;
  --success-100: #dcfce7;
  --success-200: #bbf7d0;
  --success-300: #86efac;
  --success-400: #4ade80;
  --success-500: #22c55e;
  --success-600: #16a34a;
  --success-700: #15803d;
  --success-800: #166534;
  --success-900: #14532d;
  --warning-50: #fefce8;
  --warning-100: #fef9c3;
  --warning-200: #fef08a;
  --warning-300: #fde047;
  --warning-400: #facc15;
  --warning-500: #eab308;
  --warning-600: #ff9f29;
  --warning-700: #f39016;
  --warning-800: #e58209;
  --warning-900: #d77907;
  --info-50: #eff6ff;
  --info-100: #dbeafe;
  --info-200: #bfdbfe;
  --info-300: #93c5fd;
  --info-400: #60a5fa;
  --info-500: #3b82f6;
  --info-600: #2563eb;
  --info-700: #1d4ed8;
  --info-800: #1e40af;
  --info-900: #1e3a8a;
  --dark-1: #1b2431;
  --dark-2: #273142;
  --dark-3: #323d4e;
  --lilac-50: #f0e1ff;
  --lilac-100: #ebd7ff;
  --lilac-600: #8252e9;
  --lilac-700: #6f37e6;
  --lilac-800: #601eef;
  --light-50: #f5f6fa;
  --light-100: #f3f4f6;
  --light-600: #e4f1ff;
  --light-700: #374151;
  --light-800: #1f2937;
  --success-main: #45b369;
  --success-surface: #daf0e1;
  --success-border: #b5e1c3;
  --success-hover: #009f5e;
  --success-pressed: #006038;
  --success-focus: rgba(69, 179, 105, 0.1490196078);
  --info-main: #144bd6;
  --info-surface: #e7eefc;
  --info-border: #aecafc;
  --info-hover: #0a51ce;
  --info-pressed: #06307c;
  --info-focus: rgba(20, 75, 214, 0.1490196078);
  --warning-main: #ff9f29;
  --warning-surface: #fff9e2;
  --warning-border: #ffeba6;
  --warning-hover: #d69705;
  --warning-pressed: #c28800;
  --warning-focus: rgba(255, 192, 45, 0.1490196078);
  --danger-main: #ef4a00;
  --danger-surface: #fcdae2;
  --danger-border: #f9b5c6;
  --danger-hover: #d53128;
  --danger-pressed: #801d18;
  --danger-focus: rgba(239, 71, 112, 0.1490196078);
  --cyan: #00b8f2;
  --indigo: #7f27ff;
  --purple: #8252e9;
  --red: #e30a0a;
  --yellow: #f4941e;
  --orange: #f86624;
  --pink: #de3ace;
  --primary-light: rgba(72, 127, 255, 0.15);
  --yellow-light: rgba(255, 159, 41, 0.15);
  --purple-light: rgba(130, 82, 233, 0.15);
  --pink-light: rgba(250, 54, 230, 0.15);
  --primary-light-white: rgba(72, 127, 255, 0.25);
  --yellow-light-white: rgba(255, 159, 41, 0.25);
  --purple-light-white: rgba(132, 90, 223, 0.25);
  --pink-light-white: rgba(250, 54, 230, 0.25);
  --base: #fff;
  --brand: var(--primary-600);
  --button-secondary: var(--primary-50);
  --black: var(--dark-2);
  --white: var(--base);
  --bg-color: var(--neutral-50);
  --text-primary-light: var(--neutral-900);
  --text-secondary-light: var(--neutral-600);
  --text-secondary-dark: var(--neutral-300);
  --input-form-light: var(--neutral-300);
  --input-form-dark: var(--neutral-500);
  --input-bg: var(--neutral-50);
  --input-stroke: var(--neutral-300);
  --border-color: #d1d5db80;
}

[data-theme="dark"] {
  --button-secondary: var(--neutral-300);
  --black: var(--base);
  --white: var(--dark-2);
  --bg-color: var(--dark-1);
  --text-primary-light: var(--base);
  --text-secondary-light: #d1d5db;
  --text-secondary-dark: var(--dark-2);
  --input-form-light: #6b7280;
  --input-form-dark: #d1d5db;
  --input-bg: var(--dark-3);
  --input-stroke: #f3f4f6;
  --primary-50: #e4f1ff0f;
  --info-50: #eff6ff0f;
  --warning-50: #fefce80f;
  --success-50: #f0fdf40f;
  --danger-50: #fef2f20f;
  --lilac-100: #ebd7ff0f;
  --success-100: #dcfce70f;
  --danger-100: rgba(239, 71, 112, 0.1490196078);
  --border-color: #d1d5db33;
  --neutral-50: #1b2431;
  --neutral-100: #273142;
  --neutral-200: #323d4e;
  --neutral-300: #4b5563;
  --neutral-400: #6b7280;
  --neutral-500: #9ca3af;
  --neutral-600: #d1d5db;
  --neutral-700: #ebecef;
  --neutral-800: #f3f4f6;
  --neutral-900: #f5f6fa;
}

/* === reset css start === */
/* === reset css start === */
*:where(
    :not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)
  ) {
  all: unset;
  display: revert;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  /* transition: all 0.3s; */
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  color: var(--text-secondary-light);
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  overflow-x: hidden;
}

body.overlay-active::after {
  width: 100%;
}

body::after {
  position: absolute;
  content: "";
  top: 0;
  inset-inline-start: 0;
  width: 0;
  height: 100%;
  background-color: #000;
  opacity: 0.65;
  /* transition: all 0.3s; */
  z-index: 2;
}

main {
  display: block;
}

footer {
  margin-top: auto;
}

img {
  max-width: 100%;
  height: auto;
  user-select: none;
}

select {
  cursor: pointer;
}

dt {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
  opacity: 0.15;
}

pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

address {
  font-style: inherit;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
}

button:focus {
  outline: none;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
  color: inherit;
}

a,
button {
  cursor: revert;
}

a:hover {
  text-decoration: none;
}

strong {
  font-weight: 700;
}

a:hover {
  color: var(--primary);
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

svg,
img,
embed,
object,
iframe {
  vertical-align: bottom;
}

button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

button:disabled,
[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled {
  cursor: default;
}

:-moz-focusring {
  outline: auto;
}

select:disabled {
  opacity: inherit;
}

option {
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[type="number"] {
  -moz-appearance: textfield;
}

label[for] {
  cursor: pointer;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[contenteditable]:focus {
  outline: auto;
}

table {
  border-color: inherit;
  border-collapse: collapse;
}

caption {
  text-align: left;
}

td,
th {
  vertical-align: top;
  padding: 0;
}

th {
  text-align: left;
  font-weight: bold;
}

/* === reset css end === */
/* === reset css end === */
/* === color css start === */
.text-primary-50 {
  color: var(--primary-50) !important;
}

.text-primary-100 {
  color: var(--primary-100) !important;
}

.text-primary-200 {
  color: var(--primary-200) !important;
}

.text-primary-300 {
  color: var(--primary-300) !important;
}

.text-primary-400 {
  color: var(--primary-400) !important;
}

.text-primary-500 {
  color: var(--primary-500) !important;
}

.text-primary-600 {
  color: var(--primary-600) !important;
}

.text-primary-700 {
  color: var(--primary-700) !important;
}

.text-primary-800 {
  color: var(--primary-800) !important;
}

.text-primary-900 {
  color: var(--primary-900) !important;
}

.text-neutral-50 {
  color: var(--neutral-50) !important;
}

.text-neutral-100 {
  color: var(--neutral-100) !important;
}

.text-neutral-200 {
  color: var(--neutral-200) !important;
}

.text-neutral-300 {
  color: var(--neutral-300) !important;
}

.text-neutral-400 {
  color: var(--neutral-400) !important;
}

.text-neutral-500 {
  color: var(--neutral-500) !important;
}

.text-neutral-600 {
  color: var(--neutral-600) !important;
}

.text-neutral-700 {
  color: var(--neutral-700) !important;
}

.text-neutral-800 {
  color: var(--neutral-800) !important;
}

.text-neutral-900 {
  color: var(--neutral-900) !important;
}

.text-danger-50 {
  color: var(--danger-50) !important;
}

.text-danger-100 {
  color: var(--danger-100) !important;
}

.text-danger-200 {
  color: var(--danger-200) !important;
}

.text-danger-300 {
  color: var(--danger-300) !important;
}

.text-danger-400 {
  color: var(--danger-400) !important;
}

.text-danger-500 {
  color: var(--danger-500) !important;
}

.text-danger-600 {
  color: var(--danger-600) !important;
}

.text-danger-700 {
  color: var(--danger-700) !important;
}

.text-danger-800 {
  color: var(--danger-800) !important;
}

.text-danger-900 {
  color: var(--danger-900) !important;
}

.text-success-50 {
  color: var(--success-50) !important;
}

.text-success-100 {
  color: var(--success-100) !important;
}

.text-success-200 {
  color: var(--success-200) !important;
}

.text-success-300 {
  color: var(--success-300) !important;
}

.text-success-400 {
  color: var(--success-400) !important;
}

.text-success-500 {
  color: var(--success-500) !important;
}

.text-success-600 {
  color: var(--success-600) !important;
}

.text-success-700 {
  color: var(--success-700) !important;
}

.text-success-800 {
  color: var(--success-800) !important;
}

.text-success-900 {
  color: var(--success-900) !important;
}

.text-warning-50 {
  color: var(--warning-50) !important;
}

.text-warning-100 {
  color: var(--warning-100) !important;
}

.text-warning-200 {
  color: var(--warning-200) !important;
}

.text-warning-300 {
  color: var(--warning-300) !important;
}

.text-warning-400 {
  color: var(--warning-400) !important;
}

.text-warning-500 {
  color: var(--warning-500) !important;
}

.text-warning-600 {
  color: var(--warning-600) !important;
}

.text-warning-700 {
  color: var(--warning-700) !important;
}

.text-warning-800 {
  color: var(--warning-800) !important;
}

.text-warning-900 {
  color: var(--warning-900) !important;
}

.text-info-50 {
  color: var(--info-50) !important;
}

.text-info-100 {
  color: var(--info-100) !important;
}

.text-info-200 {
  color: var(--info-200) !important;
}

.text-info-300 {
  color: var(--info-300) !important;
}

.text-info-400 {
  color: var(--info-400) !important;
}

.text-info-500 {
  color: var(--info-500) !important;
}

.text-info-600 {
  color: var(--info-600) !important;
}

.text-info-700 {
  color: var(--info-700) !important;
}

.text-info-800 {
  color: var(--info-800) !important;
}

.text-info-900 {
  color: var(--info-900) !important;
}

.text-dark-1 {
  color: var(--dark-1) !important;
}

.text-dark-2 {
  color: var(--dark-2) !important;
}

.text-dark-3 {
  color: var(--dark-3) !important;
}

.text-lilac-50 {
  color: var(--lilac-50) !important;
}

.text-lilac-100 {
  color: var(--lilac-100) !important;
}

.text-lilac-600 {
  color: var(--lilac-600) !important;
}

.text-lilac-700 {
  color: var(--lilac-700) !important;
}

.text-lilac-800 {
  color: var(--lilac-800) !important;
}

.text-light-50 {
  color: var(--light-50) !important;
}

.text-light-100 {
  color: var(--light-100) !important;
}

.text-light-600 {
  color: var(--light-600) !important;
}

.text-light-700 {
  color: var(--light-700) !important;
}

.text-light-800 {
  color: var(--light-800) !important;
}

.text-hover-primary-50:hover {
  color: var(--primary-50) !important;
}

.text-hover-primary-100:hover {
  color: var(--primary-100) !important;
}

.text-hover-primary-200:hover {
  color: var(--primary-200) !important;
}

.text-hover-primary-300:hover {
  color: var(--primary-300) !important;
}

.text-hover-primary-400:hover {
  color: var(--primary-400) !important;
}

.text-hover-primary-500:hover {
  color: var(--primary-500) !important;
}

.text-hover-primary-600:hover {
  color: var(--primary-600) !important;
}

.text-hover-primary-700:hover {
  color: var(--primary-700) !important;
}

.text-hover-primary-800:hover {
  color: var(--primary-800) !important;
}

.text-hover-primary-900:hover {
  color: var(--primary-900) !important;
}

.text-hover-neutral-50:hover {
  color: var(--neutral-50) !important;
}

.text-hover-neutral-100:hover {
  color: var(--neutral-100) !important;
}

.text-hover-neutral-200:hover {
  color: var(--neutral-200) !important;
}

.text-hover-neutral-300:hover {
  color: var(--neutral-300) !important;
}

.text-hover-neutral-400:hover {
  color: var(--neutral-400) !important;
}

.text-hover-neutral-500:hover {
  color: var(--neutral-500) !important;
}

.text-hover-neutral-600:hover {
  color: var(--neutral-600) !important;
}

.text-hover-neutral-700:hover {
  color: var(--neutral-700) !important;
}

.text-hover-neutral-800:hover {
  color: var(--neutral-800) !important;
}

.text-hover-neutral-900:hover {
  color: var(--neutral-900) !important;
}

.text-hover-danger-50:hover {
  color: var(--danger-50) !important;
}

.text-hover-danger-100:hover {
  color: var(--danger-100) !important;
}

.text-hover-danger-200:hover {
  color: var(--danger-200) !important;
}

.text-hover-danger-300:hover {
  color: var(--danger-300) !important;
}

.text-hover-danger-400:hover {
  color: var(--danger-400) !important;
}

.text-hover-danger-500:hover {
  color: var(--danger-500) !important;
}

.text-hover-danger-600:hover {
  color: var(--danger-600) !important;
}

.text-hover-danger-700:hover {
  color: var(--danger-700) !important;
}

.text-hover-danger-800:hover {
  color: var(--danger-800) !important;
}

.text-hover-danger-900:hover {
  color: var(--danger-900) !important;
}

.text-hover-success-50:hover {
  color: var(--success-50) !important;
}

.text-hover-success-100:hover {
  color: var(--success-100) !important;
}

.text-hover-success-200:hover {
  color: var(--success-200) !important;
}

.text-hover-success-300:hover {
  color: var(--success-300) !important;
}

.text-hover-success-400:hover {
  color: var(--success-400) !important;
}

.text-hover-success-500:hover {
  color: var(--success-500) !important;
}

.text-hover-success-600:hover {
  color: var(--success-600) !important;
}

.text-hover-success-700:hover {
  color: var(--success-700) !important;
}

.text-hover-success-800:hover {
  color: var(--success-800) !important;
}

.text-hover-success-900:hover {
  color: var(--success-900) !important;
}

.text-hover-warning-50:hover {
  color: var(--warning-50) !important;
}

.text-hover-warning-100:hover {
  color: var(--warning-100) !important;
}

.text-hover-warning-200:hover {
  color: var(--warning-200) !important;
}

.text-hover-warning-300:hover {
  color: var(--warning-300) !important;
}

.text-hover-warning-400:hover {
  color: var(--warning-400) !important;
}

.text-hover-warning-500:hover {
  color: var(--warning-500) !important;
}

.text-hover-warning-600:hover {
  color: var(--warning-600) !important;
}

.text-hover-warning-700:hover {
  color: var(--warning-700) !important;
}

.text-hover-warning-800:hover {
  color: var(--warning-800) !important;
}

.text-hover-warning-900:hover {
  color: var(--warning-900) !important;
}

.text-hover-info-50:hover {
  color: var(--info-50) !important;
}

.text-hover-info-100:hover {
  color: var(--info-100) !important;
}

.text-hover-info-200:hover {
  color: var(--info-200) !important;
}

.text-hover-info-300:hover {
  color: var(--info-300) !important;
}

.text-hover-info-400:hover {
  color: var(--info-400) !important;
}

.text-hover-info-500:hover {
  color: var(--info-500) !important;
}

.text-hover-info-600:hover {
  color: var(--info-600) !important;
}

.text-hover-info-700:hover {
  color: var(--info-700) !important;
}

.text-hover-info-800:hover {
  color: var(--info-800) !important;
}

.text-hover-info-900:hover {
  color: var(--info-900) !important;
}

.text-hover-dark-1:hover {
  color: var(--dark-1) !important;
}

.text-hover-dark-2:hover {
  color: var(--dark-2) !important;
}

.text-hover-dark-3:hover {
  color: var(--dark-3) !important;
}

.text-hover-lilac-50:hover {
  color: var(--lilac-50) !important;
}

.text-hover-lilac-100:hover {
  color: var(--lilac-100) !important;
}

.text-hover-lilac-600:hover {
  color: var(--lilac-600) !important;
}

.text-hover-lilac-700:hover {
  color: var(--lilac-700) !important;
}

.text-hover-lilac-800:hover {
  color: var(--lilac-800) !important;
}

.text-hover-light-50:hover {
  color: var(--light-50) !important;
}

.text-hover-light-100:hover {
  color: var(--light-100) !important;
}

.text-hover-light-600:hover {
  color: var(--light-600) !important;
}

.text-hover-light-700:hover {
  color: var(--light-700) !important;
}

.text-hover-light-800:hover {
  color: var(--light-800) !important;
}

.text-success-main {
  color: var(--success-main);
}

.text-success-surface {
  color: var(--success-surface);
}

.text-success-border {
  color: var(--success-border);
}

.text-success-hover {
  color: var(--success-hover);
}

.text-success-pressed {
  color: var(--success-pressed);
}

.text-success-focus {
  color: var(--success-focus);
}

.text-info-main {
  color: var(--info-main);
}

.text-info-surface {
  color: var(--info-surface);
}

.text-info-border {
  color: var(--info-border);
}

.text-info-hover {
  color: var(--info-hover);
}

.text-info-pressed {
  color: var(--info-pressed);
}

.text-info-focus {
  color: var(--info-focus);
}

.text-warning-main {
  color: var(--warning-main);
}

.text-warning-surface {
  color: var(--warning-surface);
}

.text-warning-border {
  color: var(--warning-border);
}

.text-warning-hover {
  color: var(--warning-hover);
}

.text-warning-pressed {
  color: var(--warning-pressed);
}

.text-warning-focus {
  color: var(--warning-focus);
}

.text-danger-main {
  color: var(--danger-main);
}

.text-danger-surface {
  color: var(--danger-surface);
}

.text-danger-border {
  color: var(--danger-border);
}

.text-danger-hover {
  color: var(--danger-hover);
}

.text-danger-pressed {
  color: var(--danger-pressed);
}

.text-danger-focus {
  color: var(--danger-focus);
}

.text-cyan {
  color: var(--cyan);
}

.text-indigo {
  color: var(--indigo);
}

.text-purple {
  color: var(--purple);
}

.text-red {
  color: var(--red);
}

.text-yellow {
  color: var(--yellow);
}

.text-orange {
  color: var(--orange);
}

.text-pink {
  color: var(--pink);
}

.text-primary-light {
  color: var(--text-primary-light) !important;
}

.text-secondary-light {
  color: var(--text-secondary-light);
}

.text-secondary-dark {
  color: var(--text-secondary-dark);
}

.text-base {
  color: var(--white) !important;
}

.text-black {
  color: var(--black) !important;
}

.hover-text-primary:hover,
.btn.hover-text-primary:hover {
  color: var(--primary-600) !important;
}

.hover-text-success:hover,
.btn.hover-text-success:hover {
  color: var(--success-main) !important;
}

.hover-text-info:hover,
.btn.hover-text-info:hover {
  color: var(--info-main) !important;
}

.hover-text-warning:hover,
.btn.hover-text-warning:hover {
  color: var(--warning-main) !important;
}

.hover-text-danger:hover,
.btn.hover-text-danger:hover {
  color: var(--danger-main) !important;
}

.hover-text-white:hover,
.btn.hover-text-white:hover {
  color: var(--base) !important;
}

/* === color css end === */
/* === bg css start === */
.bg-primary-50 {
  background-color: var(--primary-50) !important;
}

.bg-primary-100 {
  background-color: var(--primary-100) !important;
}

.bg-primary-200 {
  background-color: var(--primary-200) !important;
}

.bg-primary-300 {
  background-color: var(--primary-300) !important;
}

.bg-primary-400 {
  background-color: var(--primary-400) !important;
}

.bg-primary-500 {
  background-color: var(--primary-500) !important;
}

.bg-primary-600 {
  background-color: var(--primary-600) !important;
}

.bg-primary-700 {
  background-color: var(--primary-700) !important;
}

.bg-primary-800 {
  background-color: var(--primary-800) !important;
}

.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.bg-neutral-50 {
  background-color: var(--neutral-50) !important;
}

.bg-neutral-100 {
  background-color: var(--neutral-100) !important;
}

.bg-neutral-200 {
  background-color: var(--neutral-200) !important;
}

.bg-neutral-300 {
  background-color: var(--neutral-300) !important;
}

.bg-neutral-400 {
  background-color: var(--neutral-400) !important;
}

.bg-neutral-500 {
  background-color: var(--neutral-500) !important;
}

.bg-neutral-600 {
  background-color: var(--neutral-600) !important;
}

.bg-neutral-700 {
  background-color: var(--neutral-700) !important;
}

.bg-neutral-800 {
  background-color: var(--neutral-800) !important;
}

.bg-neutral-900 {
  background-color: var(--neutral-900) !important;
}

.bg-danger-50 {
  background-color: var(--danger-50) !important;
}

.bg-danger-100 {
  background-color: var(--danger-100) !important;
}

.bg-danger-200 {
  background-color: var(--danger-200) !important;
}

.bg-danger-300 {
  background-color: var(--danger-300) !important;
}

.bg-danger-400 {
  background-color: var(--danger-400) !important;
}

.bg-danger-500 {
  background-color: var(--danger-500) !important;
}

.bg-danger-600 {
  background-color: var(--danger-600) !important;
}

.bg-danger-700 {
  background-color: var(--danger-700) !important;
}

.bg-danger-800 {
  background-color: var(--danger-800) !important;
}

.bg-danger-900 {
  background-color: var(--danger-900) !important;
}

.bg-success-50 {
  background-color: var(--success-50) !important;
}

.bg-success-100 {
  background-color: var(--success-100) !important;
}

.bg-success-200 {
  background-color: var(--success-200) !important;
}

.bg-success-300 {
  background-color: var(--success-300) !important;
}

.bg-success-400 {
  background-color: var(--success-400) !important;
}

.bg-success-500 {
  background-color: var(--success-500) !important;
}

.bg-success-600 {
  background-color: var(--success-600) !important;
}

.bg-success-700 {
  background-color: var(--success-700) !important;
}

.bg-success-800 {
  background-color: var(--success-800) !important;
}

.bg-success-900 {
  background-color: var(--success-900) !important;
}

.bg-warning-50 {
  background-color: var(--warning-50) !important;
}

.bg-warning-100 {
  background-color: var(--warning-100) !important;
}

.bg-warning-200 {
  background-color: var(--warning-200) !important;
}

.bg-warning-300 {
  background-color: var(--warning-300) !important;
}

.bg-warning-400 {
  background-color: var(--warning-400) !important;
}

.bg-warning-500 {
  background-color: var(--warning-500) !important;
}

.bg-warning-600 {
  background-color: var(--warning-600) !important;
}

.bg-warning-700 {
  background-color: var(--warning-700) !important;
}

.bg-warning-800 {
  background-color: var(--warning-800) !important;
}

.bg-warning-900 {
  background-color: var(--warning-900) !important;
}

.bg-info-50 {
  background-color: var(--info-50) !important;
}

.bg-info-100 {
  background-color: var(--info-100) !important;
}

.bg-info-200 {
  background-color: var(--info-200) !important;
}

.bg-info-300 {
  background-color: var(--info-300) !important;
}

.bg-info-400 {
  background-color: var(--info-400) !important;
}

.bg-info-500 {
  background-color: var(--info-500) !important;
}

.bg-info-600 {
  background-color: var(--info-600) !important;
}

.bg-info-700 {
  background-color: var(--info-700) !important;
}

.bg-info-800 {
  background-color: var(--info-800) !important;
}

.bg-info-900 {
  background-color: var(--info-900) !important;
}

.bg-dark-1 {
  background-color: var(--dark-1) !important;
}

.bg-dark-2 {
  background-color: var(--dark-2) !important;
}

.bg-dark-3 {
  background-color: var(--dark-3) !important;
}

.bg-lilac-50 {
  background-color: var(--lilac-50) !important;
}

.bg-lilac-100 {
  background-color: var(--lilac-100) !important;
}

.bg-lilac-600 {
  background-color: var(--lilac-600) !important;
}

.bg-lilac-700 {
  background-color: var(--lilac-700) !important;
}

.bg-lilac-800 {
  background-color: var(--lilac-800) !important;
}

.bg-light-50 {
  background-color: var(--light-50) !important;
}

.bg-light-100 {
  background-color: var(--light-100) !important;
}

.bg-light-600 {
  background-color: var(--light-600) !important;
}

.bg-light-700 {
  background-color: var(--light-700) !important;
}

.bg-light-800 {
  background-color: var(--light-800) !important;
}

.bg-success-main {
  background-color: var(--success-main) !important;
}

.bg-success-surface {
  background-color: var(--success-surface) !important;
}

.bg-success-border {
  background-color: var(--success-border) !important;
}

.bg-success-hover {
  background-color: var(--success-hover) !important;
}

.bg-success-pressed {
  background-color: var(--success-pressed) !important;
}

.bg-success-focus {
  background-color: var(--success-focus) !important;
}

.bg-info-main {
  background-color: var(--info-main) !important;
}

.bg-info-surface {
  background-color: var(--info-surface) !important;
}

.bg-info-border {
  background-color: var(--info-border) !important;
}

.bg-info-hover {
  background-color: var(--info-hover) !important;
}

.bg-info-pressed {
  background-color: var(--info-pressed) !important;
}

.bg-info-focus {
  background-color: var(--info-focus) !important;
}

.bg-warning-main {
  background-color: var(--warning-main) !important;
}

.bg-warning-surface {
  background-color: var(--warning-surface) !important;
}

.bg-warning-border {
  background-color: var(--warning-border) !important;
}

.bg-warning-hover {
  background-color: var(--warning-hover) !important;
}

.bg-warning-pressed {
  background-color: var(--warning-pressed) !important;
}

.bg-warning-focus {
  background-color: var(--warning-focus) !important;
}

.bg-danger-main {
  background-color: var(--danger-main) !important;
}

.bg-danger-surface {
  background-color: var(--danger-surface) !important;
}

.bg-danger-border {
  background-color: var(--danger-border) !important;
}

.bg-danger-hover {
  background-color: var(--danger-hover) !important;
}

.bg-danger-pressed {
  background-color: var(--danger-pressed) !important;
}

.bg-danger-focus {
  background-color: var(--danger-focus) !important;
}

.bg-cyan {
  background-color: var(--cyan) !important;
}

.bg-indigo {
  background-color: var(--indigo) !important;
}

.bg-purple {
  background-color: var(--purple) !important;
}

.bg-red {
  background-color: var(--red) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
}

.bg-pink {
  background-color: var(--pink) !important;
}

.bg-hover-primary-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-50:hover {
  background-color: var(--primary-50) !important;
}

.bg-hover-primary-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-100:hover {
  background-color: var(--primary-100) !important;
}

.bg-hover-primary-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-200:hover {
  background-color: var(--primary-200) !important;
}

.bg-hover-primary-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-300:hover {
  background-color: var(--primary-300) !important;
}

.bg-hover-primary-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-400:hover {
  background-color: var(--primary-400) !important;
}

.bg-hover-primary-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-500:hover {
  background-color: var(--primary-500) !important;
}

.bg-hover-primary-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-600:hover {
  background-color: var(--primary-600) !important;
}

.bg-hover-primary-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-700:hover {
  background-color: var(--primary-700) !important;
}

.bg-hover-primary-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-800:hover {
  background-color: var(--primary-800) !important;
}

.bg-hover-primary-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.bg-hover-neutral-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-50:hover {
  background-color: var(--neutral-50) !important;
}

.bg-hover-neutral-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-100:hover {
  background-color: var(--neutral-100) !important;
}

.bg-hover-neutral-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-200:hover {
  background-color: var(--neutral-200) !important;
}

.bg-hover-neutral-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-300:hover {
  background-color: var(--neutral-300) !important;
}

.bg-hover-neutral-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-400:hover {
  background-color: var(--neutral-400) !important;
}

.bg-hover-neutral-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-500:hover {
  background-color: var(--neutral-500) !important;
}

.bg-hover-neutral-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-600:hover {
  background-color: var(--neutral-600) !important;
}

.bg-hover-neutral-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-700:hover {
  background-color: var(--neutral-700) !important;
}

.bg-hover-neutral-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-800:hover {
  background-color: var(--neutral-800) !important;
}

.bg-hover-neutral-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-neutral-900:hover {
  background-color: var(--neutral-900) !important;
}

.bg-hover-danger-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-50:hover {
  background-color: var(--danger-50) !important;
}

.bg-hover-danger-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-100:hover {
  background-color: var(--danger-100) !important;
}

.bg-hover-danger-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-200:hover {
  background-color: var(--danger-200) !important;
}

.bg-hover-danger-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-300:hover {
  background-color: var(--danger-300) !important;
}

.bg-hover-danger-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-400:hover {
  background-color: var(--danger-400) !important;
}

.bg-hover-danger-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-500:hover {
  background-color: var(--danger-500) !important;
}

.bg-hover-danger-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-600:hover {
  background-color: var(--danger-600) !important;
}

.bg-hover-danger-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-700:hover {
  background-color: var(--danger-700) !important;
}

.bg-hover-danger-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-800:hover {
  background-color: var(--danger-800) !important;
}

.bg-hover-danger-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-900:hover {
  background-color: var(--danger-900) !important;
}

.bg-hover-success-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-50:hover {
  background-color: var(--success-50) !important;
}

.bg-hover-success-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-100:hover {
  background-color: var(--success-100) !important;
}

.bg-hover-success-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-200:hover {
  background-color: var(--success-200) !important;
}

.bg-hover-success-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-300:hover {
  background-color: var(--success-300) !important;
}

.bg-hover-success-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-400:hover {
  background-color: var(--success-400) !important;
}

.bg-hover-success-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-500:hover {
  background-color: var(--success-500) !important;
}

.bg-hover-success-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-600:hover {
  background-color: var(--success-600) !important;
}

.bg-hover-success-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-700:hover {
  background-color: var(--success-700) !important;
}

.bg-hover-success-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-800:hover {
  background-color: var(--success-800) !important;
}

.bg-hover-success-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-900:hover {
  background-color: var(--success-900) !important;
}

.bg-hover-warning-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-50:hover {
  background-color: var(--warning-50) !important;
}

.bg-hover-warning-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-100:hover {
  background-color: var(--warning-100) !important;
}

.bg-hover-warning-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-200:hover {
  background-color: var(--warning-200) !important;
}

.bg-hover-warning-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-300:hover {
  background-color: var(--warning-300) !important;
}

.bg-hover-warning-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-400:hover {
  background-color: var(--warning-400) !important;
}

.bg-hover-warning-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-500:hover {
  background-color: var(--warning-500) !important;
}

.bg-hover-warning-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-600:hover {
  background-color: var(--warning-600) !important;
}

.bg-hover-warning-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-700:hover {
  background-color: var(--warning-700) !important;
}

.bg-hover-warning-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-800:hover {
  background-color: var(--warning-800) !important;
}

.bg-hover-warning-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-900:hover {
  background-color: var(--warning-900) !important;
}

.bg-hover-info-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-50:hover {
  background-color: var(--info-50) !important;
}

.bg-hover-info-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-100:hover {
  background-color: var(--info-100) !important;
}

.bg-hover-info-200 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-200:hover {
  background-color: var(--info-200) !important;
}

.bg-hover-info-300 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-300:hover {
  background-color: var(--info-300) !important;
}

.bg-hover-info-400 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-400:hover {
  background-color: var(--info-400) !important;
}

.bg-hover-info-500 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-500:hover {
  background-color: var(--info-500) !important;
}

.bg-hover-info-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-600:hover {
  background-color: var(--info-600) !important;
}

.bg-hover-info-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-700:hover {
  background-color: var(--info-700) !important;
}

.bg-hover-info-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-800:hover {
  background-color: var(--info-800) !important;
}

.bg-hover-info-900 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-900:hover {
  background-color: var(--info-900) !important;
}

.bg-hover-dark-1 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-dark-1:hover {
  background-color: var(--dark-1) !important;
}

.bg-hover-dark-2 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-dark-2:hover {
  background-color: var(--dark-2) !important;
}

.bg-hover-dark-3 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-dark-3:hover {
  background-color: var(--dark-3) !important;
}

.bg-hover-lilac-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-lilac-50:hover {
  background-color: var(--lilac-50) !important;
}

.bg-hover-lilac-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-lilac-100:hover {
  background-color: var(--lilac-100) !important;
}

.bg-hover-lilac-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-lilac-600:hover {
  background-color: var(--lilac-600) !important;
}

.bg-hover-lilac-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-lilac-700:hover {
  background-color: var(--lilac-700) !important;
}

.bg-hover-lilac-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-lilac-800:hover {
  background-color: var(--lilac-800) !important;
}

.bg-hover-light-50 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light-50:hover {
  background-color: var(--light-50) !important;
}

.bg-hover-light-100 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light-100:hover {
  background-color: var(--light-100) !important;
}

.bg-hover-light-600 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light-600:hover {
  background-color: var(--light-600) !important;
}

.bg-hover-light-700 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light-700:hover {
  background-color: var(--light-700) !important;
}

.bg-hover-light-800 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-light-800:hover {
  background-color: var(--light-800) !important;
}

.bg-hover-success-main {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-main:hover {
  background-color: var(--success-main) !important;
}

.bg-hover-success-surface {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-surface:hover {
  background-color: var(--success-surface) !important;
}

.bg-hover-success-border {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-border:hover {
  background-color: var(--success-border) !important;
}

.bg-hover-success-hover {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-hover:hover {
  background-color: var(--success-hover) !important;
}

.bg-hover-success-pressed {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-pressed:hover {
  background-color: var(--success-pressed) !important;
}

.bg-hover-success-focus {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-success-focus:hover {
  background-color: var(--success-focus) !important;
}

.bg-hover-info-main {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-main:hover {
  background-color: var(--info-main) !important;
}

.bg-hover-info-surface {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-surface:hover {
  background-color: var(--info-surface) !important;
}

.bg-hover-info-border {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-border:hover {
  background-color: var(--info-border) !important;
}

.bg-hover-info-hover {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-hover:hover {
  background-color: var(--info-hover) !important;
}

.bg-hover-info-pressed {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-pressed:hover {
  background-color: var(--info-pressed) !important;
}

.bg-hover-info-focus {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-info-focus:hover {
  background-color: var(--info-focus) !important;
}

.bg-hover-warning-main {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-main:hover {
  background-color: var(--warning-main) !important;
}

.bg-hover-warning-surface {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-surface:hover {
  background-color: var(--warning-surface) !important;
}

.bg-hover-warning-border {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-border:hover {
  background-color: var(--warning-border) !important;
}

.bg-hover-warning-hover {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-hover:hover {
  background-color: var(--warning-hover) !important;
}

.bg-hover-warning-pressed {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-pressed:hover {
  background-color: var(--warning-pressed) !important;
}

.bg-hover-warning-focus {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-warning-focus:hover {
  background-color: var(--warning-focus) !important;
}

.bg-hover-danger-main {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-main:hover {
  background-color: var(--danger-main) !important;
}

.bg-hover-danger-surface {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-surface:hover {
  background-color: var(--danger-surface) !important;
}

.bg-hover-danger-border {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-border:hover {
  background-color: var(--danger-border) !important;
}

.bg-hover-danger-hover {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-hover:hover {
  background-color: var(--danger-hover) !important;
}

.bg-hover-danger-pressed {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-pressed:hover {
  background-color: var(--danger-pressed) !important;
}

.bg-hover-danger-focus {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-danger-focus:hover {
  background-color: var(--danger-focus) !important;
}

.bg-hover-cyan {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-cyan:hover {
  background-color: var(--cyan) !important;
}

.bg-hover-indigo {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-indigo:hover {
  background-color: var(--indigo) !important;
}

.bg-hover-purple {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-purple:hover {
  background-color: var(--purple) !important;
}

.bg-hover-red {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-red:hover {
  background-color: var(--red) !important;
}

.bg-hover-yellow {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-yellow:hover {
  background-color: var(--yellow) !important;
}

.bg-hover-orange {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-orange:hover {
  background-color: var(--orange) !important;
}

.bg-hover-pink {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-hover-pink:hover {
  background-color: var(--pink) !important;
}

.bg-base {
  background-color: var(--white) !important;
}

.bg-base-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.hover-bg-transparent {
  background-color: transparent !important;
}

.hover-bg-white:hover {
  background-color: var(--white) !important;
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-yellow-light {
  background-color: var(--yellow-light) !important;
}

.bg-purple-light {
  background-color: var(--purple-light) !important;
}

.bg-pink-light {
  background-color: var(--pink-light) !important;
}

.bg-gradient-start-1 {
  background: linear-gradient(to right, #e6f9ff, #feffff);
}

.bg-gradient-start-2 {
  background: linear-gradient(to right, #f7e9ff, #fffefd);
}

.bg-gradient-start-3 {
  background: linear-gradient(to right, #e6ebff, #ffffff);
}

.bg-gradient-start-4 {
  background: linear-gradient(to right, #e8fff5, #ffffff);
}

.bg-gradient-start-5 {
  background: linear-gradient(to right, #ffeeee, #fffcfc);
}

.bg-gradient-dark-start-1 {
  background: linear-gradient(
    261deg,
    rgba(255, 234, 244, 0.5) 2.07%,
    #ffe2f0 97.73%
  );
}

.bg-gradient-dark-start-2 {
  background: linear-gradient(
    262deg,
    rgba(236, 221, 255, 0.3) 2.45%,
    #ecddff 97.35%
  );
}

.bg-gradient-dark-start-3 {
  background: linear-gradient(262deg, #ebfaff 4.01%, #c0f0ff 99.29%);
}

.bg-gradient-end-1 {
  background: linear-gradient(to right, #ffffff, #eff4ff);
}

.bg-gradient-end-2 {
  background: linear-gradient(to right, #ffffff, #eafff9);
}

.bg-gradient-end-3 {
  background: linear-gradient(to right, #ffffff, #fff5e9);
}

.bg-gradient-end-4 {
  background: linear-gradient(to right, #ffffff, #f3eeff);
}

.bg-gradient-end-5 {
  background: linear-gradient(to right, #ffffff, #fff2fe);
}

.bg-gradient-end-6 {
  background: linear-gradient(to right, #ffffff, #eefbff);
}

.bg-gradient-purple {
  background: linear-gradient(300deg, #ffe9e0 1.27%, #efd3ff 98.89%);
}

.bg-gradient-primary {
  background: linear-gradient(299deg, #d7f6ff 1.03%, #d1deff 97.72%);
}

.bg-gradient-success {
  background: linear-gradient(299deg, #ecfff7 1.76%, #adf7d6 98.11%);
}

.bg-gradient-danger {
  background: linear-gradient(299deg, #ffefef 0.96%, #ffd7d7 98.97%);
}

.bg-primary-gradient {
  background: linear-gradient(299deg, #d7f6ff 1.03%, #d1deff 97.72%);
}

.bg-success-gradient {
  background: linear-gradient(270deg, #70e396 0%, #45b369 100%);
}

.bg-info-gradient {
  background: linear-gradient(270deg, #85a7ff 0%, #144bd6 100%);
}

.bg-warning-gradient {
  background: linear-gradient(270deg, #ffd199 0%, #ff9f29 100%);
}

.bg-danger-gradient {
  background: linear-gradient(270deg, #ffab86 0%, #ef4a00 100%);
}

.bg-primary-success-gradient {
  background: linear-gradient(90deg, #bbcaff 0%, #dcfffd 100%);
}

.bg-dark-primary-gradient {
  background: linear-gradient(270deg, #7ea5ff 0%, #003dcc 100%);
}

.bg-dark-lilac-gradient {
  background: linear-gradient(270deg, #ba76ff 0%, #6100c1 100%);
}

.bg-dark-success-gradient {
  background: linear-gradient(270deg, #48dc79 0%, #02862d 100%);
}

.bg-dark-info-gradient {
  background: linear-gradient(270deg, #5384ff 0%, #0036bd 100%);
}

.bg-dark-warning-gradient {
  background: linear-gradient(270deg, #ffc175 0%, #c36c00 100%);
}

.bg-dark-danger-gradient {
  background: linear-gradient(270deg, #ff7739 0%, #c63d00 100%);
}

.bg-dark-dark-gradient {
  background: linear-gradient(90deg, #273142 0%, #637da8 100%);
}

.bg-danger-gradient-light {
  background: linear-gradient(90deg, #f7e9ff 0.12%, #fdf8f7 99.89%) !important;
}

.bg-white-gradient-light {
  background: linear-gradient(
    317deg,
    rgba(225, 225, 225, 0.1) 8.56%,
    rgba(255, 255, 255, 0.5) 91.49%
  ) !important;
}

.bg-light-pink {
  background: var(
    --gradients-Colors-gradients-2,
    linear-gradient(90deg, #f7e9ff 0.12%, #fdf8f7 99.89%)
  );
}

html[data-theme="dark"] .bg-base-50 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

html[data-theme="dark"] .bg-gradient-start-1 {
  background: linear-gradient(
    to right,
    rgba(230, 249, 255, 0.15),
    rgba(254, 255, 255, 0.1)
  );
}

html[data-theme="dark"] .bg-gradient-start-2 {
  background: linear-gradient(
    to right,
    rgba(247, 233, 255, 0.15),
    rgba(255, 254, 253, 0.1)
  );
}

html[data-theme="dark"] .bg-gradient-start-3 {
  background: linear-gradient(
    to right,
    rgba(230, 235, 255, 0.15),
    rgba(255, 255, 255, 0.1)
  );
}

html[data-theme="dark"] .bg-gradient-start-4 {
  background: linear-gradient(
    to right,
    rgba(232, 255, 245, 0.15),
    rgba(255, 255, 255, 0.1)
  );
}

html[data-theme="dark"] .bg-gradient-start-5 {
  background: linear-gradient(
    to right,
    rgba(255, 238, 238, 0.15),
    rgba(255, 252, 252, 0.1)
  );
}

html[data-theme="dark"] .bg-gradient-dark-start-1 {
  background: linear-gradient(
    261deg,
    rgba(255, 234, 244, 0.08) 2.07%,
    rgba(255, 226, 240, 0.15) 97.73%
  );
}

html[data-theme="dark"] .bg-gradient-dark-start-2 {
  background: linear-gradient(
    262deg,
    rgba(236, 221, 255, 0.05) 2.45%,
    rgba(236, 221, 255, 0.15) 97.35%
  );
}

html[data-theme="dark"] .bg-gradient-dark-start-3 {
  background: linear-gradient(
    262deg,
    rgba(235, 250, 255, 0.15) 4.01%,
    rgba(192, 240, 255, 0.15) 99.29%
  );
}

html[data-theme="dark"] .bg-gradient-end-1 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(239, 244, 255, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-end-2 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(234, 255, 249, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-end-3 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(255, 245, 233, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-end-4 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(243, 238, 255, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-end-5 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(255, 242, 254, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-end-6 {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(238, 251, 255, 0.125)
  );
}

html[data-theme="dark"] .bg-gradient-purple {
  background: linear-gradient(
    300deg,
    rgba(255, 233, 224, 0.1) 1.27%,
    rgba(239, 211, 255, 0.15) 98.89%
  );
}

html[data-theme="dark"] .bg-gradient-primary {
  background: linear-gradient(
    299deg,
    rgba(215, 246, 255, 0.1) 1.03%,
    rgba(209, 222, 255, 0.15) 97.72%
  );
}

html[data-theme="dark"] .bg-gradient-success {
  background: linear-gradient(
    299deg,
    rgba(236, 255, 247, 0.1) 1.76%,
    rgba(173, 247, 214, 0.15) 98.11%
  );
}

html[data-theme="dark"] .bg-gradient-danger {
  background: linear-gradient(
    299deg,
    rgba(255, 239, 239, 0.1) 0.96%,
    rgba(255, 215, 215, 0.15) 98.97%
  );
}

html[data-theme="dark"] .bg-danger-gradient-light {
  background: linear-gradient(
    90deg,
    rgba(247, 233, 255, 0.05) 0.12%,
    rgba(253, 248, 247, 0.1) 99.89%
  ) !important;
}

html[data-theme="dark"] .bg-white-gradient-light {
  background: linear-gradient(
    317deg,
    rgba(225, 225, 225, 0.05) 8.56%,
    rgba(255, 255, 255, 0.1) 91.49%
  ) !important;
}

html[data-theme="dark"] .bg-light-pink {
  background: var(
    --gradients-Colors-gradients-2,
    linear-gradient(
      90deg,
      rgba(247, 233, 255, 0.1) 0.12%,
      rgba(253, 248, 247, 0.05) 99.89%
    )
  );
}

/* === bg css end === */
/* === shadow css start === */
.shadow-1 {
  box-shadow: var(--shadow-1);
}

.shadow-2 {
  box-shadow: var(--shadow-2);
}

.shadow-3 {
  box-shadow: var(--shadow-3);
}

.shadow-4 {
  box-shadow: var(--shadow-4);
}

.shadow-5 {
  box-shadow: var(--shadow-5);
}

.shadow-6 {
  box-shadow: var(--shadow-6);
}

.bordered-shadow {
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5019607843);
}

.shadow-top {
  box-shadow: 0px -4px 30px 0px rgba(46, 45, 116, 0.05);
}

/* === shadow css end === */
/* === size css start === */
.w-2-px {
  width: 0.125rem !important;
}

.w-4-px {
  width: 0.25rem !important;
}

.w-6-px {
  width: 0.375rem !important;
}

.w-8-px {
  width: 0.5rem !important;
}

.w-9-px {
  width: 0.5625rem !important;
}

.w-10-px {
  width: 0.625rem !important;
}

.w-11-px {
  width: 0.6875rem !important;
}

.w-12-px {
  width: 0.75rem !important;
}

.w-13-px {
  width: 0.8125rem !important;
}

.w-16-px {
  width: 1rem !important;
}

.w-20-px {
  width: 1.25rem !important;
}

.w-24-px {
  width: 1.5rem !important;
}

.w-28-px {
  width: 1.5rem !important;
}

.w-32-px {
  width: 2rem !important;
}

.w-36-px {
  width: 2rem !important;
}

.w-40-px {
  width: 2.5rem !important;
}

.w-44-px {
  width: 2.75rem !important;
}

.w-48-px {
  width: 3rem !important;
}

.w-50-px {
  width: 3.125rem !important;
}

.w-56-px {
  width: 3.5rem !important;
}

.w-60-px {
  width: 3.75rem !important;
}

.w-64-px {
  width: 4rem !important;
}

.w-72-px {
  width: 4.5rem !important;
}

.w-76-px {
  width: 4.75rem !important;
}

.w-80-px {
  width: 5rem !important;
}

.w-90-px {
  width: 5.625rem !important;
}

.w-110-px {
  width: 6.875rem !important;
}

.w-120-px {
  width: 7.5rem !important;
}

.w-144-px {
  width: 9rem !important;
}

.w-160-px {
  width: 10rem !important;
}

.w-170-px {
  width: 10.625rem !important;
}

.w-190-px {
  width: 11.875rem !important;
}

.w-200-px {
  width: 12.5rem !important;
}

.w-240-px {
  width: 15rem !important;
}

.w-440-px {
  width: 27.5rem !important;
}

.w-100-px {
  width: 100px;
}

.h-100-px {
  height: 100px;
}

.min-w-max-content {
  min-width: max-content;
}

.w-190-px {
  width: 190px;
}

.max-w-740-px {
  max-width: 740px;
}

@media (max-width: 1399px) {
  .max-w-740-px {
    max-width: 500px;
  }
}
@media (max-width: 991px) {
  .max-w-740-px {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .max-w-740-px {
    max-width: 250px;
  }
}
.textarea-max-height {
  height: 44px;
}

.resize-none {
  resize: none;
}

.min-w-450-px {
  min-width: 450px;
}

.min-w-132-px {
  min-width: 132px;
}

.h-2-px {
  height: 0.125rem !important;
}

.h-4-px {
  height: 0.25rem !important;
}

.h-6-px {
  height: 0.375rem !important;
}

.h-8-px {
  height: 0.5rem !important;
}

.h-9-px {
  height: 0.5625rem !important;
}

.h-10-px {
  height: 0.625rem !important;
}

.h-11-px {
  height: 0.6875rem !important;
}

.h-12-px {
  height: 0.75rem !important;
}

.h-13-px {
  height: 0.8125rem !important;
}

.h-16-px {
  height: 1rem !important;
}

.h-20-px {
  height: 1.25rem !important;
}

.h-24-px {
  height: 1.5rem !important;
}

.h-28-px {
  height: 1.5rem !important;
}

.h-32-px {
  height: 2rem !important;
}

.h-36-px {
  height: 2rem !important;
}

.h-40-px {
  height: 2.5rem !important;
}

.h-44-px {
  height: 2.75rem !important;
}

.h-48-px {
  height: 3rem !important;
}

.h-50-px {
  height: 3.125rem !important;
}

.h-56-px {
  height: 3.5rem !important;
}

.h-60-px {
  height: 3.75rem !important;
}

.h-64-px {
  height: 4rem !important;
}

.h-72-px {
  height: 4.5rem !important;
}

.h-76-px {
  height: 4.75rem !important;
}

.h-80-px {
  height: 5rem !important;
}

.h-90-px {
  height: 5.625rem !important;
}

.h-110-px {
  height: 6.875rem !important;
}

.h-120-px {
  height: 7.5rem !important;
}

.h-144-px {
  height: 9rem !important;
}

.h-160-px {
  height: 10rem !important;
}

.h-170-px {
  height: 10.625rem !important;
}

.h-190-px {
  height: 11.875rem !important;
}

.h-200-px {
  height: 12.5rem !important;
}

.h-240-px {
  height: 15rem !important;
}

.h-440-px {
  height: 27.5rem !important;
}

.max-h-258-px {
  max-height: 258px;
}

.max-h-350-px {
  max-height: 350px;
}

.max-h-266-px {
  max-height: 266px;
}

.max-h-400-px {
  max-height: 400px;
}

.max-h-612-px {
  max-height: 612px !important;
}

.min-h-612-px {
  min-height: 612px !important;
}

/* Min max width & height Start */
.min-w-120-px {
  min-width: 120px;
}

.max-w-150-px {
  max-width: 150px;
}

.max-w-440-px {
  max-width: 440px;
}

.max-w-464-px {
  max-width: 464px;
}

.max-w-500-px {
  max-width: 500px;
}

.max-w-290-px {
  max-width: 290px;
}

.max-w-634-px {
  max-width: 634px;
}

/* Min max width & height End */
.text-w-200-px {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-w-100-px {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-auto {
  top: auto;
}

.bottom-3px {
  bottom: 3px;
}

@media (min-width: 1700px) {
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
}
.kanban-wrapper {
  min-width: 1560px;
}

@media (min-width: 425px) and (max-width: 575px) {
  .col-xs-6 {
    width: 50%;
  }
}
.transition-2 {
  transition: 0.2s !important;
}

/* === size css end === */
/* === spacing css start === */
.m-2 {
  margin: var(--size-2);
}

.m-4 {
  margin: var(--size-4);
}

.m-6 {
  margin: var(--size-6);
}

.m-8 {
  margin: var(--size-8);
}

.m-9 {
  margin: var(--size-9);
}

.m-10 {
  margin: var(--size-10);
}

.m-11 {
  margin: var(--size-11);
}

.m-12 {
  margin: var(--size-12);
}

.m-13 {
  margin: var(--size-13);
}

.m-16 {
  margin: var(--size-16);
}

.m-20 {
  margin: var(--size-20);
}

.m-24 {
  margin: var(--size-24);
}

.m-28 {
  margin: var(--size-28);
}

.m-32 {
  margin: var(--size-32);
}

.m-36 {
  margin: var(--size-36);
}

.m-40 {
  margin: var(--size-40);
}

.m-44 {
  margin: var(--size-44);
}

.m-48 {
  margin: var(--size-48);
}

.m-50 {
  margin: var(--size-50);
}

.m-56 {
  margin: var(--size-56);
}

.m-60 {
  margin: var(--size-60);
}

.m-64 {
  margin: var(--size-64);
}

.m-72 {
  margin: var(--size-72);
}

.m-76 {
  margin: var(--size-76);
}

.m-80 {
  margin: var(--size-80);
}

.m-90 {
  margin: var(--size-90);
}

.m-110 {
  margin: var(--size-110);
}

.m-120 {
  margin: var(--size-120);
}

.m-144 {
  margin: var(--size-144);
}

.m-160 {
  margin: var(--size-160);
}

.m-170 {
  margin: var(--size-170);
}

.m-190 {
  margin: var(--size-190);
}

.m-200 {
  margin: var(--size-200);
}

.m-240 {
  margin: var(--size-240);
}

.m-440 {
  margin: var(--size-440);
}

.mx-2 {
  margin-inline: var(--size-2) !important;
}

.mx-4 {
  margin-inline: var(--size-4) !important;
}

.mx-6 {
  margin-inline: var(--size-6) !important;
}

.mx-8 {
  margin-inline: var(--size-8) !important;
}

.mx-9 {
  margin-inline: var(--size-9) !important;
}

.mx-10 {
  margin-inline: var(--size-10) !important;
}

.mx-11 {
  margin-inline: var(--size-11) !important;
}

.mx-12 {
  margin-inline: var(--size-12) !important;
}

.mx-13 {
  margin-inline: var(--size-13) !important;
}

.mx-16 {
  margin-inline: var(--size-16) !important;
}

.mx-20 {
  margin-inline: var(--size-20) !important;
}

.mx-24 {
  margin-inline: var(--size-24) !important;
}

.mx-28 {
  margin-inline: var(--size-28) !important;
}

.mx-32 {
  margin-inline: var(--size-32) !important;
}

.mx-36 {
  margin-inline: var(--size-36) !important;
}

.mx-40 {
  margin-inline: var(--size-40) !important;
}

.mx-44 {
  margin-inline: var(--size-44) !important;
}

.mx-48 {
  margin-inline: var(--size-48) !important;
}

.mx-50 {
  margin-inline: var(--size-50) !important;
}

.mx-56 {
  margin-inline: var(--size-56) !important;
}

.mx-60 {
  margin-inline: var(--size-60) !important;
}

.mx-64 {
  margin-inline: var(--size-64) !important;
}

.mx-72 {
  margin-inline: var(--size-72) !important;
}

.mx-76 {
  margin-inline: var(--size-76) !important;
}

.mx-80 {
  margin-inline: var(--size-80) !important;
}

.mx-90 {
  margin-inline: var(--size-90) !important;
}

.mx-110 {
  margin-inline: var(--size-110) !important;
}

.mx-120 {
  margin-inline: var(--size-120) !important;
}

.mx-144 {
  margin-inline: var(--size-144) !important;
}

.mx-160 {
  margin-inline: var(--size-160) !important;
}

.mx-170 {
  margin-inline: var(--size-170) !important;
}

.mx-190 {
  margin-inline: var(--size-190) !important;
}

.mx-200 {
  margin-inline: var(--size-200) !important;
}

.mx-240 {
  margin-inline: var(--size-240) !important;
}

.mx-440 {
  margin-inline: var(--size-440) !important;
}

.my-2 {
  margin-block: var(--size-2) !important;
}

.my-4 {
  margin-block: var(--size-4) !important;
}

.my-6 {
  margin-block: var(--size-6) !important;
}

.my-8 {
  margin-block: var(--size-8) !important;
}

.my-9 {
  margin-block: var(--size-9) !important;
}

.my-10 {
  margin-block: var(--size-10) !important;
}

.my-11 {
  margin-block: var(--size-11) !important;
}

.my-12 {
  margin-block: var(--size-12) !important;
}

.my-13 {
  margin-block: var(--size-13) !important;
}

.my-16 {
  margin-block: var(--size-16) !important;
}

.my-20 {
  margin-block: var(--size-20) !important;
}

.my-24 {
  margin-block: var(--size-24) !important;
}

.my-28 {
  margin-block: var(--size-28) !important;
}

.my-32 {
  margin-block: var(--size-32) !important;
}

.my-36 {
  margin-block: var(--size-36) !important;
}

.my-40 {
  margin-block: var(--size-40) !important;
}

.my-44 {
  margin-block: var(--size-44) !important;
}

.my-48 {
  margin-block: var(--size-48) !important;
}

.my-50 {
  margin-block: var(--size-50) !important;
}

.my-56 {
  margin-block: var(--size-56) !important;
}

.my-60 {
  margin-block: var(--size-60) !important;
}

.my-64 {
  margin-block: var(--size-64) !important;
}

.my-72 {
  margin-block: var(--size-72) !important;
}

.my-76 {
  margin-block: var(--size-76) !important;
}

.my-80 {
  margin-block: var(--size-80) !important;
}

.my-90 {
  margin-block: var(--size-90) !important;
}

.my-110 {
  margin-block: var(--size-110) !important;
}

.my-120 {
  margin-block: var(--size-120) !important;
}

.my-144 {
  margin-block: var(--size-144) !important;
}

.my-160 {
  margin-block: var(--size-160) !important;
}

.my-170 {
  margin-block: var(--size-170) !important;
}

.my-190 {
  margin-block: var(--size-190) !important;
}

.my-200 {
  margin-block: var(--size-200) !important;
}

.my-240 {
  margin-block: var(--size-240) !important;
}

.my-440 {
  margin-block: var(--size-440) !important;
}

.ms-2 {
  margin-inline-start: var(--size-2) !important;
}

.ms-4 {
  margin-inline-start: var(--size-4) !important;
}

.ms-6 {
  margin-inline-start: var(--size-6) !important;
}

.ms-8 {
  margin-inline-start: var(--size-8) !important;
}

.ms-9 {
  margin-inline-start: var(--size-9) !important;
}

.ms-10 {
  margin-inline-start: var(--size-10) !important;
}

.ms-11 {
  margin-inline-start: var(--size-11) !important;
}

.ms-12 {
  margin-inline-start: var(--size-12) !important;
}

.ms-13 {
  margin-inline-start: var(--size-13) !important;
}

.ms-16 {
  margin-inline-start: var(--size-16) !important;
}

.ms-20 {
  margin-inline-start: var(--size-20) !important;
}

.ms-24 {
  margin-inline-start: var(--size-24) !important;
}

.ms-28 {
  margin-inline-start: var(--size-28) !important;
}

.ms-32 {
  margin-inline-start: var(--size-32) !important;
}

.ms-36 {
  margin-inline-start: var(--size-36) !important;
}

.ms-40 {
  margin-inline-start: var(--size-40) !important;
}

.ms-44 {
  margin-inline-start: var(--size-44) !important;
}

.ms-48 {
  margin-inline-start: var(--size-48) !important;
}

.ms-50 {
  margin-inline-start: var(--size-50) !important;
}

.ms-56 {
  margin-inline-start: var(--size-56) !important;
}

.ms-60 {
  margin-inline-start: var(--size-60) !important;
}

.ms-64 {
  margin-inline-start: var(--size-64) !important;
}

.ms-72 {
  margin-inline-start: var(--size-72) !important;
}

.ms-76 {
  margin-inline-start: var(--size-76) !important;
}

.ms-80 {
  margin-inline-start: var(--size-80) !important;
}

.ms-90 {
  margin-inline-start: var(--size-90) !important;
}

.ms-110 {
  margin-inline-start: var(--size-110) !important;
}

.ms-120 {
  margin-inline-start: var(--size-120) !important;
}

.ms-144 {
  margin-inline-start: var(--size-144) !important;
}

.ms-160 {
  margin-inline-start: var(--size-160) !important;
}

.ms-170 {
  margin-inline-start: var(--size-170) !important;
}

.ms-190 {
  margin-inline-start: var(--size-190) !important;
}

.ms-200 {
  margin-inline-start: var(--size-200) !important;
}

.ms-240 {
  margin-inline-start: var(--size-240) !important;
}

.ms-440 {
  margin-inline-start: var(--size-440) !important;
}

.me-2 {
  margin-inline-end: var(--size-2) !important;
}

.me-4 {
  margin-inline-end: var(--size-4) !important;
}

.me-6 {
  margin-inline-end: var(--size-6) !important;
}

.me-8 {
  margin-inline-end: var(--size-8) !important;
}

.me-9 {
  margin-inline-end: var(--size-9) !important;
}

.me-10 {
  margin-inline-end: var(--size-10) !important;
}

.me-11 {
  margin-inline-end: var(--size-11) !important;
}

.me-12 {
  margin-inline-end: var(--size-12) !important;
}

.me-13 {
  margin-inline-end: var(--size-13) !important;
}

.me-16 {
  margin-inline-end: var(--size-16) !important;
}

.me-20 {
  margin-inline-end: var(--size-20) !important;
}

.me-24 {
  margin-inline-end: var(--size-24) !important;
}

.me-28 {
  margin-inline-end: var(--size-28) !important;
}

.me-32 {
  margin-inline-end: var(--size-32) !important;
}

.me-36 {
  margin-inline-end: var(--size-36) !important;
}

.me-40 {
  margin-inline-end: var(--size-40) !important;
}

.me-44 {
  margin-inline-end: var(--size-44) !important;
}

.me-48 {
  margin-inline-end: var(--size-48) !important;
}

.me-50 {
  margin-inline-end: var(--size-50) !important;
}

.me-56 {
  margin-inline-end: var(--size-56) !important;
}

.me-60 {
  margin-inline-end: var(--size-60) !important;
}

.me-64 {
  margin-inline-end: var(--size-64) !important;
}

.me-72 {
  margin-inline-end: var(--size-72) !important;
}

.me-76 {
  margin-inline-end: var(--size-76) !important;
}

.me-80 {
  margin-inline-end: var(--size-80) !important;
}

.me-90 {
  margin-inline-end: var(--size-90) !important;
}

.me-110 {
  margin-inline-end: var(--size-110) !important;
}

.me-120 {
  margin-inline-end: var(--size-120) !important;
}

.me-144 {
  margin-inline-end: var(--size-144) !important;
}

.me-160 {
  margin-inline-end: var(--size-160) !important;
}

.me-170 {
  margin-inline-end: var(--size-170) !important;
}

.me-190 {
  margin-inline-end: var(--size-190) !important;
}

.me-200 {
  margin-inline-end: var(--size-200) !important;
}

.me-240 {
  margin-inline-end: var(--size-240) !important;
}

.me-440 {
  margin-inline-end: var(--size-440) !important;
}

.mt-2 {
  margin-block-start: var(--size-2) !important;
}

.mt-4 {
  margin-block-start: var(--size-4) !important;
}

.mt-6 {
  margin-block-start: var(--size-6) !important;
}

.mt-8 {
  margin-block-start: var(--size-8) !important;
}

.mt-9 {
  margin-block-start: var(--size-9) !important;
}

.mt-10 {
  margin-block-start: var(--size-10) !important;
}

.mt-11 {
  margin-block-start: var(--size-11) !important;
}

.mt-12 {
  margin-block-start: var(--size-12) !important;
}

.mt-13 {
  margin-block-start: var(--size-13) !important;
}

.mt-16 {
  margin-block-start: var(--size-16) !important;
}

.mt-20 {
  margin-block-start: var(--size-20) !important;
}

.mt-24 {
  margin-block-start: var(--size-24) !important;
}

.mt-28 {
  margin-block-start: var(--size-28) !important;
}

.mt-32 {
  margin-block-start: var(--size-32) !important;
}

.mt-36 {
  margin-block-start: var(--size-36) !important;
}

.mt-40 {
  margin-block-start: var(--size-40) !important;
}

.mt-44 {
  margin-block-start: var(--size-44) !important;
}

.mt-48 {
  margin-block-start: var(--size-48) !important;
}

.mt-50 {
  margin-block-start: var(--size-50) !important;
}

.mt-56 {
  margin-block-start: var(--size-56) !important;
}

.mt-60 {
  margin-block-start: var(--size-60) !important;
}

.mt-64 {
  margin-block-start: var(--size-64) !important;
}

.mt-72 {
  margin-block-start: var(--size-72) !important;
}

.mt-76 {
  margin-block-start: var(--size-76) !important;
}

.mt-80 {
  margin-block-start: var(--size-80) !important;
}

.mt-90 {
  margin-block-start: var(--size-90) !important;
}

.mt-110 {
  margin-block-start: var(--size-110) !important;
}

.mt-120 {
  margin-block-start: var(--size-120) !important;
}

.mt-144 {
  margin-block-start: var(--size-144) !important;
}

.mt-160 {
  margin-block-start: var(--size-160) !important;
}

.mt-170 {
  margin-block-start: var(--size-170) !important;
}

.mt-190 {
  margin-block-start: var(--size-190) !important;
}

.mt-200 {
  margin-block-start: var(--size-200) !important;
}

.mt-240 {
  margin-block-start: var(--size-240) !important;
}

.mt-440 {
  margin-block-start: var(--size-440) !important;
}

.mb-2 {
  margin-block-end: var(--size-2) !important;
}

.mb-4 {
  margin-block-end: var(--size-4) !important;
}

.mb-6 {
  margin-block-end: var(--size-6) !important;
}

.mb-8 {
  margin-block-end: var(--size-8) !important;
}

.mb-9 {
  margin-block-end: var(--size-9) !important;
}

.mb-10 {
  margin-block-end: var(--size-10) !important;
}

.mb-11 {
  margin-block-end: var(--size-11) !important;
}

.mb-12 {
  margin-block-end: var(--size-12) !important;
}

.mb-13 {
  margin-block-end: var(--size-13) !important;
}

.mb-16 {
  margin-block-end: var(--size-16) !important;
}

.mb-20 {
  margin-block-end: var(--size-20) !important;
}

.mb-24 {
  margin-block-end: var(--size-24) !important;
}

.mb-28 {
  margin-block-end: var(--size-28) !important;
}

.mb-32 {
  margin-block-end: var(--size-32) !important;
}

.mb-36 {
  margin-block-end: var(--size-36) !important;
}

.mb-40 {
  margin-block-end: var(--size-40) !important;
}

.mb-44 {
  margin-block-end: var(--size-44) !important;
}

.mb-48 {
  margin-block-end: var(--size-48) !important;
}

.mb-50 {
  margin-block-end: var(--size-50) !important;
}

.mb-56 {
  margin-block-end: var(--size-56) !important;
}

.mb-60 {
  margin-block-end: var(--size-60) !important;
}

.mb-64 {
  margin-block-end: var(--size-64) !important;
}

.mb-72 {
  margin-block-end: var(--size-72) !important;
}

.mb-76 {
  margin-block-end: var(--size-76) !important;
}

.mb-80 {
  margin-block-end: var(--size-80) !important;
}

.mb-90 {
  margin-block-end: var(--size-90) !important;
}

.mb-110 {
  margin-block-end: var(--size-110) !important;
}

.mb-120 {
  margin-block-end: var(--size-120) !important;
}

.mb-144 {
  margin-block-end: var(--size-144) !important;
}

.mb-160 {
  margin-block-end: var(--size-160) !important;
}

.mb-170 {
  margin-block-end: var(--size-170) !important;
}

.mb-190 {
  margin-block-end: var(--size-190) !important;
}

.mb-200 {
  margin-block-end: var(--size-200) !important;
}

.mb-240 {
  margin-block-end: var(--size-240) !important;
}

.mb-440 {
  margin-block-end: var(--size-440) !important;
}

.p-2 {
  padding: var(--size-2) !important;
}

.p-4 {
  padding: var(--size-4) !important;
}

.p-6 {
  padding: var(--size-6) !important;
}

.p-8 {
  padding: var(--size-8) !important;
}

.p-9 {
  padding: var(--size-9) !important;
}

.p-10 {
  padding: var(--size-10) !important;
}

.p-11 {
  padding: var(--size-11) !important;
}

.p-12 {
  padding: var(--size-12) !important;
}

.p-13 {
  padding: var(--size-13) !important;
}

.p-16 {
  padding: var(--size-16) !important;
}

.p-20 {
  padding: var(--size-20) !important;
}

.p-24 {
  padding: var(--size-24) !important;
}

.p-28 {
  padding: var(--size-28) !important;
}

.p-32 {
  padding: var(--size-32) !important;
}

.p-36 {
  padding: var(--size-36) !important;
}

.p-40 {
  padding: var(--size-40) !important;
}

.p-44 {
  padding: var(--size-44) !important;
}

.p-48 {
  padding: var(--size-48) !important;
}

.p-50 {
  padding: var(--size-50) !important;
}

.p-56 {
  padding: var(--size-56) !important;
}

.p-60 {
  padding: var(--size-60) !important;
}

.p-64 {
  padding: var(--size-64) !important;
}

.p-72 {
  padding: var(--size-72) !important;
}

.p-76 {
  padding: var(--size-76) !important;
}

.p-80 {
  padding: var(--size-80) !important;
}

.p-90 {
  padding: var(--size-90) !important;
}

.p-110 {
  padding: var(--size-110) !important;
}

.p-120 {
  padding: var(--size-120) !important;
}

.p-144 {
  padding: var(--size-144) !important;
}

.p-160 {
  padding: var(--size-160) !important;
}

.p-170 {
  padding: var(--size-170) !important;
}

.p-190 {
  padding: var(--size-190) !important;
}

.p-200 {
  padding: var(--size-200) !important;
}

.p-240 {
  padding: var(--size-240) !important;
}

.p-440 {
  padding: var(--size-440) !important;
}

.px-2 {
  padding-inline: var(--size-2) !important;
}

.px-4 {
  padding-inline: var(--size-4) !important;
}

.px-6 {
  padding-inline: var(--size-6) !important;
}

.px-8 {
  padding-inline: var(--size-8) !important;
}

.px-9 {
  padding-inline: var(--size-9) !important;
}

.px-10 {
  padding-inline: var(--size-10) !important;
}

.px-11 {
  padding-inline: var(--size-11) !important;
}

.px-12 {
  padding-inline: var(--size-12) !important;
}

.px-13 {
  padding-inline: var(--size-13) !important;
}

.px-16 {
  padding-inline: var(--size-16) !important;
}

.px-20 {
  padding-inline: var(--size-20) !important;
}

.px-24 {
  padding-inline: var(--size-24) !important;
}

.px-28 {
  padding-inline: var(--size-28) !important;
}

.px-32 {
  padding-inline: var(--size-32) !important;
}

.px-36 {
  padding-inline: var(--size-36) !important;
}

.px-40 {
  padding-inline: var(--size-40) !important;
}

.px-44 {
  padding-inline: var(--size-44) !important;
}

.px-48 {
  padding-inline: var(--size-48) !important;
}

.px-50 {
  padding-inline: var(--size-50) !important;
}

.px-56 {
  padding-inline: var(--size-56) !important;
}

.px-60 {
  padding-inline: var(--size-60) !important;
}

.px-64 {
  padding-inline: var(--size-64) !important;
}

.px-72 {
  padding-inline: var(--size-72) !important;
}

.px-76 {
  padding-inline: var(--size-76) !important;
}

.px-80 {
  padding-inline: var(--size-80) !important;
}

.px-90 {
  padding-inline: var(--size-90) !important;
}

.px-110 {
  padding-inline: var(--size-110) !important;
}

.px-120 {
  padding-inline: var(--size-120) !important;
}

.px-144 {
  padding-inline: var(--size-144) !important;
}

.px-160 {
  padding-inline: var(--size-160) !important;
}

.px-170 {
  padding-inline: var(--size-170) !important;
}

.px-190 {
  padding-inline: var(--size-190) !important;
}

.px-200 {
  padding-inline: var(--size-200) !important;
}

.px-240 {
  padding-inline: var(--size-240) !important;
}

.px-440 {
  padding-inline: var(--size-440) !important;
}

.py-2 {
  padding-block: var(--size-2) !important;
}

.py-4 {
  padding-block: var(--size-4) !important;
}

.py-6 {
  padding-block: var(--size-6) !important;
}

.py-8 {
  padding-block: var(--size-8) !important;
}

.py-9 {
  padding-block: var(--size-9) !important;
}

.py-10 {
  padding-block: var(--size-10) !important;
}

.py-11 {
  padding-block: var(--size-11) !important;
}

.py-12 {
  padding-block: var(--size-12) !important;
}

.py-13 {
  padding-block: var(--size-13) !important;
}

.py-16 {
  padding-block: var(--size-16) !important;
}

.py-20 {
  padding-block: var(--size-20) !important;
}

.py-24 {
  padding-block: var(--size-24) !important;
}

.py-28 {
  padding-block: var(--size-28) !important;
}

.py-32 {
  padding-block: var(--size-32) !important;
}

.py-36 {
  padding-block: var(--size-36) !important;
}

.py-40 {
  padding-block: var(--size-40) !important;
}

.py-44 {
  padding-block: var(--size-44) !important;
}

.py-48 {
  padding-block: var(--size-48) !important;
}

.py-50 {
  padding-block: var(--size-50) !important;
}

.py-56 {
  padding-block: var(--size-56) !important;
}

.py-60 {
  padding-block: var(--size-60) !important;
}

.py-64 {
  padding-block: var(--size-64) !important;
}

.py-72 {
  padding-block: var(--size-72) !important;
}

.py-76 {
  padding-block: var(--size-76) !important;
}

.py-80 {
  padding-block: var(--size-80) !important;
}

.py-90 {
  padding-block: var(--size-90) !important;
}

.py-110 {
  padding-block: var(--size-110) !important;
}

.py-120 {
  padding-block: var(--size-120) !important;
}

.py-144 {
  padding-block: var(--size-144) !important;
}

.py-160 {
  padding-block: var(--size-160) !important;
}

.py-170 {
  padding-block: var(--size-170) !important;
}

.py-190 {
  padding-block: var(--size-190) !important;
}

.py-200 {
  padding-block: var(--size-200) !important;
}

.py-240 {
  padding-block: var(--size-240) !important;
}

.py-440 {
  padding-block: var(--size-440) !important;
}

.ps-2 {
  padding-inline-start: var(--size-2) !important;
}

.ps-4 {
  padding-inline-start: var(--size-4) !important;
}

.ps-6 {
  padding-inline-start: var(--size-6) !important;
}

.ps-8 {
  padding-inline-start: var(--size-8) !important;
}

.ps-9 {
  padding-inline-start: var(--size-9) !important;
}

.ps-10 {
  padding-inline-start: var(--size-10) !important;
}

.ps-11 {
  padding-inline-start: var(--size-11) !important;
}

.ps-12 {
  padding-inline-start: var(--size-12) !important;
}

.ps-13 {
  padding-inline-start: var(--size-13) !important;
}

.ps-16 {
  padding-inline-start: var(--size-16) !important;
}

.ps-20 {
  padding-inline-start: var(--size-20) !important;
}

.ps-24 {
  padding-inline-start: var(--size-24) !important;
}

.ps-28 {
  padding-inline-start: var(--size-28) !important;
}

.ps-32 {
  padding-inline-start: var(--size-32) !important;
}

.ps-36 {
  padding-inline-start: var(--size-36) !important;
}

.ps-40 {
  padding-inline-start: var(--size-40) !important;
}

.ps-44 {
  padding-inline-start: var(--size-44) !important;
}

.ps-48 {
  padding-inline-start: var(--size-48) !important;
}

.ps-50 {
  padding-inline-start: var(--size-50) !important;
}

.ps-56 {
  padding-inline-start: var(--size-56) !important;
}

.ps-60 {
  padding-inline-start: var(--size-60) !important;
}

.ps-64 {
  padding-inline-start: var(--size-64) !important;
}

.ps-72 {
  padding-inline-start: var(--size-72) !important;
}

.ps-76 {
  padding-inline-start: var(--size-76) !important;
}

.ps-80 {
  padding-inline-start: var(--size-80) !important;
}

.ps-90 {
  padding-inline-start: var(--size-90) !important;
}

.ps-110 {
  padding-inline-start: var(--size-110) !important;
}

.ps-120 {
  padding-inline-start: var(--size-120) !important;
}

.ps-144 {
  padding-inline-start: var(--size-144) !important;
}

.ps-160 {
  padding-inline-start: var(--size-160) !important;
}

.ps-170 {
  padding-inline-start: var(--size-170) !important;
}

.ps-190 {
  padding-inline-start: var(--size-190) !important;
}

.ps-200 {
  padding-inline-start: var(--size-200) !important;
}

.ps-240 {
  padding-inline-start: var(--size-240) !important;
}

.ps-440 {
  padding-inline-start: var(--size-440) !important;
}

.pe-2 {
  padding-inline-end: var(--size-2) !important;
}

.pe-4 {
  padding-inline-end: var(--size-4) !important;
}

.pe-6 {
  padding-inline-end: var(--size-6) !important;
}

.pe-8 {
  padding-inline-end: var(--size-8) !important;
}

.pe-9 {
  padding-inline-end: var(--size-9) !important;
}

.pe-10 {
  padding-inline-end: var(--size-10) !important;
}

.pe-11 {
  padding-inline-end: var(--size-11) !important;
}

.pe-12 {
  padding-inline-end: var(--size-12) !important;
}

.pe-13 {
  padding-inline-end: var(--size-13) !important;
}

.pe-16 {
  padding-inline-end: var(--size-16) !important;
}

.pe-20 {
  padding-inline-end: var(--size-20) !important;
}

.pe-24 {
  padding-inline-end: var(--size-24) !important;
}

.pe-28 {
  padding-inline-end: var(--size-28) !important;
}

.pe-32 {
  padding-inline-end: var(--size-32) !important;
}

.pe-36 {
  padding-inline-end: var(--size-36) !important;
}

.pe-40 {
  padding-inline-end: var(--size-40) !important;
}

.pe-44 {
  padding-inline-end: var(--size-44) !important;
}

.pe-48 {
  padding-inline-end: var(--size-48) !important;
}

.pe-50 {
  padding-inline-end: var(--size-50) !important;
}

.pe-56 {
  padding-inline-end: var(--size-56) !important;
}

.pe-60 {
  padding-inline-end: var(--size-60) !important;
}

.pe-64 {
  padding-inline-end: var(--size-64) !important;
}

.pe-72 {
  padding-inline-end: var(--size-72) !important;
}

.pe-76 {
  padding-inline-end: var(--size-76) !important;
}

.pe-80 {
  padding-inline-end: var(--size-80) !important;
}

.pe-90 {
  padding-inline-end: var(--size-90) !important;
}

.pe-110 {
  padding-inline-end: var(--size-110) !important;
}

.pe-120 {
  padding-inline-end: var(--size-120) !important;
}

.pe-144 {
  padding-inline-end: var(--size-144) !important;
}

.pe-160 {
  padding-inline-end: var(--size-160) !important;
}

.pe-170 {
  padding-inline-end: var(--size-170) !important;
}

.pe-190 {
  padding-inline-end: var(--size-190) !important;
}

.pe-200 {
  padding-inline-end: var(--size-200) !important;
}

.pe-240 {
  padding-inline-end: var(--size-240) !important;
}

.pe-440 {
  padding-inline-end: var(--size-440) !important;
}

.pt-2 {
  padding-block-start: var(--size-2) !important;
}

.pt-4 {
  padding-block-start: var(--size-4) !important;
}

.pt-6 {
  padding-block-start: var(--size-6) !important;
}

.pt-8 {
  padding-block-start: var(--size-8) !important;
}

.pt-9 {
  padding-block-start: var(--size-9) !important;
}

.pt-10 {
  padding-block-start: var(--size-10) !important;
}

.pt-11 {
  padding-block-start: var(--size-11) !important;
}

.pt-12 {
  padding-block-start: var(--size-12) !important;
}

.pt-13 {
  padding-block-start: var(--size-13) !important;
}

.pt-16 {
  padding-block-start: var(--size-16) !important;
}

.pt-20 {
  padding-block-start: var(--size-20) !important;
}

.pt-24 {
  padding-block-start: var(--size-24) !important;
}

.pt-28 {
  padding-block-start: var(--size-28) !important;
}

.pt-32 {
  padding-block-start: var(--size-32) !important;
}

.pt-36 {
  padding-block-start: var(--size-36) !important;
}

.pt-40 {
  padding-block-start: var(--size-40) !important;
}

.pt-44 {
  padding-block-start: var(--size-44) !important;
}

.pt-48 {
  padding-block-start: var(--size-48) !important;
}

.pt-50 {
  padding-block-start: var(--size-50) !important;
}

.pt-56 {
  padding-block-start: var(--size-56) !important;
}

.pt-60 {
  padding-block-start: var(--size-60) !important;
}

.pt-64 {
  padding-block-start: var(--size-64) !important;
}

.pt-72 {
  padding-block-start: var(--size-72) !important;
}

.pt-76 {
  padding-block-start: var(--size-76) !important;
}

.pt-80 {
  padding-block-start: var(--size-80) !important;
}

.pt-90 {
  padding-block-start: var(--size-90) !important;
}

.pt-110 {
  padding-block-start: var(--size-110) !important;
}

.pt-120 {
  padding-block-start: var(--size-120) !important;
}

.pt-144 {
  padding-block-start: var(--size-144) !important;
}

.pt-160 {
  padding-block-start: var(--size-160) !important;
}

.pt-170 {
  padding-block-start: var(--size-170) !important;
}

.pt-190 {
  padding-block-start: var(--size-190) !important;
}

.pt-200 {
  padding-block-start: var(--size-200) !important;
}

.pt-240 {
  padding-block-start: var(--size-240) !important;
}

.pt-440 {
  padding-block-start: var(--size-440) !important;
}

.pb-2 {
  padding-block-end: var(--size-2) !important;
}

.pb-4 {
  padding-block-end: var(--size-4) !important;
}

.pb-6 {
  padding-block-end: var(--size-6) !important;
}

.pb-8 {
  padding-block-end: var(--size-8) !important;
}

.pb-9 {
  padding-block-end: var(--size-9) !important;
}

.pb-10 {
  padding-block-end: var(--size-10) !important;
}

.pb-11 {
  padding-block-end: var(--size-11) !important;
}

.pb-12 {
  padding-block-end: var(--size-12) !important;
}

.pb-13 {
  padding-block-end: var(--size-13) !important;
}

.pb-16 {
  padding-block-end: var(--size-16) !important;
}

.pb-20 {
  padding-block-end: var(--size-20) !important;
}

.pb-24 {
  padding-block-end: var(--size-24) !important;
}

.pb-28 {
  padding-block-end: var(--size-28) !important;
}

.pb-32 {
  padding-block-end: var(--size-32) !important;
}

.pb-36 {
  padding-block-end: var(--size-36) !important;
}

.pb-40 {
  padding-block-end: var(--size-40) !important;
}

.pb-44 {
  padding-block-end: var(--size-44) !important;
}

.pb-48 {
  padding-block-end: var(--size-48) !important;
}

.pb-50 {
  padding-block-end: var(--size-50) !important;
}

.pb-56 {
  padding-block-end: var(--size-56) !important;
}

.pb-60 {
  padding-block-end: var(--size-60) !important;
}

.pb-64 {
  padding-block-end: var(--size-64) !important;
}

.pb-72 {
  padding-block-end: var(--size-72) !important;
}

.pb-76 {
  padding-block-end: var(--size-76) !important;
}

.pb-80 {
  padding-block-end: var(--size-80) !important;
}

.pb-90 {
  padding-block-end: var(--size-90) !important;
}

.pb-110 {
  padding-block-end: var(--size-110) !important;
}

.pb-120 {
  padding-block-end: var(--size-120) !important;
}

.pb-144 {
  padding-block-end: var(--size-144) !important;
}

.pb-160 {
  padding-block-end: var(--size-160) !important;
}

.pb-170 {
  padding-block-end: var(--size-170) !important;
}

.pb-190 {
  padding-block-end: var(--size-190) !important;
}

.pb-200 {
  padding-block-end: var(--size-200) !important;
}

.pb-240 {
  padding-block-end: var(--size-240) !important;
}

.pb-440 {
  padding-block-end: var(--size-440) !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.ms--10px {
  margin-left: -10px;
}

.mt--50 {
  margin-top: -50px;
}

.mt--100 {
  margin-top: -100px;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

/* Gap Css */
.gap-2 {
  gap: var(--size-2);
}

.gap-4 {
  gap: var(--size-4);
}

.gap-6 {
  gap: var(--size-6);
}

.gap-8 {
  gap: var(--size-8);
}

.gap-9 {
  gap: var(--size-9);
}

.gap-10 {
  gap: var(--size-10);
}

.gap-11 {
  gap: var(--size-11);
}

.gap-12 {
  gap: var(--size-12);
}

.gap-13 {
  gap: var(--size-13);
}

.gap-16 {
  gap: var(--size-16);
}

.gap-20 {
  gap: var(--size-20);
}

.gap-24 {
  gap: var(--size-24);
}

.gap-28 {
  gap: var(--size-28);
}

.gap-32 {
  gap: var(--size-32);
}

.gap-36 {
  gap: var(--size-36);
}

.gap-40 {
  gap: var(--size-40);
}

.gap-44 {
  gap: var(--size-44);
}

.gap-48 {
  gap: var(--size-48);
}

.gap-50 {
  gap: var(--size-50);
}

.gap-56 {
  gap: var(--size-56);
}

.gap-60 {
  gap: var(--size-60);
}

.gap-64 {
  gap: var(--size-64);
}

.gap-72 {
  gap: var(--size-72);
}

.gap-76 {
  gap: var(--size-76);
}

.gap-80 {
  gap: var(--size-80);
}

.gap-90 {
  gap: var(--size-90);
}

.gap-110 {
  gap: var(--size-110);
}

.gap-120 {
  gap: var(--size-120);
}

.gap-144 {
  gap: var(--size-144);
}

.gap-160 {
  gap: var(--size-160);
}

.gap-170 {
  gap: var(--size-170);
}

.gap-190 {
  gap: var(--size-190);
}

.gap-200 {
  gap: var(--size-200);
}

.gap-240 {
  gap: var(--size-240);
}

.gap-440 {
  gap: var(--size-440);
}

/* === spacing css end === */
/* === spacing css start === */
.radius-2 {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  -o-border-radius: 0.125rem;
}

.radius-4 {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}

.radius-6 {
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.radius-8 {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.radius-9 {
  border-radius: 0.5625rem;
  -webkit-border-radius: 0.5625rem;
  -moz-border-radius: 0.5625rem;
  -ms-border-radius: 0.5625rem;
  -o-border-radius: 0.5625rem;
}

.radius-10 {
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.radius-11 {
  border-radius: 0.6875rem;
  -webkit-border-radius: 0.6875rem;
  -moz-border-radius: 0.6875rem;
  -ms-border-radius: 0.6875rem;
  -o-border-radius: 0.6875rem;
}

.radius-12 {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
}

.radius-13 {
  border-radius: 0.8125rem;
  -webkit-border-radius: 0.8125rem;
  -moz-border-radius: 0.8125rem;
  -ms-border-radius: 0.8125rem;
  -o-border-radius: 0.8125rem;
}

.radius-16 {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.radius-20 {
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  -ms-border-radius: 1.25rem;
  -o-border-radius: 1.25rem;
}

.radius-24 {
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;
}

.radius-28 {
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;
}

.radius-32 {
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.radius-36 {
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.radius-40 {
  border-radius: 2.5rem;
  -webkit-border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;
  -ms-border-radius: 2.5rem;
  -o-border-radius: 2.5rem;
}

.radius-44 {
  border-radius: 2.75rem;
  -webkit-border-radius: 2.75rem;
  -moz-border-radius: 2.75rem;
  -ms-border-radius: 2.75rem;
  -o-border-radius: 2.75rem;
}

.radius-48 {
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
}

.radius-50 {
  border-radius: 3.125rem;
  -webkit-border-radius: 3.125rem;
  -moz-border-radius: 3.125rem;
  -ms-border-radius: 3.125rem;
  -o-border-radius: 3.125rem;
}

.radius-56 {
  border-radius: 3.5rem;
  -webkit-border-radius: 3.5rem;
  -moz-border-radius: 3.5rem;
  -ms-border-radius: 3.5rem;
  -o-border-radius: 3.5rem;
}

.radius-60 {
  border-radius: 3.75rem;
  -webkit-border-radius: 3.75rem;
  -moz-border-radius: 3.75rem;
  -ms-border-radius: 3.75rem;
  -o-border-radius: 3.75rem;
}

.radius-64 {
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
}

.radius-72 {
  border-radius: 4.5rem;
  -webkit-border-radius: 4.5rem;
  -moz-border-radius: 4.5rem;
  -ms-border-radius: 4.5rem;
  -o-border-radius: 4.5rem;
}

.radius-76 {
  border-radius: 4.75rem;
  -webkit-border-radius: 4.75rem;
  -moz-border-radius: 4.75rem;
  -ms-border-radius: 4.75rem;
  -o-border-radius: 4.75rem;
}

.radius-80 {
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

.radius-90 {
  border-radius: 5.625rem;
  -webkit-border-radius: 5.625rem;
  -moz-border-radius: 5.625rem;
  -ms-border-radius: 5.625rem;
  -o-border-radius: 5.625rem;
}

.radius-110 {
  border-radius: 6.875rem;
  -webkit-border-radius: 6.875rem;
  -moz-border-radius: 6.875rem;
  -ms-border-radius: 6.875rem;
  -o-border-radius: 6.875rem;
}

.radius-120 {
  border-radius: 7.5rem;
  -webkit-border-radius: 7.5rem;
  -moz-border-radius: 7.5rem;
  -ms-border-radius: 7.5rem;
  -o-border-radius: 7.5rem;
}

.radius-144 {
  border-radius: 9rem;
  -webkit-border-radius: 9rem;
  -moz-border-radius: 9rem;
  -ms-border-radius: 9rem;
  -o-border-radius: 9rem;
}

.radius-160 {
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  -o-border-radius: 10rem;
}

.radius-170 {
  border-radius: 10.625rem;
  -webkit-border-radius: 10.625rem;
  -moz-border-radius: 10.625rem;
  -ms-border-radius: 10.625rem;
  -o-border-radius: 10.625rem;
}

.radius-190 {
  border-radius: 11.875rem;
  -webkit-border-radius: 11.875rem;
  -moz-border-radius: 11.875rem;
  -ms-border-radius: 11.875rem;
  -o-border-radius: 11.875rem;
}

.radius-200 {
  border-radius: 12.5rem;
  -webkit-border-radius: 12.5rem;
  -moz-border-radius: 12.5rem;
  -ms-border-radius: 12.5rem;
  -o-border-radius: 12.5rem;
}

.radius-240 {
  border-radius: 15rem;
  -webkit-border-radius: 15rem;
  -moz-border-radius: 15rem;
  -ms-border-radius: 15rem;
  -o-border-radius: 15rem;
}

.radius-440 {
  border-radius: 27.5rem;
  -webkit-border-radius: 27.5rem;
  -moz-border-radius: 27.5rem;
  -ms-border-radius: 27.5rem;
  -o-border-radius: 27.5rem;
}

.rounded-start-top-0 {
  border-start-start-radius: 0 !important;
}

.rounded-end-bottom-0 {
  border-end-end-radius: 0 !important;
}

/* === spacing css end === */
/* === animation css start === */
/* === animation css end === */
/* === typography css start === */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary-light);
  font-weight: 600;
}

h1,
.h1 {
  font-size: var(--h1) !important;
}

h2,
.h2 {
  font-size: var(--h2) !important;
}

h3,
.h3 {
  font-size: var(--h3) !important;
}

h4,
.h4 {
  font-size: var(--h4) !important;
}

h5,
.h5 {
  font-size: var(--h5) !important;
}

h6,
.h6 {
  font-size: var(--h6) !important;
}

.text-2xxl {
  font-size: var(--font-2xxl) !important;
}

.text-2xl {
  font-size: var(--font-2xl) !important;
}

.text-xxl {
  font-size: var(--font-xxl) !important;
}

.text-xl {
  font-size: var(--font-xl) !important;
}

.text-lg {
  font-size: var(--font-lg) !important;
}

.text-md {
  font-size: var(--font-md) !important;
}

.text-sm {
  font-size: var(--font-sm) !important;
}

.text-xs {
  font-size: var(--font-xs) !important;
}

.text-xxs {
  font-size: var(--font-xxs) !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.text-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* === typography css end === */
/* === text-align css start === */
.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

/* === text-align css end === */
/* === border css start === */
.border {
  border: 1px solid var(--border-color) !important;
}

.border-start {
  border-inline-start: 1px solid var(--border-color) !important;
}

.border-end {
  border-inline-end: 1px solid var(--border-color) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--border-color) !important;
}

.border-primary-light-white {
  border-color: var(--primary-light-white) !important;
}

.border-yellow-light-white {
  border-color: var(--yellow-light-white) !important;
}

.border-purple-light-white {
  border-color: var(--purple-light-white) !important;
}

.border-pink-light-white {
  border-color: var(--pink-light-white) !important;
}

.br-success {
  border-color: var(--success-border) !important;
}

.br-white {
  border-color: var(--base) !important;
}

.border-light-white {
  border-color: rgba(255, 255, 255, 0.38) !important;
}

.input-form-light {
  border-color: var(--input-form-light) !important;
}

.input-form-dark {
  border-color: var(--input-form-dark) !important;
}

.br-hover-primary {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.br-hover-primary:hover {
  border-color: var(--primary-600) !important;
}

.br-hover-success {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.br-hover-success:hover {
  border-color: var(--success-main) !important;
}

.br-hover-info {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.br-hover-info:hover {
  border-color: var(--info-main) !important;
}

.br-hover-warning {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.br-hover-warning:hover {
  border-color: var(--warning-main) !important;
}

.br-hover-danger {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.br-hover-danger:hover {
  border-color: var(--danger-main) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}

.border-primary-100 {
  border-color: var(--primary-100) !important;
}

.border-primary-200 {
  border-color: var(--primary-200) !important;
}

.border-primary-300 {
  border-color: var(--primary-300) !important;
}

.border-primary-400 {
  border-color: var(--primary-400) !important;
}

.border-primary-500 {
  border-color: var(--primary-500) !important;
}

.border-primary-600 {
  border-color: var(--primary-600) !important;
}

.border-primary-700 {
  border-color: var(--primary-700) !important;
}

.border-primary-800 {
  border-color: var(--primary-800) !important;
}

.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.border-neutral-50 {
  border-color: var(--neutral-50) !important;
}

.border-neutral-100 {
  border-color: var(--neutral-100) !important;
}

.border-neutral-200 {
  border-color: var(--neutral-200) !important;
}

.border-neutral-300 {
  border-color: var(--neutral-300) !important;
}

.border-neutral-400 {
  border-color: var(--neutral-400) !important;
}

.border-neutral-500 {
  border-color: var(--neutral-500) !important;
}

.border-neutral-600 {
  border-color: var(--neutral-600) !important;
}

.border-neutral-700 {
  border-color: var(--neutral-700) !important;
}

.border-neutral-800 {
  border-color: var(--neutral-800) !important;
}

.border-neutral-900 {
  border-color: var(--neutral-900) !important;
}

.border-danger-50 {
  border-color: var(--danger-50) !important;
}

.border-danger-100 {
  border-color: var(--danger-100) !important;
}

.border-danger-200 {
  border-color: var(--danger-200) !important;
}

.border-danger-300 {
  border-color: var(--danger-300) !important;
}

.border-danger-400 {
  border-color: var(--danger-400) !important;
}

.border-danger-500 {
  border-color: var(--danger-500) !important;
}

.border-danger-600 {
  border-color: var(--danger-600) !important;
}

.border-danger-700 {
  border-color: var(--danger-700) !important;
}

.border-danger-800 {
  border-color: var(--danger-800) !important;
}

.border-danger-900 {
  border-color: var(--danger-900) !important;
}

.border-success-50 {
  border-color: var(--success-50) !important;
}

.border-success-100 {
  border-color: var(--success-100) !important;
}

.border-success-200 {
  border-color: var(--success-200) !important;
}

.border-success-300 {
  border-color: var(--success-300) !important;
}

.border-success-400 {
  border-color: var(--success-400) !important;
}

.border-success-500 {
  border-color: var(--success-500) !important;
}

.border-success-600 {
  border-color: var(--success-600) !important;
}

.border-success-700 {
  border-color: var(--success-700) !important;
}

.border-success-800 {
  border-color: var(--success-800) !important;
}

.border-success-900 {
  border-color: var(--success-900) !important;
}

.border-warning-50 {
  border-color: var(--warning-50) !important;
}

.border-warning-100 {
  border-color: var(--warning-100) !important;
}

.border-warning-200 {
  border-color: var(--warning-200) !important;
}

.border-warning-300 {
  border-color: var(--warning-300) !important;
}

.border-warning-400 {
  border-color: var(--warning-400) !important;
}

.border-warning-500 {
  border-color: var(--warning-500) !important;
}

.border-warning-600 {
  border-color: var(--warning-600) !important;
}

.border-warning-700 {
  border-color: var(--warning-700) !important;
}

.border-warning-800 {
  border-color: var(--warning-800) !important;
}

.border-warning-900 {
  border-color: var(--warning-900) !important;
}

.border-info-50 {
  border-color: var(--info-50) !important;
}

.border-info-100 {
  border-color: var(--info-100) !important;
}

.border-info-200 {
  border-color: var(--info-200) !important;
}

.border-info-300 {
  border-color: var(--info-300) !important;
}

.border-info-400 {
  border-color: var(--info-400) !important;
}

.border-info-500 {
  border-color: var(--info-500) !important;
}

.border-info-600 {
  border-color: var(--info-600) !important;
}

.border-info-700 {
  border-color: var(--info-700) !important;
}

.border-info-800 {
  border-color: var(--info-800) !important;
}

.border-info-900 {
  border-color: var(--info-900) !important;
}

.border-dark-1 {
  border-color: var(--dark-1) !important;
}

.border-dark-2 {
  border-color: var(--dark-2) !important;
}

.border-dark-3 {
  border-color: var(--dark-3) !important;
}

.border-lilac-50 {
  border-color: var(--lilac-50) !important;
}

.border-lilac-100 {
  border-color: var(--lilac-100) !important;
}

.border-lilac-600 {
  border-color: var(--lilac-600) !important;
}

.border-lilac-700 {
  border-color: var(--lilac-700) !important;
}

.border-lilac-800 {
  border-color: var(--lilac-800) !important;
}

.border-light-50 {
  border-color: var(--light-50) !important;
}

.border-light-100 {
  border-color: var(--light-100) !important;
}

.border-light-600 {
  border-color: var(--light-600) !important;
}

.border-light-700 {
  border-color: var(--light-700) !important;
}

.border-light-800 {
  border-color: var(--light-800) !important;
}

.border-success-main {
  border-color: var(--success-main) !important;
}

.border-success-surface {
  border-color: var(--success-surface) !important;
}

.border-success-border {
  border-color: var(--success-border) !important;
}

.border-success-hover {
  border-color: var(--success-hover) !important;
}

.border-success-pressed {
  border-color: var(--success-pressed) !important;
}

.border-success-focus {
  border-color: var(--success-focus) !important;
}

.border-info-main {
  border-color: var(--info-main) !important;
}

.border-info-surface {
  border-color: var(--info-surface) !important;
}

.border-info-border {
  border-color: var(--info-border) !important;
}

.border-info-hover {
  border-color: var(--info-hover) !important;
}

.border-info-pressed {
  border-color: var(--info-pressed) !important;
}

.border-info-focus {
  border-color: var(--info-focus) !important;
}

.border-warning-main {
  border-color: var(--warning-main) !important;
}

.border-warning-surface {
  border-color: var(--warning-surface) !important;
}

.border-warning-border {
  border-color: var(--warning-border) !important;
}

.border-warning-hover {
  border-color: var(--warning-hover) !important;
}

.border-warning-pressed {
  border-color: var(--warning-pressed) !important;
}

.border-warning-focus {
  border-color: var(--warning-focus) !important;
}

.border-danger-main {
  border-color: var(--danger-main) !important;
}

.border-danger-surface {
  border-color: var(--danger-surface) !important;
}

.border-danger-border {
  border-color: var(--danger-border) !important;
}

.border-danger-hover {
  border-color: var(--danger-hover) !important;
}

.border-danger-pressed {
  border-color: var(--danger-pressed) !important;
}

.border-danger-focus {
  border-color: var(--danger-focus) !important;
}

.border-cyan {
  border-color: var(--cyan) !important;
}

.border-indigo {
  border-color: var(--indigo) !important;
}

.border-purple {
  border-color: var(--purple) !important;
}

.border-red {
  border-color: var(--red) !important;
}

.border-yellow {
  border-color: var(--yellow) !important;
}

.border-orange {
  border-color: var(--orange) !important;
}

.border-pink {
  border-color: var(--pink) !important;
}

.border-width-2-px {
  border-width: 2px !important;
}

.border-width-3-px {
  border-width: 3px !important;
}

.border-width-4-px {
  border-width: 4px !important;
}

.border-width-5-px {
  border-width: 5px !important;
}

.border-start-width-2-px {
  border-inline-start-width: 2px !important;
}

.border-start-width-3-px {
  border-inline-start-width: 3px !important;
}

.border-start-width-4-px {
  border-inline-start-width: 4px !important;
}

.border-start-width-5-px {
  border-inline-start-width: 5px !important;
}

.border-end-width-2-px {
  border-inline-end-width: 2px !important;
}

.border-end-width-3-px {
  border-inline-end-width: 3px !important;
}

.border-end-width-4-px {
  border-inline-end-width: 4px !important;
}

.border-end-width-5-px {
  border-inline-end-width: 5px !important;
}

.border-end-width-6-px {
  border-inline-end-width: 6px !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-dashed {
  border-style: dashed !important;
}

.center-border {
  position: relative;
}

.center-border::before {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% - 9px);
  background-color: var(--neutral-300);
  left: 50%;
  transform: translateX(-50%);
}

/* === border css end === */
/* === hover css start === */
.group-item:hover .group-hover\:bg-primary-50 {
  background-color: var(--primary-50) !important;
}

.group-item:hover .group-hover\:bg-primary-100 {
  background-color: var(--primary-100) !important;
}

.group-item:hover .group-hover\:bg-primary-200 {
  background-color: var(--primary-200) !important;
}

.group-item:hover .group-hover\:bg-primary-300 {
  background-color: var(--primary-300) !important;
}

.group-item:hover .group-hover\:bg-primary-400 {
  background-color: var(--primary-400) !important;
}

.group-item:hover .group-hover\:bg-primary-500 {
  background-color: var(--primary-500) !important;
}

.group-item:hover .group-hover\:bg-primary-600 {
  background-color: var(--primary-600) !important;
}

.group-item:hover .group-hover\:bg-primary-700 {
  background-color: var(--primary-700) !important;
}

.group-item:hover .group-hover\:bg-primary-800 {
  background-color: var(--primary-800) !important;
}

.group-item:hover .group-hover\:bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.group-item:hover .group-hover\:bg-neutral-50 {
  background-color: var(--neutral-50) !important;
}

.group-item:hover .group-hover\:bg-neutral-100 {
  background-color: var(--neutral-100) !important;
}

.group-item:hover .group-hover\:bg-neutral-200 {
  background-color: var(--neutral-200) !important;
}

.group-item:hover .group-hover\:bg-neutral-300 {
  background-color: var(--neutral-300) !important;
}

.group-item:hover .group-hover\:bg-neutral-400 {
  background-color: var(--neutral-400) !important;
}

.group-item:hover .group-hover\:bg-neutral-500 {
  background-color: var(--neutral-500) !important;
}

.group-item:hover .group-hover\:bg-neutral-600 {
  background-color: var(--neutral-600) !important;
}

.group-item:hover .group-hover\:bg-neutral-700 {
  background-color: var(--neutral-700) !important;
}

.group-item:hover .group-hover\:bg-neutral-800 {
  background-color: var(--neutral-800) !important;
}

.group-item:hover .group-hover\:bg-neutral-900 {
  background-color: var(--neutral-900) !important;
}

.group-item:hover .group-hover\:bg-danger-50 {
  background-color: var(--danger-50) !important;
}

.group-item:hover .group-hover\:bg-danger-100 {
  background-color: var(--danger-100) !important;
}

.group-item:hover .group-hover\:bg-danger-200 {
  background-color: var(--danger-200) !important;
}

.group-item:hover .group-hover\:bg-danger-300 {
  background-color: var(--danger-300) !important;
}

.group-item:hover .group-hover\:bg-danger-400 {
  background-color: var(--danger-400) !important;
}

.group-item:hover .group-hover\:bg-danger-500 {
  background-color: var(--danger-500) !important;
}

.group-item:hover .group-hover\:bg-danger-600 {
  background-color: var(--danger-600) !important;
}

.group-item:hover .group-hover\:bg-danger-700 {
  background-color: var(--danger-700) !important;
}

.group-item:hover .group-hover\:bg-danger-800 {
  background-color: var(--danger-800) !important;
}

.group-item:hover .group-hover\:bg-danger-900 {
  background-color: var(--danger-900) !important;
}

.group-item:hover .group-hover\:bg-success-50 {
  background-color: var(--success-50) !important;
}

.group-item:hover .group-hover\:bg-success-100 {
  background-color: var(--success-100) !important;
}

.group-item:hover .group-hover\:bg-success-200 {
  background-color: var(--success-200) !important;
}

.group-item:hover .group-hover\:bg-success-300 {
  background-color: var(--success-300) !important;
}

.group-item:hover .group-hover\:bg-success-400 {
  background-color: var(--success-400) !important;
}

.group-item:hover .group-hover\:bg-success-500 {
  background-color: var(--success-500) !important;
}

.group-item:hover .group-hover\:bg-success-600 {
  background-color: var(--success-600) !important;
}

.group-item:hover .group-hover\:bg-success-700 {
  background-color: var(--success-700) !important;
}

.group-item:hover .group-hover\:bg-success-800 {
  background-color: var(--success-800) !important;
}

.group-item:hover .group-hover\:bg-success-900 {
  background-color: var(--success-900) !important;
}

.group-item:hover .group-hover\:bg-warning-50 {
  background-color: var(--warning-50) !important;
}

.group-item:hover .group-hover\:bg-warning-100 {
  background-color: var(--warning-100) !important;
}

.group-item:hover .group-hover\:bg-warning-200 {
  background-color: var(--warning-200) !important;
}

.group-item:hover .group-hover\:bg-warning-300 {
  background-color: var(--warning-300) !important;
}

.group-item:hover .group-hover\:bg-warning-400 {
  background-color: var(--warning-400) !important;
}

.group-item:hover .group-hover\:bg-warning-500 {
  background-color: var(--warning-500) !important;
}

.group-item:hover .group-hover\:bg-warning-600 {
  background-color: var(--warning-600) !important;
}

.group-item:hover .group-hover\:bg-warning-700 {
  background-color: var(--warning-700) !important;
}

.group-item:hover .group-hover\:bg-warning-800 {
  background-color: var(--warning-800) !important;
}

.group-item:hover .group-hover\:bg-warning-900 {
  background-color: var(--warning-900) !important;
}

.group-item:hover .group-hover\:bg-info-50 {
  background-color: var(--info-50) !important;
}

.group-item:hover .group-hover\:bg-info-100 {
  background-color: var(--info-100) !important;
}

.group-item:hover .group-hover\:bg-info-200 {
  background-color: var(--info-200) !important;
}

.group-item:hover .group-hover\:bg-info-300 {
  background-color: var(--info-300) !important;
}

.group-item:hover .group-hover\:bg-info-400 {
  background-color: var(--info-400) !important;
}

.group-item:hover .group-hover\:bg-info-500 {
  background-color: var(--info-500) !important;
}

.group-item:hover .group-hover\:bg-info-600 {
  background-color: var(--info-600) !important;
}

.group-item:hover .group-hover\:bg-info-700 {
  background-color: var(--info-700) !important;
}

.group-item:hover .group-hover\:bg-info-800 {
  background-color: var(--info-800) !important;
}

.group-item:hover .group-hover\:bg-info-900 {
  background-color: var(--info-900) !important;
}

.group-item:hover .group-hover\:bg-dark-1 {
  background-color: var(--dark-1) !important;
}

.group-item:hover .group-hover\:bg-dark-2 {
  background-color: var(--dark-2) !important;
}

.group-item:hover .group-hover\:bg-dark-3 {
  background-color: var(--dark-3) !important;
}

.group-item:hover .group-hover\:bg-lilac-50 {
  background-color: var(--lilac-50) !important;
}

.group-item:hover .group-hover\:bg-lilac-100 {
  background-color: var(--lilac-100) !important;
}

.group-item:hover .group-hover\:bg-lilac-600 {
  background-color: var(--lilac-600) !important;
}

.group-item:hover .group-hover\:bg-lilac-700 {
  background-color: var(--lilac-700) !important;
}

.group-item:hover .group-hover\:bg-lilac-800 {
  background-color: var(--lilac-800) !important;
}

.group-item:hover .group-hover\:bg-light-50 {
  background-color: var(--light-50) !important;
}

.group-item:hover .group-hover\:bg-light-100 {
  background-color: var(--light-100) !important;
}

.group-item:hover .group-hover\:bg-light-600 {
  background-color: var(--light-600) !important;
}

.group-item:hover .group-hover\:bg-light-700 {
  background-color: var(--light-700) !important;
}

.group-item:hover .group-hover\:bg-light-800 {
  background-color: var(--light-800) !important;
}

.group-item:hover .group-hover\:bg-success-main {
  background-color: var(--success-main) !important;
}

.group-item:hover .group-hover\:bg-success-surface {
  background-color: var(--success-surface) !important;
}

.group-item:hover .group-hover\:bg-success-border {
  background-color: var(--success-border) !important;
}

.group-item:hover .group-hover\:bg-success-hover {
  background-color: var(--success-hover) !important;
}

.group-item:hover .group-hover\:bg-success-pressed {
  background-color: var(--success-pressed) !important;
}

.group-item:hover .group-hover\:bg-success-focus {
  background-color: var(--success-focus) !important;
}

.group-item:hover .group-hover\:bg-info-main {
  background-color: var(--info-main) !important;
}

.group-item:hover .group-hover\:bg-info-surface {
  background-color: var(--info-surface) !important;
}

.group-item:hover .group-hover\:bg-info-border {
  background-color: var(--info-border) !important;
}

.group-item:hover .group-hover\:bg-info-hover {
  background-color: var(--info-hover) !important;
}

.group-item:hover .group-hover\:bg-info-pressed {
  background-color: var(--info-pressed) !important;
}

.group-item:hover .group-hover\:bg-info-focus {
  background-color: var(--info-focus) !important;
}

.group-item:hover .group-hover\:bg-warning-main {
  background-color: var(--warning-main) !important;
}

.group-item:hover .group-hover\:bg-warning-surface {
  background-color: var(--warning-surface) !important;
}

.group-item:hover .group-hover\:bg-warning-border {
  background-color: var(--warning-border) !important;
}

.group-item:hover .group-hover\:bg-warning-hover {
  background-color: var(--warning-hover) !important;
}

.group-item:hover .group-hover\:bg-warning-pressed {
  background-color: var(--warning-pressed) !important;
}

.group-item:hover .group-hover\:bg-warning-focus {
  background-color: var(--warning-focus) !important;
}

.group-item:hover .group-hover\:bg-danger-main {
  background-color: var(--danger-main) !important;
}

.group-item:hover .group-hover\:bg-danger-surface {
  background-color: var(--danger-surface) !important;
}

.group-item:hover .group-hover\:bg-danger-border {
  background-color: var(--danger-border) !important;
}

.group-item:hover .group-hover\:bg-danger-hover {
  background-color: var(--danger-hover) !important;
}

.group-item:hover .group-hover\:bg-danger-pressed {
  background-color: var(--danger-pressed) !important;
}

.group-item:hover .group-hover\:bg-danger-focus {
  background-color: var(--danger-focus) !important;
}

.group-item:hover .group-hover\:bg-cyan {
  background-color: var(--cyan) !important;
}

.group-item:hover .group-hover\:bg-indigo {
  background-color: var(--indigo) !important;
}

.group-item:hover .group-hover\:bg-purple {
  background-color: var(--purple) !important;
}

.group-item:hover .group-hover\:bg-red {
  background-color: var(--red) !important;
}

.group-item:hover .group-hover\:bg-yellow {
  background-color: var(--yellow) !important;
}

.group-item:hover .group-hover\:bg-orange {
  background-color: var(--orange) !important;
}

.group-item:hover .group-hover\:bg-pink {
  background-color: var(--pink) !important;
}

.group-item:hover .group-hover\:text-primary-50 {
  color: var(--primary-50) !important;
}

.group-item:hover .group-hover\:text-primary-100 {
  color: var(--primary-100) !important;
}

.group-item:hover .group-hover\:text-primary-200 {
  color: var(--primary-200) !important;
}

.group-item:hover .group-hover\:text-primary-300 {
  color: var(--primary-300) !important;
}

.group-item:hover .group-hover\:text-primary-400 {
  color: var(--primary-400) !important;
}

.group-item:hover .group-hover\:text-primary-500 {
  color: var(--primary-500) !important;
}

.group-item:hover .group-hover\:text-primary-600 {
  color: var(--primary-600) !important;
}

.group-item:hover .group-hover\:text-primary-700 {
  color: var(--primary-700) !important;
}

.group-item:hover .group-hover\:text-primary-800 {
  color: var(--primary-800) !important;
}

.group-item:hover .group-hover\:text-primary-900 {
  color: var(--primary-900) !important;
}

.group-item:hover .group-hover\:text-neutral-50 {
  color: var(--neutral-50) !important;
}

.group-item:hover .group-hover\:text-neutral-100 {
  color: var(--neutral-100) !important;
}

.group-item:hover .group-hover\:text-neutral-200 {
  color: var(--neutral-200) !important;
}

.group-item:hover .group-hover\:text-neutral-300 {
  color: var(--neutral-300) !important;
}

.group-item:hover .group-hover\:text-neutral-400 {
  color: var(--neutral-400) !important;
}

.group-item:hover .group-hover\:text-neutral-500 {
  color: var(--neutral-500) !important;
}

.group-item:hover .group-hover\:text-neutral-600 {
  color: var(--neutral-600) !important;
}

.group-item:hover .group-hover\:text-neutral-700 {
  color: var(--neutral-700) !important;
}

.group-item:hover .group-hover\:text-neutral-800 {
  color: var(--neutral-800) !important;
}

.group-item:hover .group-hover\:text-neutral-900 {
  color: var(--neutral-900) !important;
}

.group-item:hover .group-hover\:text-danger-50 {
  color: var(--danger-50) !important;
}

.group-item:hover .group-hover\:text-danger-100 {
  color: var(--danger-100) !important;
}

.group-item:hover .group-hover\:text-danger-200 {
  color: var(--danger-200) !important;
}

.group-item:hover .group-hover\:text-danger-300 {
  color: var(--danger-300) !important;
}

.group-item:hover .group-hover\:text-danger-400 {
  color: var(--danger-400) !important;
}

.group-item:hover .group-hover\:text-danger-500 {
  color: var(--danger-500) !important;
}

.group-item:hover .group-hover\:text-danger-600 {
  color: var(--danger-600) !important;
}

.group-item:hover .group-hover\:text-danger-700 {
  color: var(--danger-700) !important;
}

.group-item:hover .group-hover\:text-danger-800 {
  color: var(--danger-800) !important;
}

.group-item:hover .group-hover\:text-danger-900 {
  color: var(--danger-900) !important;
}

.group-item:hover .group-hover\:text-success-50 {
  color: var(--success-50) !important;
}

.group-item:hover .group-hover\:text-success-100 {
  color: var(--success-100) !important;
}

.group-item:hover .group-hover\:text-success-200 {
  color: var(--success-200) !important;
}

.group-item:hover .group-hover\:text-success-300 {
  color: var(--success-300) !important;
}

.group-item:hover .group-hover\:text-success-400 {
  color: var(--success-400) !important;
}

.group-item:hover .group-hover\:text-success-500 {
  color: var(--success-500) !important;
}

.group-item:hover .group-hover\:text-success-600 {
  color: var(--success-600) !important;
}

.group-item:hover .group-hover\:text-success-700 {
  color: var(--success-700) !important;
}

.group-item:hover .group-hover\:text-success-800 {
  color: var(--success-800) !important;
}

.group-item:hover .group-hover\:text-success-900 {
  color: var(--success-900) !important;
}

.group-item:hover .group-hover\:text-warning-50 {
  color: var(--warning-50) !important;
}

.group-item:hover .group-hover\:text-warning-100 {
  color: var(--warning-100) !important;
}

.group-item:hover .group-hover\:text-warning-200 {
  color: var(--warning-200) !important;
}

.group-item:hover .group-hover\:text-warning-300 {
  color: var(--warning-300) !important;
}

.group-item:hover .group-hover\:text-warning-400 {
  color: var(--warning-400) !important;
}

.group-item:hover .group-hover\:text-warning-500 {
  color: var(--warning-500) !important;
}

.group-item:hover .group-hover\:text-warning-600 {
  color: var(--warning-600) !important;
}

.group-item:hover .group-hover\:text-warning-700 {
  color: var(--warning-700) !important;
}

.group-item:hover .group-hover\:text-warning-800 {
  color: var(--warning-800) !important;
}

.group-item:hover .group-hover\:text-warning-900 {
  color: var(--warning-900) !important;
}

.group-item:hover .group-hover\:text-info-50 {
  color: var(--info-50) !important;
}

.group-item:hover .group-hover\:text-info-100 {
  color: var(--info-100) !important;
}

.group-item:hover .group-hover\:text-info-200 {
  color: var(--info-200) !important;
}

.group-item:hover .group-hover\:text-info-300 {
  color: var(--info-300) !important;
}

.group-item:hover .group-hover\:text-info-400 {
  color: var(--info-400) !important;
}

.group-item:hover .group-hover\:text-info-500 {
  color: var(--info-500) !important;
}

.group-item:hover .group-hover\:text-info-600 {
  color: var(--info-600) !important;
}

.group-item:hover .group-hover\:text-info-700 {
  color: var(--info-700) !important;
}

.group-item:hover .group-hover\:text-info-800 {
  color: var(--info-800) !important;
}

.group-item:hover .group-hover\:text-info-900 {
  color: var(--info-900) !important;
}

.group-item:hover .group-hover\:text-dark-1 {
  color: var(--dark-1) !important;
}

.group-item:hover .group-hover\:text-dark-2 {
  color: var(--dark-2) !important;
}

.group-item:hover .group-hover\:text-dark-3 {
  color: var(--dark-3) !important;
}

.group-item:hover .group-hover\:text-lilac-50 {
  color: var(--lilac-50) !important;
}

.group-item:hover .group-hover\:text-lilac-100 {
  color: var(--lilac-100) !important;
}

.group-item:hover .group-hover\:text-lilac-600 {
  color: var(--lilac-600) !important;
}

.group-item:hover .group-hover\:text-lilac-700 {
  color: var(--lilac-700) !important;
}

.group-item:hover .group-hover\:text-lilac-800 {
  color: var(--lilac-800) !important;
}

.group-item:hover .group-hover\:text-light-50 {
  color: var(--light-50) !important;
}

.group-item:hover .group-hover\:text-light-100 {
  color: var(--light-100) !important;
}

.group-item:hover .group-hover\:text-light-600 {
  color: var(--light-600) !important;
}

.group-item:hover .group-hover\:text-light-700 {
  color: var(--light-700) !important;
}

.group-item:hover .group-hover\:text-light-800 {
  color: var(--light-800) !important;
}

.group-item:hover .group-hover\:text-success-main {
  color: var(--success-main) !important;
}

.group-item:hover .group-hover\:text-success-surface {
  color: var(--success-surface) !important;
}

.group-item:hover .group-hover\:text-success-border {
  color: var(--success-border) !important;
}

.group-item:hover .group-hover\:text-success-hover {
  color: var(--success-hover) !important;
}

.group-item:hover .group-hover\:text-success-pressed {
  color: var(--success-pressed) !important;
}

.group-item:hover .group-hover\:text-success-focus {
  color: var(--success-focus) !important;
}

.group-item:hover .group-hover\:text-info-main {
  color: var(--info-main) !important;
}

.group-item:hover .group-hover\:text-info-surface {
  color: var(--info-surface) !important;
}

.group-item:hover .group-hover\:text-info-border {
  color: var(--info-border) !important;
}

.group-item:hover .group-hover\:text-info-hover {
  color: var(--info-hover) !important;
}

.group-item:hover .group-hover\:text-info-pressed {
  color: var(--info-pressed) !important;
}

.group-item:hover .group-hover\:text-info-focus {
  color: var(--info-focus) !important;
}

.group-item:hover .group-hover\:text-warning-main {
  color: var(--warning-main) !important;
}

.group-item:hover .group-hover\:text-warning-surface {
  color: var(--warning-surface) !important;
}

.group-item:hover .group-hover\:text-warning-border {
  color: var(--warning-border) !important;
}

.group-item:hover .group-hover\:text-warning-hover {
  color: var(--warning-hover) !important;
}

.group-item:hover .group-hover\:text-warning-pressed {
  color: var(--warning-pressed) !important;
}

.group-item:hover .group-hover\:text-warning-focus {
  color: var(--warning-focus) !important;
}

.group-item:hover .group-hover\:text-danger-main {
  color: var(--danger-main) !important;
}

.group-item:hover .group-hover\:text-danger-surface {
  color: var(--danger-surface) !important;
}

.group-item:hover .group-hover\:text-danger-border {
  color: var(--danger-border) !important;
}

.group-item:hover .group-hover\:text-danger-hover {
  color: var(--danger-hover) !important;
}

.group-item:hover .group-hover\:text-danger-pressed {
  color: var(--danger-pressed) !important;
}

.group-item:hover .group-hover\:text-danger-focus {
  color: var(--danger-focus) !important;
}

.text-cyan {
  color: var(--cyan);
}

.group-item:hover .group-hover\:text-cyan {
  color: var(--cyan) !important;
}

.text-indigo {
  color: var(--indigo);
}

.group-item:hover .group-hover\:text-indigo {
  color: var(--indigo) !important;
}

.text-purple {
  color: var(--purple);
}

.group-item:hover .group-hover\:text-purple {
  color: var(--purple) !important;
}

.text-red {
  color: var(--red);
}

.group-item:hover .group-hover\:text-red {
  color: var(--red) !important;
}

.text-yellow {
  color: var(--yellow);
}

.group-item:hover .group-hover\:text-yellow {
  color: var(--yellow) !important;
}

.text-orange {
  color: var(--orange);
}

.group-item:hover .group-hover\:text-orange {
  color: var(--orange) !important;
}

.text-pink {
  color: var(--pink);
}

.group-item:hover .group-hover\:text-pink {
  color: var(--pink) !important;
}

.group-item:hover .group-hover\:text-white {
  color: var(--base);
}

.text-hover-white:hover {
  color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.hover-scale-img__img {
  transition: 0.2s linear;
}

.hover-scale-img:hover .hover-scale-img__img {
  transform: scale(1.1);
}

.visibility-hidden {
  visibility: hidden;
}

/* === hover css end === */
/* === overlay css start === */
/* Overlay Css Start */
.gradient-overlay {
  position: relative;
  z-index: 1;
}

.gradient-overlay.top-0::before {
  transform: rotate(180deg);
}

.gradient-overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 22%,
    rgba(0, 0, 0, 0.69) 57.92%,
    rgba(0, 0, 0, 0.7) 100%
  );
  left: inherit;
  bottom: inherit;
}

/* Overlay Css End */
/* === overlay css end === */
/* === position css start === */
[dir="rtl"] .start-0 {
  left: auto !important;
  right: 0;
}

[dir="rtl"] .end-0 {
  right: auto !important;
  left: 0;
}

/* === position css end === */
/* === kanban css start === */
/* =============================== Kanban Card Css Start =============================== */
.ui-sortable-handle {
  cursor: move !important;
}

.ui-sortable-helper {
  box-shadow: 0px 5px 20px 3px var(--neutral-300);
  box-shadow: 0 4px 30px 0 rgba(4, 6, 15, 0.5) !important;
}

.ui-sortable-placeholder {
  background-color: var(--neutral-100) !important;
  visibility: visible !important;
  padding: 100px !important;
  border: 1px dashed var(--neutral-400);
}

#taskImagePreview {
  display: none;
  margin-top: 10px;
  max-width: 100%;
}

/* =============================== Kanban Card Css End =============================== */
/* === kanban css end === */
/* === preloader css start === */
/* === preloader css end === */
/* === buttons css start === */
.btn {
  padding: 0.5625rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
}

.btn.btn-lg {
  padding: 0.9375rem 0.875rem;
  font-weight: 600;
}

.btn.btn-sm {
  padding: 0.5rem 0.875rem;
  font-size: 0.875rem;
}

.btn-success {
  background-color: var(--success-main);
  color: #fff;
  border-color: var(--success-main);
}

.btn-success:hover {
  background-color: var(--success-hover);
  color: #fff;
  border-color: var(--success-main);
}

.btn-success:active,
.btn-success:focus {
  background-color: var(--success-pressed);
  color: #fff;
  border-color: var(--success-pressed);
}

.btn-danger {
  background-color: var(--danger-main);
  color: #fff;
  border-color: var(--danger-main);
}

.btn-danger:hover {
  background-color: var(--danger-hover);
  color: #fff;
  border-color: var(--danger-main);
}

.btn-danger:active,
.btn-danger:focus {
  background-color: var(--danger-pressed);
  color: #fff;
  border-color: var(--danger-pressed);
}

.btn-warning {
  background-color: var(--warning-main);
  color: #fff;
  border-color: var(--warning-main);
}

.btn-warning:hover {
  background-color: var(--warning-hover);
  color: #fff;
  border-color: var(--warning-main);
}

.btn-warning:active,
.btn-warning:focus {
  background-color: var(--warning-pressed);
  color: #fff;
  border-color: var(--warning-pressed);
}

.btn-info {
  background-color: var(--info-main);
  color: #fff;
  border-color: var(--info-main);
}

.btn-info:hover {
  background-color: var(--info-hover);
  color: #fff;
  border-color: var(--info-main);
}

.btn-info:active,
.btn-info:focus {
  background-color: var(--info-pressed);
  color: #fff;
  border-color: var(--info-pressed);
}

.btn-outline-success {
  background-color: transparent;
  color: var(--success-main);
  border-color: var(--success-main);
}

.btn-outline-success:hover {
  background-color: var(--success-hover);
  color: #fff;
  border-color: var(--success-main);
}

.btn-outline-success:active,
.btn-outline-success:focus {
  background-color: var(--success-pressed) !important;
  color: #fff !important;
  border-color: var(--success-pressed) !important;
}

.btn-outline-danger {
  background-color: transparent;
  color: var(--danger-main);
  border-color: var(--danger-main);
}

.btn-outline-danger:hover {
  background-color: var(--danger-hover);
  color: #fff;
  border-color: var(--danger-main);
}

.btn-outline-danger:active,
.btn-outline-danger:focus {
  background-color: var(--danger-pressed) !important;
  color: #fff !important;
  border-color: var(--danger-pressed) !important;
}

.btn-outline-warning {
  background-color: transparent;
  color: var(--warning-main);
  border-color: var(--warning-main);
}

.btn-outline-warning:hover {
  background-color: var(--warning-hover);
  color: #fff;
  border-color: var(--warning-main);
}

.btn-outline-warning:active,
.btn-outline-warning:focus {
  background-color: var(--warning-pressed) !important;
  color: #fff !important;
  border-color: var(--warning-pressed) !important;
}

.btn-outline-info {
  background-color: transparent;
  color: var(--info-main);
  border-color: var(--info-main);
}

.btn-outline-info:hover {
  background-color: var(--info-hover);
  color: #fff;
  border-color: var(--info-main);
}

.btn-outline-info:active,
.btn-outline-info:focus {
  background-color: var(--info-pressed) !important;
  color: #fff !important;
  border-color: var(--info-pressed) !important;
}

/* Pill Btn */
.btn-primary-50 {
  background-color: var(--primary-50);
  color: #fff;
  border-color: var(--primary-50);
}

.btn-primary-50:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-50:active,
.btn-primary-50:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-100 {
  background-color: var(--primary-100);
  color: #fff;
  border-color: var(--primary-100);
}

.btn-primary-100:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-100:active,
.btn-primary-100:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-200 {
  background-color: var(--primary-200);
  color: #fff;
  border-color: var(--primary-200);
}

.btn-primary-200:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-200:active,
.btn-primary-200:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-300 {
  background-color: var(--primary-300);
  color: #fff;
  border-color: var(--primary-300);
}

.btn-primary-300:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-300:active,
.btn-primary-300:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-400 {
  background-color: var(--primary-400);
  color: #fff;
  border-color: var(--primary-400);
}

.btn-primary-400:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-400:active,
.btn-primary-400:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-500 {
  background-color: var(--primary-500);
  color: #fff;
  border-color: var(--primary-500);
}

.btn-primary-500:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-500:active,
.btn-primary-500:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-600 {
  background-color: var(--primary-600);
  color: #fff;
  border-color: var(--primary-600);
}

.btn-primary-600:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-600:active,
.btn-primary-600:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-700 {
  background-color: var(--primary-700);
  color: #fff;
  border-color: var(--primary-700);
}

.btn-primary-700:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-700:active,
.btn-primary-700:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-800 {
  background-color: var(--primary-800);
  color: #fff;
  border-color: var(--primary-800);
}

.btn-primary-800:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-800:active,
.btn-primary-800:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-primary-900 {
  background-color: var(--primary-900);
  color: #fff;
  border-color: var(--primary-900);
}

.btn-primary-900:hover {
  background-color: var(--primary-700);
  color: #fff !important;
  border-color: var(--primary-700);
}

.btn-primary-900:active,
.btn-primary-900:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-neutral-50 {
  background-color: var(--neutral-50);
  color: #fff;
  border-color: var(--neutral-50);
}

.btn-neutral-50:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-50:active,
.btn-neutral-50:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-100 {
  background-color: var(--neutral-100);
  color: #fff;
  border-color: var(--neutral-100);
}

.btn-neutral-100:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-100:active,
.btn-neutral-100:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-200 {
  background-color: var(--neutral-200);
  color: #fff;
  border-color: var(--neutral-200);
}

.btn-neutral-200:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-200:active,
.btn-neutral-200:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-300 {
  background-color: var(--neutral-300);
  color: #fff;
  border-color: var(--neutral-300);
}

.btn-neutral-300:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-300:active,
.btn-neutral-300:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-400 {
  background-color: var(--neutral-400);
  color: #fff;
  border-color: var(--neutral-400);
}

.btn-neutral-400:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-400:active,
.btn-neutral-400:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-500 {
  background-color: var(--neutral-500);
  color: #fff;
  border-color: var(--neutral-500);
}

.btn-neutral-500:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-500:active,
.btn-neutral-500:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-600 {
  background-color: var(--neutral-600);
  color: #fff;
  border-color: var(--neutral-600);
}

.btn-neutral-600:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-600:active,
.btn-neutral-600:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-700 {
  background-color: var(--neutral-700);
  color: #fff;
  border-color: var(--neutral-700);
}

.btn-neutral-700:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-700:active,
.btn-neutral-700:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-800 {
  background-color: var(--neutral-800);
  color: #fff;
  border-color: var(--neutral-800);
}

.btn-neutral-800:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-800:active,
.btn-neutral-800:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-neutral-900 {
  background-color: var(--neutral-900);
  color: #fff;
  border-color: var(--neutral-900);
}

.btn-neutral-900:hover {
  background-color: var(--neutral-700);
  color: #fff !important;
  border-color: var(--neutral-700);
}

.btn-neutral-900:active,
.btn-neutral-900:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-danger-50 {
  background-color: var(--danger-50);
  color: #fff;
  border-color: var(--danger-50);
}

.btn-danger-50:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-50:active,
.btn-danger-50:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-100 {
  background-color: var(--danger-100);
  color: #fff;
  border-color: var(--danger-100);
}

.btn-danger-100:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-100:active,
.btn-danger-100:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-200 {
  background-color: var(--danger-200);
  color: #fff;
  border-color: var(--danger-200);
}

.btn-danger-200:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-200:active,
.btn-danger-200:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-300 {
  background-color: var(--danger-300);
  color: #fff;
  border-color: var(--danger-300);
}

.btn-danger-300:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-300:active,
.btn-danger-300:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-400 {
  background-color: var(--danger-400);
  color: #fff;
  border-color: var(--danger-400);
}

.btn-danger-400:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-400:active,
.btn-danger-400:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-500 {
  background-color: var(--danger-500);
  color: #fff;
  border-color: var(--danger-500);
}

.btn-danger-500:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-500:active,
.btn-danger-500:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-600 {
  background-color: var(--danger-600);
  color: #fff;
  border-color: var(--danger-600);
}

.btn-danger-600:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-600:active,
.btn-danger-600:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-700 {
  background-color: var(--danger-700);
  color: #fff;
  border-color: var(--danger-700);
}

.btn-danger-700:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-700:active,
.btn-danger-700:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-800 {
  background-color: var(--danger-800);
  color: #fff;
  border-color: var(--danger-800);
}

.btn-danger-800:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-800:active,
.btn-danger-800:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-danger-900 {
  background-color: var(--danger-900);
  color: #fff;
  border-color: var(--danger-900);
}

.btn-danger-900:hover {
  background-color: var(--danger-700);
  color: #fff !important;
  border-color: var(--danger-700);
}

.btn-danger-900:active,
.btn-danger-900:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-success-50 {
  background-color: var(--success-50);
  color: #fff;
  border-color: var(--success-50);
}

.btn-success-50:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-50:active,
.btn-success-50:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-100 {
  background-color: var(--success-100);
  color: #fff;
  border-color: var(--success-100);
}

.btn-success-100:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-100:active,
.btn-success-100:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-200 {
  background-color: var(--success-200);
  color: #fff;
  border-color: var(--success-200);
}

.btn-success-200:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-200:active,
.btn-success-200:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-300 {
  background-color: var(--success-300);
  color: #fff;
  border-color: var(--success-300);
}

.btn-success-300:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-300:active,
.btn-success-300:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-400 {
  background-color: var(--success-400);
  color: #fff;
  border-color: var(--success-400);
}

.btn-success-400:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-400:active,
.btn-success-400:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-500 {
  background-color: var(--success-500);
  color: #fff;
  border-color: var(--success-500);
}

.btn-success-500:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-500:active,
.btn-success-500:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-600 {
  background-color: var(--success-600);
  color: #fff;
  border-color: var(--success-600);
}

.btn-success-600:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-600:active,
.btn-success-600:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-700 {
  background-color: var(--success-700);
  color: #fff;
  border-color: var(--success-700);
}

.btn-success-700:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-700:active,
.btn-success-700:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-800 {
  background-color: var(--success-800);
  color: #fff;
  border-color: var(--success-800);
}

.btn-success-800:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-800:active,
.btn-success-800:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-success-900 {
  background-color: var(--success-900);
  color: #fff;
  border-color: var(--success-900);
}

.btn-success-900:hover {
  background-color: var(--success-700);
  color: #fff !important;
  border-color: var(--success-700);
}

.btn-success-900:active,
.btn-success-900:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-warning-50 {
  background-color: var(--warning-50);
  color: #fff;
  border-color: var(--warning-50);
}

.btn-warning-50:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-50:active,
.btn-warning-50:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-100 {
  background-color: var(--warning-100);
  color: #fff;
  border-color: var(--warning-100);
}

.btn-warning-100:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-100:active,
.btn-warning-100:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-200 {
  background-color: var(--warning-200);
  color: #fff;
  border-color: var(--warning-200);
}

.btn-warning-200:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-200:active,
.btn-warning-200:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-300 {
  background-color: var(--warning-300);
  color: #fff;
  border-color: var(--warning-300);
}

.btn-warning-300:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-300:active,
.btn-warning-300:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-400 {
  background-color: var(--warning-400);
  color: #fff;
  border-color: var(--warning-400);
}

.btn-warning-400:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-400:active,
.btn-warning-400:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-500 {
  background-color: var(--warning-500);
  color: #fff;
  border-color: var(--warning-500);
}

.btn-warning-500:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-500:active,
.btn-warning-500:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-600 {
  background-color: var(--warning-600);
  color: #fff;
  border-color: var(--warning-600);
}

.btn-warning-600:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-600:active,
.btn-warning-600:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-700 {
  background-color: var(--warning-700);
  color: #fff;
  border-color: var(--warning-700);
}

.btn-warning-700:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-700:active,
.btn-warning-700:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-800 {
  background-color: var(--warning-800);
  color: #fff;
  border-color: var(--warning-800);
}

.btn-warning-800:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-800:active,
.btn-warning-800:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-warning-900 {
  background-color: var(--warning-900);
  color: #fff;
  border-color: var(--warning-900);
}

.btn-warning-900:hover {
  background-color: var(--warning-700);
  color: #fff !important;
  border-color: var(--warning-700);
}

.btn-warning-900:active,
.btn-warning-900:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-info-50 {
  background-color: var(--info-50);
  color: #fff;
  border-color: var(--info-50);
}

.btn-info-50:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-50:active,
.btn-info-50:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-100 {
  background-color: var(--info-100);
  color: #fff;
  border-color: var(--info-100);
}

.btn-info-100:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-100:active,
.btn-info-100:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-200 {
  background-color: var(--info-200);
  color: #fff;
  border-color: var(--info-200);
}

.btn-info-200:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-200:active,
.btn-info-200:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-300 {
  background-color: var(--info-300);
  color: #fff;
  border-color: var(--info-300);
}

.btn-info-300:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-300:active,
.btn-info-300:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-400 {
  background-color: var(--info-400);
  color: #fff;
  border-color: var(--info-400);
}

.btn-info-400:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-400:active,
.btn-info-400:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-500 {
  background-color: var(--info-500);
  color: #fff;
  border-color: var(--info-500);
}

.btn-info-500:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-500:active,
.btn-info-500:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-600 {
  background-color: var(--info-600);
  color: #fff;
  border-color: var(--info-600);
}

.btn-info-600:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-600:active,
.btn-info-600:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-700 {
  background-color: var(--info-700);
  color: #fff;
  border-color: var(--info-700);
}

.btn-info-700:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-700:active,
.btn-info-700:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-800 {
  background-color: var(--info-800);
  color: #fff;
  border-color: var(--info-800);
}

.btn-info-800:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-800:active,
.btn-info-800:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-info-900 {
  background-color: var(--info-900);
  color: #fff;
  border-color: var(--info-900);
}

.btn-info-900:hover {
  background-color: var(--info-700);
  color: #fff !important;
  border-color: var(--info-700);
}

.btn-info-900:active,
.btn-info-900:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-dark-1 {
  background-color: var(--dark-1);
  color: #fff;
  border-color: var(--dark-1);
}

.btn-dark-1:hover {
  background-color: var(--dark-700);
  color: #fff !important;
  border-color: var(--dark-700);
}

.btn-dark-1:active,
.btn-dark-1:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-dark-2 {
  background-color: var(--dark-2);
  color: #fff;
  border-color: var(--dark-2);
}

.btn-dark-2:hover {
  background-color: var(--dark-700);
  color: #fff !important;
  border-color: var(--dark-700);
}

.btn-dark-2:active,
.btn-dark-2:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-dark-3 {
  background-color: var(--dark-3);
  color: #fff;
  border-color: var(--dark-3);
}

.btn-dark-3:hover {
  background-color: var(--dark-700);
  color: #fff !important;
  border-color: var(--dark-700);
}

.btn-dark-3:active,
.btn-dark-3:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-lilac-50 {
  background-color: var(--lilac-50);
  color: #fff;
  border-color: var(--lilac-50);
}

.btn-lilac-50:hover {
  background-color: var(--lilac-700);
  color: #fff !important;
  border-color: var(--lilac-700);
}

.btn-lilac-50:active,
.btn-lilac-50:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-lilac-100 {
  background-color: var(--lilac-100);
  color: #fff;
  border-color: var(--lilac-100);
}

.btn-lilac-100:hover {
  background-color: var(--lilac-700);
  color: #fff !important;
  border-color: var(--lilac-700);
}

.btn-lilac-100:active,
.btn-lilac-100:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-lilac-600 {
  background-color: var(--lilac-600);
  color: #fff;
  border-color: var(--lilac-600);
}

.btn-lilac-600:hover {
  background-color: var(--lilac-700);
  color: #fff !important;
  border-color: var(--lilac-700);
}

.btn-lilac-600:active,
.btn-lilac-600:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-lilac-700 {
  background-color: var(--lilac-700);
  color: #fff;
  border-color: var(--lilac-700);
}

.btn-lilac-700:hover {
  background-color: var(--lilac-700);
  color: #fff !important;
  border-color: var(--lilac-700);
}

.btn-lilac-700:active,
.btn-lilac-700:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-lilac-800 {
  background-color: var(--lilac-800);
  color: #fff;
  border-color: var(--lilac-800);
}

.btn-lilac-800:hover {
  background-color: var(--lilac-700);
  color: #fff !important;
  border-color: var(--lilac-700);
}

.btn-lilac-800:active,
.btn-lilac-800:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-light-50 {
  background-color: var(--light-50);
  color: #fff;
  border-color: var(--light-50);
}

.btn-light-50:hover {
  background-color: var(--light-700);
  color: #fff !important;
  border-color: var(--light-700);
}

.btn-light-50:active,
.btn-light-50:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-light-100 {
  background-color: var(--light-100);
  color: #fff;
  border-color: var(--light-100);
}

.btn-light-100:hover {
  background-color: var(--light-700);
  color: #fff !important;
  border-color: var(--light-700);
}

.btn-light-100:active,
.btn-light-100:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-light-600 {
  background-color: var(--light-600);
  color: #fff;
  border-color: var(--light-600);
}

.btn-light-600:hover {
  background-color: var(--light-700);
  color: #fff !important;
  border-color: var(--light-700);
}

.btn-light-600:active,
.btn-light-600:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-light-700 {
  background-color: var(--light-700);
  color: #fff;
  border-color: var(--light-700);
}

.btn-light-700:hover {
  background-color: var(--light-700);
  color: #fff !important;
  border-color: var(--light-700);
}

.btn-light-700:active,
.btn-light-700:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-light-800 {
  background-color: var(--light-800);
  color: #fff;
  border-color: var(--light-800);
}

.btn-light-800:hover {
  background-color: var(--light-700);
  color: #fff !important;
  border-color: var(--light-700);
}

.btn-light-800:active,
.btn-light-800:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

/* Outline Btn */
.btn-outline-primary-50 {
  background-color: transparent !important;
  color: var(--primary-50) !important;
  border-color: var(--primary-50) !important;
}

.btn-outline-primary-50:hover {
  background-color: var(--primary-50) !important;
  color: #fff !important;
  border-color: var(--primary-50) !important;
}

.btn-outline-primary-50:active,
.btn-outline-primary-50:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-100 {
  background-color: transparent !important;
  color: var(--primary-100) !important;
  border-color: var(--primary-100) !important;
}

.btn-outline-primary-100:hover {
  background-color: var(--primary-100) !important;
  color: #fff !important;
  border-color: var(--primary-100) !important;
}

.btn-outline-primary-100:active,
.btn-outline-primary-100:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-200 {
  background-color: transparent !important;
  color: var(--primary-200) !important;
  border-color: var(--primary-200) !important;
}

.btn-outline-primary-200:hover {
  background-color: var(--primary-200) !important;
  color: #fff !important;
  border-color: var(--primary-200) !important;
}

.btn-outline-primary-200:active,
.btn-outline-primary-200:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-300 {
  background-color: transparent !important;
  color: var(--primary-300) !important;
  border-color: var(--primary-300) !important;
}

.btn-outline-primary-300:hover {
  background-color: var(--primary-300) !important;
  color: #fff !important;
  border-color: var(--primary-300) !important;
}

.btn-outline-primary-300:active,
.btn-outline-primary-300:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-400 {
  background-color: transparent !important;
  color: var(--primary-400) !important;
  border-color: var(--primary-400) !important;
}

.btn-outline-primary-400:hover {
  background-color: var(--primary-400) !important;
  color: #fff !important;
  border-color: var(--primary-400) !important;
}

.btn-outline-primary-400:active,
.btn-outline-primary-400:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-500 {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border-color: var(--primary-500) !important;
}

.btn-outline-primary-500:hover {
  background-color: var(--primary-500) !important;
  color: #fff !important;
  border-color: var(--primary-500) !important;
}

.btn-outline-primary-500:active,
.btn-outline-primary-500:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-600 {
  background-color: transparent !important;
  color: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
}

.btn-outline-primary-600:hover {
  background-color: var(--primary-600) !important;
  color: #fff !important;
  border-color: var(--primary-600) !important;
}

.btn-outline-primary-600:active,
.btn-outline-primary-600:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-700 {
  background-color: transparent !important;
  color: var(--primary-700) !important;
  border-color: var(--primary-700) !important;
}

.btn-outline-primary-700:hover {
  background-color: var(--primary-700) !important;
  color: #fff !important;
  border-color: var(--primary-700) !important;
}

.btn-outline-primary-700:active,
.btn-outline-primary-700:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-800 {
  background-color: transparent !important;
  color: var(--primary-800) !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-800:hover {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-800:active,
.btn-outline-primary-800:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-primary-900 {
  background-color: transparent !important;
  color: var(--primary-900) !important;
  border-color: var(--primary-900) !important;
}

.btn-outline-primary-900:hover {
  background-color: var(--primary-900) !important;
  color: #fff !important;
  border-color: var(--primary-900) !important;
}

.btn-outline-primary-900:active,
.btn-outline-primary-900:focus {
  background-color: var(--primary-800) !important;
  color: #fff !important;
  border-color: var(--primary-800) !important;
}

.btn-outline-neutral-50 {
  background-color: transparent !important;
  color: var(--neutral-50) !important;
  border-color: var(--neutral-50) !important;
}

.btn-outline-neutral-50:hover {
  background-color: var(--neutral-50) !important;
  color: #fff !important;
  border-color: var(--neutral-50) !important;
}

.btn-outline-neutral-50:active,
.btn-outline-neutral-50:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-100 {
  background-color: transparent !important;
  color: var(--neutral-100) !important;
  border-color: var(--neutral-100) !important;
}

.btn-outline-neutral-100:hover {
  background-color: var(--neutral-100) !important;
  color: #fff !important;
  border-color: var(--neutral-100) !important;
}

.btn-outline-neutral-100:active,
.btn-outline-neutral-100:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-200 {
  background-color: transparent !important;
  color: var(--neutral-200) !important;
  border-color: var(--neutral-200) !important;
}

.btn-outline-neutral-200:hover {
  background-color: var(--neutral-200) !important;
  color: #fff !important;
  border-color: var(--neutral-200) !important;
}

.btn-outline-neutral-200:active,
.btn-outline-neutral-200:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-300 {
  background-color: transparent !important;
  color: var(--neutral-300) !important;
  border-color: var(--neutral-300) !important;
}

.btn-outline-neutral-300:hover {
  background-color: var(--neutral-300) !important;
  color: #fff !important;
  border-color: var(--neutral-300) !important;
}

.btn-outline-neutral-300:active,
.btn-outline-neutral-300:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-400 {
  background-color: transparent !important;
  color: var(--neutral-400) !important;
  border-color: var(--neutral-400) !important;
}

.btn-outline-neutral-400:hover {
  background-color: var(--neutral-400) !important;
  color: #fff !important;
  border-color: var(--neutral-400) !important;
}

.btn-outline-neutral-400:active,
.btn-outline-neutral-400:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-500 {
  background-color: transparent !important;
  color: var(--neutral-500) !important;
  border-color: var(--neutral-500) !important;
}

.btn-outline-neutral-500:hover {
  background-color: var(--neutral-500) !important;
  color: #fff !important;
  border-color: var(--neutral-500) !important;
}

.btn-outline-neutral-500:active,
.btn-outline-neutral-500:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-600 {
  background-color: transparent !important;
  color: var(--neutral-600) !important;
  border-color: var(--neutral-600) !important;
}

.btn-outline-neutral-600:hover {
  background-color: var(--neutral-600) !important;
  color: #fff !important;
  border-color: var(--neutral-600) !important;
}

.btn-outline-neutral-600:active,
.btn-outline-neutral-600:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-700 {
  background-color: transparent !important;
  color: var(--neutral-700) !important;
  border-color: var(--neutral-700) !important;
}

.btn-outline-neutral-700:hover {
  background-color: var(--neutral-700) !important;
  color: #fff !important;
  border-color: var(--neutral-700) !important;
}

.btn-outline-neutral-700:active,
.btn-outline-neutral-700:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-800 {
  background-color: transparent !important;
  color: var(--neutral-800) !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-800:hover {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-800:active,
.btn-outline-neutral-800:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-neutral-900 {
  background-color: transparent !important;
  color: var(--neutral-900) !important;
  border-color: var(--neutral-900) !important;
}

.btn-outline-neutral-900:hover {
  background-color: var(--neutral-900) !important;
  color: #fff !important;
  border-color: var(--neutral-900) !important;
}

.btn-outline-neutral-900:active,
.btn-outline-neutral-900:focus {
  background-color: var(--neutral-800) !important;
  color: #fff !important;
  border-color: var(--neutral-800) !important;
}

.btn-outline-danger-50 {
  background-color: transparent !important;
  color: var(--danger-50) !important;
  border-color: var(--danger-50) !important;
}

.btn-outline-danger-50:hover {
  background-color: var(--danger-50) !important;
  color: #fff !important;
  border-color: var(--danger-50) !important;
}

.btn-outline-danger-50:active,
.btn-outline-danger-50:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-100 {
  background-color: transparent !important;
  color: var(--danger-100) !important;
  border-color: var(--danger-100) !important;
}

.btn-outline-danger-100:hover {
  background-color: var(--danger-100) !important;
  color: #fff !important;
  border-color: var(--danger-100) !important;
}

.btn-outline-danger-100:active,
.btn-outline-danger-100:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-200 {
  background-color: transparent !important;
  color: var(--danger-200) !important;
  border-color: var(--danger-200) !important;
}

.btn-outline-danger-200:hover {
  background-color: var(--danger-200) !important;
  color: #fff !important;
  border-color: var(--danger-200) !important;
}

.btn-outline-danger-200:active,
.btn-outline-danger-200:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-300 {
  background-color: transparent !important;
  color: var(--danger-300) !important;
  border-color: var(--danger-300) !important;
}

.btn-outline-danger-300:hover {
  background-color: var(--danger-300) !important;
  color: #fff !important;
  border-color: var(--danger-300) !important;
}

.btn-outline-danger-300:active,
.btn-outline-danger-300:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-400 {
  background-color: transparent !important;
  color: var(--danger-400) !important;
  border-color: var(--danger-400) !important;
}

.btn-outline-danger-400:hover {
  background-color: var(--danger-400) !important;
  color: #fff !important;
  border-color: var(--danger-400) !important;
}

.btn-outline-danger-400:active,
.btn-outline-danger-400:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-500 {
  background-color: transparent !important;
  color: var(--danger-500) !important;
  border-color: var(--danger-500) !important;
}

.btn-outline-danger-500:hover {
  background-color: var(--danger-500) !important;
  color: #fff !important;
  border-color: var(--danger-500) !important;
}

.btn-outline-danger-500:active,
.btn-outline-danger-500:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-600 {
  background-color: transparent !important;
  color: var(--danger-600) !important;
  border-color: var(--danger-600) !important;
}

.btn-outline-danger-600:hover {
  background-color: var(--danger-600) !important;
  color: #fff !important;
  border-color: var(--danger-600) !important;
}

.btn-outline-danger-600:active,
.btn-outline-danger-600:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-700 {
  background-color: transparent !important;
  color: var(--danger-700) !important;
  border-color: var(--danger-700) !important;
}

.btn-outline-danger-700:hover {
  background-color: var(--danger-700) !important;
  color: #fff !important;
  border-color: var(--danger-700) !important;
}

.btn-outline-danger-700:active,
.btn-outline-danger-700:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-800 {
  background-color: transparent !important;
  color: var(--danger-800) !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-800:hover {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-800:active,
.btn-outline-danger-800:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-danger-900 {
  background-color: transparent !important;
  color: var(--danger-900) !important;
  border-color: var(--danger-900) !important;
}

.btn-outline-danger-900:hover {
  background-color: var(--danger-900) !important;
  color: #fff !important;
  border-color: var(--danger-900) !important;
}

.btn-outline-danger-900:active,
.btn-outline-danger-900:focus {
  background-color: var(--danger-800) !important;
  color: #fff !important;
  border-color: var(--danger-800) !important;
}

.btn-outline-success-50 {
  background-color: transparent !important;
  color: var(--success-50) !important;
  border-color: var(--success-50) !important;
}

.btn-outline-success-50:hover {
  background-color: var(--success-50) !important;
  color: #fff !important;
  border-color: var(--success-50) !important;
}

.btn-outline-success-50:active,
.btn-outline-success-50:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-100 {
  background-color: transparent !important;
  color: var(--success-100) !important;
  border-color: var(--success-100) !important;
}

.btn-outline-success-100:hover {
  background-color: var(--success-100) !important;
  color: #fff !important;
  border-color: var(--success-100) !important;
}

.btn-outline-success-100:active,
.btn-outline-success-100:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-200 {
  background-color: transparent !important;
  color: var(--success-200) !important;
  border-color: var(--success-200) !important;
}

.btn-outline-success-200:hover {
  background-color: var(--success-200) !important;
  color: #fff !important;
  border-color: var(--success-200) !important;
}

.btn-outline-success-200:active,
.btn-outline-success-200:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-300 {
  background-color: transparent !important;
  color: var(--success-300) !important;
  border-color: var(--success-300) !important;
}

.btn-outline-success-300:hover {
  background-color: var(--success-300) !important;
  color: #fff !important;
  border-color: var(--success-300) !important;
}

.btn-outline-success-300:active,
.btn-outline-success-300:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-400 {
  background-color: transparent !important;
  color: var(--success-400) !important;
  border-color: var(--success-400) !important;
}

.btn-outline-success-400:hover {
  background-color: var(--success-400) !important;
  color: #fff !important;
  border-color: var(--success-400) !important;
}

.btn-outline-success-400:active,
.btn-outline-success-400:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-500 {
  background-color: transparent !important;
  color: var(--success-500) !important;
  border-color: var(--success-500) !important;
}

.btn-outline-success-500:hover {
  background-color: var(--success-500) !important;
  color: #fff !important;
  border-color: var(--success-500) !important;
}

.btn-outline-success-500:active,
.btn-outline-success-500:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-600 {
  background-color: transparent !important;
  color: var(--success-600) !important;
  border-color: var(--success-600) !important;
}

.btn-outline-success-600:hover {
  background-color: var(--success-600) !important;
  color: #fff !important;
  border-color: var(--success-600) !important;
}

.btn-outline-success-600:active,
.btn-outline-success-600:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-700 {
  background-color: transparent !important;
  color: var(--success-700) !important;
  border-color: var(--success-700) !important;
}

.btn-outline-success-700:hover {
  background-color: var(--success-700) !important;
  color: #fff !important;
  border-color: var(--success-700) !important;
}

.btn-outline-success-700:active,
.btn-outline-success-700:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-800 {
  background-color: transparent !important;
  color: var(--success-800) !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-800:hover {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-800:active,
.btn-outline-success-800:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-success-900 {
  background-color: transparent !important;
  color: var(--success-900) !important;
  border-color: var(--success-900) !important;
}

.btn-outline-success-900:hover {
  background-color: var(--success-900) !important;
  color: #fff !important;
  border-color: var(--success-900) !important;
}

.btn-outline-success-900:active,
.btn-outline-success-900:focus {
  background-color: var(--success-800) !important;
  color: #fff !important;
  border-color: var(--success-800) !important;
}

.btn-outline-warning-50 {
  background-color: transparent !important;
  color: var(--warning-50) !important;
  border-color: var(--warning-50) !important;
}

.btn-outline-warning-50:hover {
  background-color: var(--warning-50) !important;
  color: #fff !important;
  border-color: var(--warning-50) !important;
}

.btn-outline-warning-50:active,
.btn-outline-warning-50:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-100 {
  background-color: transparent !important;
  color: var(--warning-100) !important;
  border-color: var(--warning-100) !important;
}

.btn-outline-warning-100:hover {
  background-color: var(--warning-100) !important;
  color: #fff !important;
  border-color: var(--warning-100) !important;
}

.btn-outline-warning-100:active,
.btn-outline-warning-100:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-200 {
  background-color: transparent !important;
  color: var(--warning-200) !important;
  border-color: var(--warning-200) !important;
}

.btn-outline-warning-200:hover {
  background-color: var(--warning-200) !important;
  color: #fff !important;
  border-color: var(--warning-200) !important;
}

.btn-outline-warning-200:active,
.btn-outline-warning-200:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-300 {
  background-color: transparent !important;
  color: var(--warning-300) !important;
  border-color: var(--warning-300) !important;
}

.btn-outline-warning-300:hover {
  background-color: var(--warning-300) !important;
  color: #fff !important;
  border-color: var(--warning-300) !important;
}

.btn-outline-warning-300:active,
.btn-outline-warning-300:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-400 {
  background-color: transparent !important;
  color: var(--warning-400) !important;
  border-color: var(--warning-400) !important;
}

.btn-outline-warning-400:hover {
  background-color: var(--warning-400) !important;
  color: #fff !important;
  border-color: var(--warning-400) !important;
}

.btn-outline-warning-400:active,
.btn-outline-warning-400:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-500 {
  background-color: transparent !important;
  color: var(--warning-500) !important;
  border-color: var(--warning-500) !important;
}

.btn-outline-warning-500:hover {
  background-color: var(--warning-500) !important;
  color: #fff !important;
  border-color: var(--warning-500) !important;
}

.btn-outline-warning-500:active,
.btn-outline-warning-500:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-600 {
  background-color: transparent !important;
  color: var(--warning-600) !important;
  border-color: var(--warning-600) !important;
}

.btn-outline-warning-600:hover {
  background-color: var(--warning-600) !important;
  color: #fff !important;
  border-color: var(--warning-600) !important;
}

.btn-outline-warning-600:active,
.btn-outline-warning-600:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-700 {
  background-color: transparent !important;
  color: var(--warning-700) !important;
  border-color: var(--warning-700) !important;
}

.btn-outline-warning-700:hover {
  background-color: var(--warning-700) !important;
  color: #fff !important;
  border-color: var(--warning-700) !important;
}

.btn-outline-warning-700:active,
.btn-outline-warning-700:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-800 {
  background-color: transparent !important;
  color: var(--warning-800) !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-800:hover {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-800:active,
.btn-outline-warning-800:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-warning-900 {
  background-color: transparent !important;
  color: var(--warning-900) !important;
  border-color: var(--warning-900) !important;
}

.btn-outline-warning-900:hover {
  background-color: var(--warning-900) !important;
  color: #fff !important;
  border-color: var(--warning-900) !important;
}

.btn-outline-warning-900:active,
.btn-outline-warning-900:focus {
  background-color: var(--warning-800) !important;
  color: #fff !important;
  border-color: var(--warning-800) !important;
}

.btn-outline-info-50 {
  background-color: transparent !important;
  color: var(--info-50) !important;
  border-color: var(--info-50) !important;
}

.btn-outline-info-50:hover {
  background-color: var(--info-50) !important;
  color: #fff !important;
  border-color: var(--info-50) !important;
}

.btn-outline-info-50:active,
.btn-outline-info-50:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-100 {
  background-color: transparent !important;
  color: var(--info-100) !important;
  border-color: var(--info-100) !important;
}

.btn-outline-info-100:hover {
  background-color: var(--info-100) !important;
  color: #fff !important;
  border-color: var(--info-100) !important;
}

.btn-outline-info-100:active,
.btn-outline-info-100:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-200 {
  background-color: transparent !important;
  color: var(--info-200) !important;
  border-color: var(--info-200) !important;
}

.btn-outline-info-200:hover {
  background-color: var(--info-200) !important;
  color: #fff !important;
  border-color: var(--info-200) !important;
}

.btn-outline-info-200:active,
.btn-outline-info-200:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-300 {
  background-color: transparent !important;
  color: var(--info-300) !important;
  border-color: var(--info-300) !important;
}

.btn-outline-info-300:hover {
  background-color: var(--info-300) !important;
  color: #fff !important;
  border-color: var(--info-300) !important;
}

.btn-outline-info-300:active,
.btn-outline-info-300:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-400 {
  background-color: transparent !important;
  color: var(--info-400) !important;
  border-color: var(--info-400) !important;
}

.btn-outline-info-400:hover {
  background-color: var(--info-400) !important;
  color: #fff !important;
  border-color: var(--info-400) !important;
}

.btn-outline-info-400:active,
.btn-outline-info-400:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-500 {
  background-color: transparent !important;
  color: var(--info-500) !important;
  border-color: var(--info-500) !important;
}

.btn-outline-info-500:hover {
  background-color: var(--info-500) !important;
  color: #fff !important;
  border-color: var(--info-500) !important;
}

.btn-outline-info-500:active,
.btn-outline-info-500:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-600 {
  background-color: transparent !important;
  color: var(--info-600) !important;
  border-color: var(--info-600) !important;
}

.btn-outline-info-600:hover {
  background-color: var(--info-600) !important;
  color: #fff !important;
  border-color: var(--info-600) !important;
}

.btn-outline-info-600:active,
.btn-outline-info-600:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-700 {
  background-color: transparent !important;
  color: var(--info-700) !important;
  border-color: var(--info-700) !important;
}

.btn-outline-info-700:hover {
  background-color: var(--info-700) !important;
  color: #fff !important;
  border-color: var(--info-700) !important;
}

.btn-outline-info-700:active,
.btn-outline-info-700:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-800 {
  background-color: transparent !important;
  color: var(--info-800) !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-800:hover {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-800:active,
.btn-outline-info-800:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-info-900 {
  background-color: transparent !important;
  color: var(--info-900) !important;
  border-color: var(--info-900) !important;
}

.btn-outline-info-900:hover {
  background-color: var(--info-900) !important;
  color: #fff !important;
  border-color: var(--info-900) !important;
}

.btn-outline-info-900:active,
.btn-outline-info-900:focus {
  background-color: var(--info-800) !important;
  color: #fff !important;
  border-color: var(--info-800) !important;
}

.btn-outline-dark-1 {
  background-color: transparent !important;
  color: var(--dark-1) !important;
  border-color: var(--dark-1) !important;
}

.btn-outline-dark-1:hover {
  background-color: var(--dark-1) !important;
  color: #fff !important;
  border-color: var(--dark-1) !important;
}

.btn-outline-dark-1:active,
.btn-outline-dark-1:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-outline-dark-2 {
  background-color: transparent !important;
  color: var(--dark-2) !important;
  border-color: var(--dark-2) !important;
}

.btn-outline-dark-2:hover {
  background-color: var(--dark-2) !important;
  color: #fff !important;
  border-color: var(--dark-2) !important;
}

.btn-outline-dark-2:active,
.btn-outline-dark-2:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-outline-dark-3 {
  background-color: transparent !important;
  color: var(--dark-3) !important;
  border-color: var(--dark-3) !important;
}

.btn-outline-dark-3:hover {
  background-color: var(--dark-3) !important;
  color: #fff !important;
  border-color: var(--dark-3) !important;
}

.btn-outline-dark-3:active,
.btn-outline-dark-3:focus {
  background-color: var(--dark-800) !important;
  color: #fff !important;
  border-color: var(--dark-800) !important;
}

.btn-outline-lilac-50 {
  background-color: transparent !important;
  color: var(--lilac-50) !important;
  border-color: var(--lilac-50) !important;
}

.btn-outline-lilac-50:hover {
  background-color: var(--lilac-50) !important;
  color: #fff !important;
  border-color: var(--lilac-50) !important;
}

.btn-outline-lilac-50:active,
.btn-outline-lilac-50:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-100 {
  background-color: transparent !important;
  color: var(--lilac-100) !important;
  border-color: var(--lilac-100) !important;
}

.btn-outline-lilac-100:hover {
  background-color: var(--lilac-100) !important;
  color: #fff !important;
  border-color: var(--lilac-100) !important;
}

.btn-outline-lilac-100:active,
.btn-outline-lilac-100:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-600 {
  background-color: transparent !important;
  color: var(--lilac-600) !important;
  border-color: var(--lilac-600) !important;
}

.btn-outline-lilac-600:hover {
  background-color: var(--lilac-600) !important;
  color: #fff !important;
  border-color: var(--lilac-600) !important;
}

.btn-outline-lilac-600:active,
.btn-outline-lilac-600:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-700 {
  background-color: transparent !important;
  color: var(--lilac-700) !important;
  border-color: var(--lilac-700) !important;
}

.btn-outline-lilac-700:hover {
  background-color: var(--lilac-700) !important;
  color: #fff !important;
  border-color: var(--lilac-700) !important;
}

.btn-outline-lilac-700:active,
.btn-outline-lilac-700:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-800 {
  background-color: transparent !important;
  color: var(--lilac-800) !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-800:hover {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-lilac-800:active,
.btn-outline-lilac-800:focus {
  background-color: var(--lilac-800) !important;
  color: #fff !important;
  border-color: var(--lilac-800) !important;
}

.btn-outline-light-50 {
  background-color: transparent !important;
  color: var(--light-50) !important;
  border-color: var(--light-50) !important;
}

.btn-outline-light-50:hover {
  background-color: var(--light-50) !important;
  color: #fff !important;
  border-color: var(--light-50) !important;
}

.btn-outline-light-50:active,
.btn-outline-light-50:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-100 {
  background-color: transparent !important;
  color: var(--light-100) !important;
  border-color: var(--light-100) !important;
}

.btn-outline-light-100:hover {
  background-color: var(--light-100) !important;
  color: #fff !important;
  border-color: var(--light-100) !important;
}

.btn-outline-light-100:active,
.btn-outline-light-100:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-600 {
  background-color: transparent !important;
  color: var(--light-600) !important;
  border-color: var(--light-600) !important;
}

.btn-outline-light-600:hover {
  background-color: var(--light-600) !important;
  color: #fff !important;
  border-color: var(--light-600) !important;
}

.btn-outline-light-600:active,
.btn-outline-light-600:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-700 {
  background-color: transparent !important;
  color: var(--light-700) !important;
  border-color: var(--light-700) !important;
}

.btn-outline-light-700:hover {
  background-color: var(--light-700) !important;
  color: #fff !important;
  border-color: var(--light-700) !important;
}

.btn-outline-light-700:active,
.btn-outline-light-700:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-800 {
  background-color: transparent !important;
  color: var(--light-800) !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-800:hover {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

.btn-outline-light-800:active,
.btn-outline-light-800:focus {
  background-color: var(--light-800) !important;
  color: #fff !important;
  border-color: var(--light-800) !important;
}

/* === buttons css end === */
/* === badge css start === */
/* === badge css end === */
/* === table css start === */
/* Table Common Css Start */
.basic-table thead th {
  background-color: var(--neutral-50);
  color: var(--text-primary-light);
  font-weight: 600;
  border-bottom: 1px solid var(--neutral-200);
  padding: 0.8125rem 1.5rem;
}

.basic-table tbody tr:last-child td {
  padding-bottom: 0;
}

.basic-table tbody td {
  background-color: var(--white);
  color: var(--text-secondary-light);
  border: none;
  padding: 0.8125rem 1.5rem;
}

.bordered-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.bordered-table thead tr th {
  background-color: var(--neutral-50) !important;
  border-bottom: 1px solid var(--neutral-200) !important;
  color: var(--text-primary-light) !important;
  padding: 16px !important;
  font-size: 1rem;
  font-weight: 600;
}

.bordered-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.bordered-table thead tr th:last-child {
  border-start-end-radius: 8px;
}

.bordered-table thead tr th:not(:last-child) {
  border-inline-end: 0 !important;
}

.bordered-table thead tr th:not(:first-child) {
  border-inline-start: 0 !important;
}

.bordered-table tbody tr:last-child td {
  border-bottom: 0;
}

.bordered-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.bordered-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.bordered-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 16px !important;
  border-bottom: 1px solid var(--neutral-200);
  background-color: var(--white);
  vertical-align: middle;
}

.rounded-table {
  border: 0 !important;
}

.rounded-table thead tr th {
  border-bottom: 0 !important;
}

.rounded-table thead tr th:first-child {
  border-radius: 8px !important;
}

.basic-border-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.basic-border-table thead tr th {
  background-color: var(--neutral-50);
  border-bottom: 1px solid var(--neutral-200);
  border-inline-end: 1px solid var(--neutral-200) !important;
  color: var(--text-primary-light);
  padding: 0.8125rem 1.5rem;
  font-weight: 600;
}

.basic-border-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.basic-border-table thead tr th:last-child {
  border-start-end-radius: 8px;
  border-inline-end: none !important;
}

.basic-border-table thead tr th:not(:last-child) {
  border-inline-end: 0;
}

.basic-border-table thead tr th:not(:first-child) {
  border-inline-start: 0;
}

.basic-border-table tbody tr:last-child td {
  border-bottom: 0;
}

.basic-border-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.basic-border-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.basic-border-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 0.8125rem 1.5rem;
  border-bottom: 1px solid var(--neutral-200);
  border-inline-end: 1px solid var(--neutral-200);
  background-color: var(--white);
  vertical-align: middle;
}

.basic-border-table tbody tr td:last-child {
  border-inline-end: none;
}

.striped-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.striped-table thead tr th {
  background-color: transparent;
  border-bottom: 1px solid var(--neutral-200);
  color: var(--text-primary-light);
  padding: 0.8125rem 1rem;
  font-weight: 600;
}

.striped-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.striped-table thead tr th:last-child {
  border-start-end-radius: 8px;
}

.striped-table thead tr th:not(:last-child) {
  border-inline-end: 0;
}

.striped-table thead tr th:not(:first-child) {
  border-inline-start: 0;
}

.striped-table tbody tr:last-child td {
  border-bottom: 0;
}

.striped-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.striped-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.striped-table tbody tr:nth-child(odd) td {
  background-color: var(--neutral-50);
}

.striped-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 0.8125rem 1rem;
  border-bottom: 1px solid var(--neutral-200);
  background-color: var(--white);
  vertical-align: middle;
}

.vertical-striped-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.vertical-striped-table thead tr th {
  background-color: var(--neutral-50);
  border-bottom: 1px solid var(--neutral-300);
  color: var(--text-primary-light);
  padding: 0.8125rem 1rem;
  font-weight: 600;
}

.vertical-striped-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.vertical-striped-table thead tr th:last-child {
  border-start-end-radius: 8px;
}

.vertical-striped-table thead tr th:not(:first-child) {
  border-inline-start: 0;
}

.vertical-striped-table thead tr th:not(:last-child) {
  border-inline-end: 0;
}

.vertical-striped-table tbody tr:last-child td {
  border-bottom: 0;
}

.vertical-striped-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.vertical-striped-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.vertical-striped-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 0.8125rem 1rem;
  border-bottom: 1px solid var(--neutral-300);
  background-color: var(--white);
  vertical-align: middle;
}

.vertical-striped-table tbody tr td:nth-child(odd) {
  background-color: var(--neutral-50);
}

.border-primary-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--primary-600);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.border-primary-table thead tr th {
  background-color: var(--neutral-50);
  border-bottom: 1px solid var(--primary-600);
  border-inline-end: 1px solid var(--primary-600);
  color: var(--text-primary-light);
  padding: 0.8125rem 1rem;
  font-weight: 600;
}

.border-primary-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.border-primary-table thead tr th:last-child {
  border-start-end-radius: 8px;
  border-inline-end: none;
}

.border-primary-table tbody tr:last-child td {
  border-bottom: 0;
}

.border-primary-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.border-primary-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.border-primary-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 0.8125rem 1rem;
  border-bottom: 1px solid var(--primary-600);
  border-inline-end: 1px solid var(--primary-600);
  background-color: var(--white);
  vertical-align: middle;
}

.border-primary-table tbody tr td:last-child {
  border-inline-end: none;
}

.colored-row-table {
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  min-width: max-content;
  vertical-align: middle;
}

.colored-row-table thead tr th {
  background-color: var(--neutral-50);
  border-bottom: 1px solid var(--neutral-200);
  color: var(--text-primary-light);
  padding: 0.8125rem 1.5rem;
  font-weight: 600;
}

.colored-row-table thead tr th:first-child {
  border-start-start-radius: 8px;
}

.colored-row-table thead tr th:last-child {
  border-start-end-radius: 8px;
}

.colored-row-table thead tr th:not(:first-child) {
  border-inline-start: 0;
}

.colored-row-table thead tr th:not(:last-child) {
  border-inline-end: 0;
}

.colored-row-table tbody tr:last-child td {
  border-bottom: 0;
}

.colored-row-table tbody tr:last-child td:first-child {
  border-end-start-radius: 8px;
}

.colored-row-table tbody tr:last-child td:last-child {
  border-end-end-radius: 8px;
}

.colored-row-table tbody tr td {
  color: var(--text-secondary-light);
  padding: 0.5625rem 1.5rem;
  background-color: var(--white);
  vertical-align: middle;
}

.dataTable tbody tr:last-child td {
  border-bottom: 0 !important;
}

.basic-data-table .dt-layout-row {
  padding-bottom: 0.875rem;
}

.basic-data-table .dt-layout-row select {
  -webkit-appearance: auto;
  appearance: auto;
  color: var(--text-secondary-light) !important;
  height: 2.5rem;
  border: 1px solid var(--input-form-light) !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  padding: 0.625rem 0.3125rem 0.625rem 0.625rem !important;
}

.basic-data-table .dt-layout-row .dt-layout-cell {
  padding: 0;
}

.basic-data-table .dt-layout-row .dt-length {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.9375rem;
}

.basic-data-table .dt-layout-row .dt-search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.9375rem;
  justify-content: flex-end;
}

.basic-data-table .dt-layout-row .dt-search .dt-input {
  color: var(--text-secondary-light) !important;
  height: 2.5rem;
  border: 1px solid var(--input-form-light) !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.basic-data-table .dt-layout-row div,
.basic-data-table .dt-layout-row label {
  color: var(--text-secondary-light);
}

div.dt-container .dt-paging {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

div.dt-container .dt-paging .dt-paging-button {
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
}

div.dt-container
  .dt-paging
  .dt-paging-button:not(.first, .previous, .next, .last) {
  color: var(--text-secondary-light) !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  background: var(--neutral-200) !important;
  font-weight: 500;
  border: none !important;
}

div.dt-container .dt-paging .dt-paging-button:focus {
  border: none !important;
  box-shadow: none !important;
}

div.dt-container .dt-paging .dt-paging-button:hover,
div.dt-container .dt-paging .dt-paging-button.current {
  background: var(--primary-600) !important;
  color: #fff !important;
}

div.dt-container .dt-paging .dt-paging-button:hover:hover,
div.dt-container .dt-paging .dt-paging-button.current:hover {
  color: #fff !important;
}

/* Table Sm Code */
.sm-table thead tr th,
.sm-table thead tr td,
.sm-table tbody tr th,
.sm-table tbody tr td {
  padding: 10px 16px !important;
}

/* Table Common Css End */
/* Table Sm Code */
.xsm-table thead tr th,
.xsm-table thead tr td,
.xsm-table tbody tr th,
.xsm-table tbody tr td {
  padding: 4px 16px !important;
}

/* Table Common Css End */
/* === table css end === */
/* === form css start === */
.form-select,
.form-control,
textarea {
  border: 1px solid var(--input-form-light);
  color: var(--text-primary-light) !important;
  background-color: var(--white);
  padding: 0.5625rem 1.25rem;
}

.form-select::placeholder,
.form-control::placeholder,
textarea::placeholder {
  color: var(--text-secondary-light);
  font-size: 1rem !important;
}

.form-select:focus,
.form-select:active,
.form-control:focus,
.form-control:active,
textarea:focus,
textarea:active {
  box-shadow: none;
  border-color: var(--primary-600) !important;
  background-color: transparent;
}

.form-select {
  padding: 0.5625rem 1.875rem 0.5625rem 0.9375rem;
  background-position-x: right;
}

.form-select.form-select-lg {
  height: 56px;
  font-size: 1.125rem;
}

.form-select.form-select-sm {
  height: 40px;
  font-size: 0.875rem;
}

[dir="rtl"] .form-select {
  background-position-x: left;
}

.form-select,
.form-control:not(textarea) {
  height: 2.75rem;
}

.form-control[type="file"] {
  line-height: 2;
}

.form-control[readonly] {
  background-color: var(--neutral-50);
}

.form-control.form-control-lg {
  height: 56px;
  font-size: 1.125rem;
}

.form-control.form-control-lg[type="file"] {
  line-height: 2.1;
}

.form-control.form-control-sm {
  height: 40px;
  font-size: 0.875rem;
}

.form-control.form-control-sm[type="file"] {
  line-height: 2.2;
}

.invoive-form-control {
  width: 130px;
  border-bottom: 1px solid var(--input-form-light);
}

.form-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-secondary-light);
}

.input-group-text {
  color: var(--text-secondary-light);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--danger-main);
}

.invalid-feedback {
  color: var(--danger-main);
}

/* icon field css start */
.icon-field {
  position: relative;
}

.icon-field .icon {
  position: absolute;
  top: 12px;
  inset-inline-start: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: var(--text-secondary-light);
}

.icon-field .form-control {
  padding-inline-start: 2.5rem;
}

.icon-field .form-control.form-control-lg ~ .icon {
  top: 18px;
}

.icon-field .form-control.form-control-sm ~ .icon {
  top: 10px;
}

/* icon field css end */
/* input Radio Css Start */
.form-check-input {
  cursor: pointer;
}

.form-check {
  margin-bottom: 0;
  padding-left: 0;
  gap: 12px;
}

.form-check.style-check .form-check-input::before {
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  transform: translate(-50%, -50%) scale(1);
  /* transition: 0.2s linear; */
}

.form-check.style-check .form-check-input::after {
  position: absolute;
  content: "\eb7a";
  font-family: "remixicon";
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  /* transition: 0.2s linear; */
  border-radius: inherit !important;
}

.form-check.style-check .form-check-input:checked {
  border-color: var(--primary-600) !important;
}

.form-check.style-check .form-check-input:checked::after {
  visibility: visible;
  opacity: 1;
}

.form-check .form-check-input {
  border: 1px solid var(--text-secondary-light);
  background: transparent;
  width: 18px;
  height: 18px;
  position: relative;
  flex-shrink: 0;
  margin-left: 0;
  margin-top: 0;
  cursor: pointer;
}

.form-check .form-check-input:checked[type="radio"] {
  background-image: none;
  border-color: var(--primary-600);
}

.form-check .form-check-input:checked::before {
  transform: translate(-50%, -50%) scale(1);
  visibility: visible;
  opacity: 1;
}

.form-check .form-check-input:checked ~ label {
  color: var(--text-primary-light) !important;
}

.form-check .form-check-input::before {
  position: absolute;
  content: "";
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: var(--primary-600);
  border-radius: inherit;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;
}

.form-check .form-check-input:focus {
  box-shadow: none;
}

/* input Radio Css End */
/* input check checked Css Start */
.checked-primary .form-check-input:checked[type="radio"] {
  border-color: var(--primary-600);
}

.checked-primary .form-check-input:checked::before {
  background-color: var(--primary-600);
}

.checked-primary .form-check-input:checked ~ label {
  color: var(--primary-600) !important;
}

.checked-success .form-check-input:checked[type="radio"] {
  border-color: var(--success-600);
}

.checked-success .form-check-input:checked::before {
  background-color: var(--success-600);
}

.checked-success .form-check-input:checked ~ label {
  color: var(--success-600) !important;
}

.checked-warning .form-check-input:checked[type="radio"] {
  border-color: var(--warning-600);
}

.checked-warning .form-check-input:checked::before {
  background-color: var(--warning-600);
}

.checked-warning .form-check-input:checked ~ label {
  color: var(--warning-600) !important;
}

.checked-danger .form-check-input:checked[type="radio"] {
  border-color: var(--danger-600);
}

.checked-danger .form-check-input:checked::before {
  background-color: var(--danger-600);
}

.checked-danger .form-check-input:checked ~ label {
  color: var(--danger-600) !important;
}

.checked-info .form-check-input:checked[type="radio"] {
  border-color: var(--info-600);
}

.checked-info .form-check-input:checked::before {
  background-color: var(--info-600);
}

.checked-info .form-check-input:checked ~ label {
  color: var(--info-600) !important;
}

.checked-secondary .form-check-input:checked[type="radio"] {
  border-color: var(--lilac-600);
}

.checked-secondary .form-check-input:checked::before {
  background-color: var(--lilac-600);
}

.checked-secondary .form-check-input:checked ~ label {
  color: var(--lilac-600) !important;
}

.checked-dark .form-check-input:checked[type="radio"] {
  border-color: var(--neutral-600);
}

.checked-dark .form-check-input:checked::before {
  background-color: var(--neutral-900);
}

.checked-dark .form-check-input:checked ~ label {
  color: var(--neutral-900) !important;
}

/* input check checked Css End */
/* Switch Css Start */
.form-switch {
  padding-inline-start: 0;
}

.form-switch .form-check-input {
  box-shadow: none !important;
  margin: 0;
  position: relative;
  background-color: var(--neutral-400);
  border: 0;
  background-image: none !important;
  width: 36px;
  height: 20px;
}

.form-switch .form-check-input:checked {
  background-color: var(--primary-600);
}

.form-switch .form-check-input:checked::before {
  left: calc(100% - 18px);
  transition: 0.2s linear;
}

.form-switch .form-check-input:checked ~ .form-check-label {
  color: var(--text-primary-light) !important;
}

.form-switch .form-check-input::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  transition: 0.2s linear;
}

/* Switch Css End */
/* Switch different Color Css start */
.switch-primary .form-check-input:checked {
  background-color: var(--primary-600) !important;
}

.switch-primary .form-check-input:checked ~ .form-check-label {
  color: var(--primary-600) !important;
}

.switch-info .form-check-input:checked {
  background-color: var(--info-600) !important;
}

.switch-info .form-check-input:checked ~ .form-check-label {
  color: var(--info-600) !important;
}

.switch-success .form-check-input:checked {
  background-color: var(--success-600) !important;
}

.switch-success .form-check-input:checked ~ .form-check-label {
  color: var(--success-600) !important;
}

.switch-danger .form-check-input:checked {
  background-color: var(--danger-600) !important;
}

.switch-danger .form-check-input:checked ~ .form-check-label {
  color: var(--danger-600) !important;
}

.switch-warning .form-check-input:checked {
  background-color: var(--warning-600) !important;
}

.switch-warning .form-check-input:checked ~ .form-check-label {
  color: var(--warning-600) !important;
}

.switch-dark .form-check-input:checked {
  background-color: var(--neutral-900) !important;
}

.switch-dark .form-check-input:checked ~ .form-check-label {
  color: var(--neutral-900) !important;
}

.switch-purple .form-check-input:checked {
  background-color: var(--lilac-600) !important;
}

.switch-purple .form-check-input:checked ~ .form-check-label {
  color: var(--lilac-600) !important;
}

/* Switch different Color Css End */
/* input Group Checkbox Css Start */
.btn-check:checked + .btn {
  color: #fff !important;
}

.btn-check:checked + .btn-outline-warning-600 {
  background-color: var(--warning-600) !important;
  border-color: var(--warning-600) !important;
}

.btn-check:checked + .btn-outline-primary-600 {
  background-color: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
}

.btn-check:checked + .btn-outline-danger-600 {
  background-color: var(--danger-600) !important;
  border-color: var(--danger-600) !important;
}

.btn-check:checked + .btn-outline-success-600 {
  background-color: var(--success-600) !important;
  border-color: var(--success-600) !important;
}

.btn-check:checked + .btn-outline-warning-600 {
  background-color: var(--warning-600) !important;
  border-color: var(--warning-600) !important;
}

.btn-check:checked + .btn-outline-info-600 {
  background-color: var(--info-600) !important;
  border-color: var(--info-600) !important;
}

.btn-check:checked + .btn-outline-lilac-600 {
  background-color: var(--lilac-600) !important;
  border-color: var(--lilac-600) !important;
}

/* input Group Checkbox Css End */
/* custom form fields css start */
.form-mobile-field {
  position: relative;
}

.form-mobile-field .form-select {
  width: auto;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  border: 0;
  background-color: transparent;
}

.form-mobile-field .form-control {
  padding-inline-start: 4.375rem;
}

select option {
  background-color: var(--white);
}

.was-validated .form-mobile-field .form-control {
  padding-inline-start: 5.9375rem;
}

/* custom form fields css end */
[data-theme="dark"] .form-select {
  background-image: url(../images/arrow-down.png);
  background-size: 11px 7px;
  background-repeat: no-repeat;
}

[data-theme="dark"] .btn-close {
  background-image: url(../images/times.png);
  background-size: auto;
  background-repeat: no-repeat;
  opacity: 1;
}

/* === form css end === */
/* === card css start === */
.card {
  background-color: var(--white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0 0.25rem 1.875rem rgba(46, 45, 116, 0.05);
  border: none;
  position: initial;
  color: var(--text-secondary-light);
}

.card .card-header {
  background-color: var(--white);
  padding: 0.875rem 1.5rem;
  border-color: var(--border-color);
  color: var(--text-secondary-light);
}

.card .card-title {
  font-size: 1.125rem !important;
  font-weight: 600;
  color: var(--text-primary-light);
}

.card .card-body {
  padding: 0.875rem 1.5rem;
  color: var(--text-secondary-light);
}

/* === card css end === */
/* === modal css start === */
.modal-header {
  border-color: var(--input-form-light);
}

.modal-header .btn-close {
  margin-inline-start: auto;
  margin-inline-end: 0;
}

.modal-content {
  background-color: var(--white);
}

.modal-footer {
  border-color: var(--input-form-light);
}

/* === modal css end === */
/* === accordion css start === */
/* =========================== Accordion Css start ============================= */
.accordion .accordion-item {
  border: 1px solid var(--border-color) !important;
  background-color: var(--white) !important;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}

.accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.accordion .accordion-body {
  background-color: transparent;
  padding: 0;
  padding-top: 8px;
  color: var(--text-secondary-light);
}

.accordion .accordion-button {
  padding: 0;
  background: transparent;
  padding-inline-end: 68px;
}

.accordion .accordion-button::after {
  background-image: none;
}

.accordion .accordion-button:focus {
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: none;
  color: var(--primary-600);
}

.accordion .accordion-button[aria-expanded="true"]::after,
.accordion .accordion-button[aria-expanded="false"]::after {
  content: "\f1af";
  font-family: remixicon;
  display: inline-block;
  position: absolute;
  inset-inline-end: 32px;
  height: 24px;
  width: 24px;
  border: 2px solid var(--primary-600);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 50%;
  line-height: 1;
  color: var(--primary-600);
}

.accordion .accordion-button[aria-expanded="false"]::after {
  content: "\ea13";
}

/* ================================= Accordion Css End =========================== */
/* === accordion css end === */
/* === nav-tabs css start === */
/* Bordered Tab Css Start */
.bordered-tab .nav-link {
  background-color: transparent !important;
  padding: 16px 24px;
  border-radius: 0;
  font-weight: 600;
  color: var(--text-secondary-light);
  position: relative;
}

.bordered-tab .nav-link::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
  background-color: var(--primary-600);
  transition: 0.2s linear;
}

.bordered-tab .nav-link.active {
  color: var(--primary-600);
}

.bordered-tab .nav-link.active::before {
  width: 100%;
}

/* Bordered Tab Css End */
/* Pill Tab Css Start */
.pill-tab .nav-link.active {
  background-color: var(--primary-600);
}

.pill-tab.style-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/* Pill Tab Css End */
/* Gradient Tab Css Start */
.border-gradient-tab {
  border-top: 1px solid var(--input-form-light);
}

.border-gradient-tab .nav-link {
  font-weight: 600;
  color: var(--text-secondary-light) !important;
  padding: 10px 16px;
  background-color: transparent !important;
  position: relative;
}

.border-gradient-tab .nav-link.active {
  color: var(--text-primary-light) !important;
}

.border-gradient-tab .nav-link.active::before {
  visibility: visible;
  opacity: 1;
}

.border-gradient-tab .nav-link.active .notification-alert {
  background-color: var(--primary-600) !important;
  color: #fff !important;
}

.border-gradient-tab .nav-link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-top: 1px solid var(--primary-600);
  background: linear-gradient(
    180deg,
    rgba(72, 127, 255, 0.12) 0%,
    rgba(72, 127, 255, 0.03) 100%
  );
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;
}

/* Gradient Tab Css End */
/* Focus Tab Css Start */
.focus-tab .nav-link.active {
  background-color: var(--primary-50);
  color: var(--primary-600);
}

/* Focus Tab Css End */
/* Button Tab Css Start */
.button-tab .nav-link.active {
  background-color: var(--primary-600);
  border-color: var(--primary-600) !important;
  color: #fff !important;
}

/* Button Tab Css End */
/* Active Text Css Start */
.active-text-tab.nav-pills .nav-link.active {
  background-color: transparent !important;
  color: var(--primary-600) !important;
}

/* Active Text Css End */
/* === nav-tabs css end === */
/* === pagination css start === */
/* === pagination css end === */
/* === avatar css start === */
.avatar-xl {
  width: 2.75rem;
  height: 2.75rem;
}

.avatar-lg {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-md {
  width: 2rem;
  height: 2rem;
}

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}

/* === avatar css end === */
/* === list-style css start === */
/* === list-style css end === */
/* === apex-chart css start === */
.apexcharts-tooltip-style-1 .apexcharts-tooltip {
  padding: 0.75rem 0.5rem;
  background-color: var();
  border: 1px solid var(--input-bg);
  background-color: var(--white);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.apexcharts-tooltip-style-1 .apexcharts-tooltip-title {
  padding: 0 !important;
  background-color: transparent !important;
  font-size: 0.875rem !important;
  color: var(--text-primary-light) !important;
  font-weight: 600 !important;
  border: none !important;
}

.apexcharts-tooltip-style-1 .apexcharts-tooltip-series-group {
  padding: 0;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.apexcharts-tooltip-style-1
  .apexcharts-tooltip-series-group
  .apexcharts-tooltip-y-group {
  padding: 0 !important;
}

.apexcharts-tooltip-style-1
  .apexcharts-tooltip-series-group
  .apexcharts-tooltip-z-group {
  display: none !important;
}

/* Home Two Widget Chart Css Start*/
.remove-tooltip-title .apexcharts-tooltip-title {
  display: none;
}

.rounded-tooltip-value .apexcharts-tooltip.apexcharts-theme-light {
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rounded-tooltip-value .apexcharts-tooltip-text-y-label {
  display: none;
}

.rounded-tooltip-value .apexcharts-tooltip-marker {
  display: none;
}

.rounded-tooltip-value .apexcharts-tooltip-text-y-value {
  margin-left: 0;
}

.rounded-tooltip-value .apexcharts-tooltip-text {
  display: flex;
}

/* Home Two Widget Chart Css End*/
/* Chart's text color Css End*/
.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  fill: #8a929f;
}

/* Chart's text color Css End*/
/* Home Two Donut Chart Css Start*/
.apexcharts-tooltip-z-none .apexcharts-tooltip-z-group {
  display: none;
}

.title-style .apexcharts-text.apexcharts-datalabel-value {
  display: none;
}

.title-style .apexcharts-text.apexcharts-datalabel-label {
  fill: #4b5563 !important;
  font-size: 14px;
  font-weight: 700 !important;
  font-family: Inter, sans-serif;
}

.circle-none circle {
  display: none;
}

#donutChart {
  height: 0px;
  min-height: 163px !important;
  margin-top: -32px;
}

.margin-16-minus {
  margin: -16px;
}

/* Home Two Donut Chart Css End*/
/* Home Five Radial Chart Css End*/
#semiCircleGauge {
  transform: translateY(22px) translateX(14px);
}

/* Home Five Radial Chart Css End*/
.apexcharts-bar-area:hover {
  fill: var(--brand);
}

.apexcharts-datalabels text {
  filter: none !important; /* Remove any filter applied to data labels */
  text-shadow: none !important; /* Remove any text shadow */
}

.apexcharts-legend-text {
  color: var(--text-secondary-light) !important;
}

.square-marker .apexcharts-legend-marker {
  border-radius: 4px !important;
  width: 16px !important;
  height: 16px !important;
}

.series-gap-24 .apexcharts-legend-series {
  margin: 4px 12px !important;
}

.check-marker .apexcharts-legend-marker {
  position: relative;
}

.check-marker .apexcharts-legend-marker::after {
  position: absolute;
  content: "\eb7a";
  font-family: remixicon;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #fff;
  /* transition: 0.2s; */
}

.apexcharts-yaxis {
  transform: translateX(10px);
}

[data-theme="dark"] .apexcharts-tooltip.apexcharts-theme-light {
  color: #fff !important;
  background: rgba(30, 30, 30, 0.8) !important;
}

[data-theme="dark"]
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: rgba(0, 0, 0, 0.7) !important;
  border-bottom: 1px solid #333 !important;
}

[data-theme="dark"] .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.7) !important;
}

.yaxies-more .apexcharts-yaxis {
  transform: translateX(20px);
}

.apexcharts-canvas {
  transition: 0s !important;
}

/* === apex-chart css end === */
/* === progress-bar css start === */
/* Progress bar css start */
.max-w-66 {
  max-width: 66px;
}

.max-w-112 {
  max-width: 112px;
}

.progress {
  background-color: var(--neutral-200);
}

.progress-sm {
  height: 8px;
}

/* animated bar */
.animated-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 2s;
}

@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Progress bar css End */
/* Floating Label Css Start */
.floating-label {
  transform: translateX(-25px);
  left: 0;
  top: 0;
  animation-name: animateFloatingLabel;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 2s;
}

@keyframes animateFloatingLabel {
  from {
    left: 0;
  }
  to {
    left: var(--left-percentage);
  }
}
/* Floating Label Css End */
/* Semi Circle Floating */
.barOverflow {
  /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden; /* Comment this line to understand the trick */
  width: inherit;
  height: inherit; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */
}

.circleBar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% + 44px);
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid var(--primary-50); /* half gray, */
  border-bottom-color: var(--primary-600); /* half azure */
  border-right-color: var(--primary-600);
}

/* Semi Circle Floating */
/* === progress-bar css end === */
/* === vector-map css start === */
/* Vector Map Css Start */
#world-map {
  height: 200px;
  background-color: var(--neutral-100);
}

.map-sm {
  height: 160px !important;
}

.jvectormap-goback,
.jvectormap-zoomin,
.jvectormap-zoomout {
  background: #d1d5db;
  color: #111827;
}

/* Vector Map Css End */
/* === vector-map css end === */
/* === scroll css start === */
.scroll-sm::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 40px;
}

.scroll-sm::-webkit-scrollbar-thumb {
  background-color: #b1b3b8;
  border-radius: 40px;
}

.scroll-sm::-webkit-scrollbar-thumb:hover {
  background-color: #939393;
}

.scroll-sm::-webkit-scrollbar-track {
  background-color: #d6d9de;
  border-radius: 40px;
}

/* === scroll css end === */
/* === slider css start === */
/* Slick Slider Css Start  */
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.slick-dots .slick-active button {
  background-color: var(--primary-600);
  width: 20px;
}

.slick-dots button {
  width: 10px;
  height: 4px;
  background-color: var(--primary-50);
  text-indent: -999999px;
  border-radius: 50px;
  color: var(--primary-50);
}

/* Dots Circle Style */
.dots-style-circle .slick-dots {
  gap: 8px;
}

.dots-style-circle .slick-dots .slick-active button {
  background-color: var(--primary-600);
  width: 12px;
  height: 12px;
}

.dots-style-circle .slick-dots button {
  width: 8px;
  height: 8px;
  background-color: #5a75ae;
  text-indent: -999999px;
  border-radius: 50%;
}

/* Dots Bottom Position Style */
.dots-positioned .slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
}

/* Slick Arrows Css */
.slick-arrow {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 32px;
  line-height: 0;
  z-index: 1;
}

.slick-arrow.slick-next {
  left: auto;
  right: 16px;
}

/* Slick Slider Css End */
/* Slider Progress Css Start */
.slider-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
}

.slider-progress span {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 0px;
  height: 100%;
  background-color: var(--primary-600);
  transition: all 0s linear;
}

.slider-progress span.active {
  width: 100%;
}

/* Slider Progress Css End */
/* === slider css end === */
/* === Dropdown css start === */
/* Dropdown Css Start */
.has-indicator {
  position: relative;
  transition: 0s;
}

.has-indicator.show::before {
  visibility: visible;
  opacity: 1;
}

.has-indicator::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 32px;
  background-color: var(--white);
  border-radius: 8px;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  visibility: hidden;
  opacity: 0;
  transition: 0s;
  margin-left: -2px;
  box-shadow: -13px -8px 24px -7px rgba(46, 45, 116, 0.09);
}

.dropdown > button.show {
  background-color: var(--primary-50);
  color: var(--primary-600) !important;
}

.dropdown > button.show .text-primary-light {
  color: var(--primary-600) !important;
}

.dropdown-menu {
  border: 0;
  border-radius: 16px;
  box-shadow: 0px 13px 30px 10px rgba(46, 45, 116, 0.05);
  padding: 16px;
  background-color: var(--white);
}

.dropdown-menu.to-top {
  top: calc(100% - 28px) !important;
}

@media (min-width: 575px) {
  .dropdown-menu-lg {
    width: 425px;
  }
}
@media (min-width: 375px) {
  .dropdown-menu-sm {
    width: 312px;
  }
}
/* Dropdown Toggle Custom Icon */
.toggle-icon.icon-up::after {
  content: "\ea78" !important;
}

.toggle-icon.icon-right::after {
  content: "\ea6e" !important;
}

.toggle-icon.icon-left::before {
  content: "\ea64" !important;
  vertical-align: middle !important;
  border: 0 !important;
  font-family: remixicon;
  font-size: 24px;
  line-height: 0;
  color: inherit;
}

.toggle-icon::after {
  vertical-align: middle !important;
  border: 0 !important;
  content: "\ea4e" !important;
  font-family: remixicon;
  font-size: 24px;
  line-height: 0;
  color: inherit;
}

.dropdown > .not-active.show {
  color: #fff !important;
}

/* Dropdown Css End */
/* === Dropdown css end === */
/* === tooltip css start === */
/* Tooltip css start */
.tooltip {
  --bs-tooltip-color: #fff;
}

.tooltip-primary {
  --bs-tooltip-bg: var(--primary-600);
}

.tooltip-purple {
  --bs-tooltip-bg: var(--lilac-600);
}

.tooltip-success {
  --bs-tooltip-bg: var(--success-600);
}

.tooltip-info {
  --bs-tooltip-bg: var(--info-600);
}

.tooltip-warning {
  --bs-tooltip-bg: var(--warning-600);
}

.tooltip-danger {
  --bs-tooltip-bg: var(--danger-600);
}

.tooltip-dark {
  --bs-tooltip-bg: var(--neutral-900);
}

.tooltip-white {
  --bs-tooltip-bg: var(--base);
  --bs-tooltip-color: #fff;
}

.tip-content {
  display: none;
}

.list-decimal {
  list-style: decimal;
  padding-left: 18px;
}

/* Tooltip css End */
/* === tooltip css end === */
/* === image upload css start === */
/* ========================= Image upload csss start ================================= */
.uploaded-image-name-list:hover .remove-image {
  visibility: visible;
  opacity: 1;
}

.uploaded-image-name-list .remove-image {
  visibility: hidden;
  opacity: 0;
}

/* Upload Image Css Start */
.avatar-upload {
  position: relative;
  max-width: max-content;
}

.avatar-upload.style-two {
  width: 180px;
  height: 150px;
  max-width: unset;
}

#imagePreview {
  background-image: url("../images/user-grid/user-grid-img13.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#previewImage1,
#previewImage2 {
  background-image: url("../images/payment/upload-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar-upload .avatar-preview {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 100%;
  border: 1px solid var(--primary-600);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview.style-two {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  border: 1px solid var(--border-color) !important;
  box-shadow: none;
}

.avatar-upload .avatar-preview.style-two > div {
  border-radius: inherit !important;
}

.avatar-upload .avatar-preview > div {
  background-image: url("");
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Upload Image Css End */
/* ========================= Image upload csss End ================================= */
/* === image upload css end === */
/* === calendar css start === */
/* Full Calendar Customize Css Start */
.fc-border-separate tr.fc-last th {
  background: var(--neutral-50);
  padding: 12px;
  color: var(--text-primary-light);
}

.fc-border-separate tr.fc-last th a {
  transition: 0s;
}

.fc-day.fc-tue.fc-widget-content.fc-today.fc-state-highlight {
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(72, 127, 255, 0.13) 0%,
    rgba(72, 127, 255, 0.12) 0.01%,
    rgba(72, 127, 255, 0.05) 100%
  );
}

.fc-day.fc-tue.fc-widget-content.fc-today.fc-state-highlight::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: var(--primary-600);
}

.fc-event {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primary-50) !important;
  color: var(--primary-600) !important;
}

.fc-event.important {
  background-color: var(--danger-100) !important;
  color: var(--danger-600) !important;
}

.fc-event.info {
  background-color: var(--lilac-100) !important;
  color: var(--lilac-600) !important;
}

.fc-border-separate tr.fc-last th {
  border-color: var(--neutral-200) !important;
}

table.fc-header,
#calendar {
  background-color: transparent;
}

.fc-week .fc-day:hover .fc-day-number {
  background-color: var(--primary-100) !important;
  color: var(--primary-600) !important;
}

.fc-state-highlight > div > div.fc-day-number {
  background-color: var(--primary-600) !important;
  color: #fff !important;
}

#calendar,
table.fc-header {
  background-color: transparent !important;
}

.fc-header-title h2 {
  font-size: 24px !important;
  font-weight: 600;
}

.fc-state-default {
  border-color: var(--primary-600) !important;
  color: var(--primary-600) !important;
}

.fc-state-default:hover {
  background-color: var(--primary-50) !important;
}

.fc-state-active,
.fc-state-down {
  background-color: var(--primary-600) !important;
  color: #fff !important;
}

.fc-state-active:hover,
.fc-state-down:hover {
  color: #fff !important;
  background-color: var(--primary-600) !important;
}

.fc-button.fc-button-today {
  border-color: var(--primary-50) !important;
  background-color: var(--primary-50) !important;
  color: var(--primary-600) !important;
}

.fc-button.fc-button-today.fc-state-disabled {
  border-color: var(--primary-600) !important;
  background-color: var(--primary-600) !important;
  color: #fff !important;
  cursor: no-drop;
}

td.fc-header-center {
  text-align: end;
}

.fc-widget-content {
  border-color: var(--border-color) !important;
}

.fc-last td {
  border-width: 1px 0 1px 1px;
}

.fc-agenda-axis.fc-widget-header {
  color: var(--text-primary-light);
  border-color: var(--border-color);
}

.fc-header td {
  padding: 0;
}

#calendar {
  overflow-x: auto;
}

#calendar .fc-content {
  min-width: max-content;
}

#calendar table.fc-header tr {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

/* Full Calendar Customize Css End */
/* === calendar css end === */
/* === auth css start === */
/* ========================== Authentication css start ============================== */
.auth {
  min-height: 100vh;
}

.auth-left {
  background: linear-gradient(90deg, #ecf0ff 0%, #fafbff 100%);
  width: 50%;
}

.auth-right {
  width: 50%;
}

@media (max-width: 991px) {
  .auth-right {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .auth-right > div {
    max-width: 100%;
    width: 100%;
  }
}
.auth.forgot-password-page .auth-left {
  background: var(
    --gradients-Colors-gradients-2,
    linear-gradient(90deg, #f7e9ff 0.12%, #fdf8f7 99.89%)
  );
}

.center-border-horizontal {
  position: relative;
  z-index: 1;
}

.center-border-horizontal::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--neutral-300);
  z-index: -1;
}

/* ========================== Authentication css End ============================== */
/* === auth css end === */
/* === pricing css start === */
/* Pricing Css Start */
@media (min-width: 1400px) {
  .pricing-plan-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.pricing-plan {
  padding: 50px 40px;
}

@media (max-width: 1699px) {
  .pricing-plan {
    padding: 24px;
  }
}
.pricing-tab {
  margin-bottom: 40px;
}

@media (min-width: 1400px) {
  .pricing-tab {
    margin-bottom: 110px;
  }
}
@media (min-width: 1400px) {
  .scale-item {
    transform: scale(1.04);
    margin-top: -50px;
    z-index: 1;
  }
}
@media (min-width: 1400px) {
  .featured-item {
    margin-top: -50px;
  }
}
/* Pricing Css End */
/* === pricing css end === */
/* === theme css start === */
/* Theme Css Start */
.payment-gateway-input:checked ~ .payment-gateway-label {
  border-color: var(--primary-600) !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
}

.payment-gateway-input:checked ~ .payment-gateway-label .text-secondary-light {
  color: var(--text-primary-light) !important;
}

/* Theme Css End */
/* === theme css end === */
/* === date picker css start === */
/* ================== Date Picker Css Start ====================== */
.flatpickr-day.selected {
  border-color: var(--primary-600);
  background-color: var(--primary-600);
  color: #fff !important;
}

.flatpickr-calendar {
  background-color: var(--white) !important;
  border: 1px solid var(--border-color) !important;
}

.flatpickr-calendar p,
.flatpickr-calendar span,
.flatpickr-calendar div,
.flatpickr-calendar input {
  color: var(--text-primary-light) !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: var(--text-primary-light) !important;
}

.flatpickr-day:hover {
  color: black !important;
}

/* ================== Date Picker Css End ====================== */
/* === date picker css end === */
/* === email css start === */
/* Email Css Start */
.item-active > a {
  background-color: var(--primary-50) !important;
  color: var(--text-primary-light);
}

.item-active > a .icon {
  color: var(--primary-600) !important;
}

.email-item:hover {
  box-shadow: var(--box-shadow);
}

.email-item.active {
  background-color: var(--primary-50) !important;
}

.reload-button {
  transition: 0.2s linear;
}

.reload-button:active {
  transform: rotate(180deg);
}

.starred-button.active .icon-outline {
  visibility: hidden;
  opacity: 0;
  width: 0;
}

.starred-button.active .icon-fill {
  visibility: visible;
  opacity: 1;
  width: auto;
}

.starred-button .icon-fill {
  visibility: hidden;
  opacity: 0;
  width: 0;
}

/* Email Css End */
/* === email css end === */
/* === faq css start === */
/* Faq Css Start */
.responsive-padding-40-150 {
  padding-inline-start: clamp(
    2rem,
    -27.4754rem + 39.3333vw,
    9.375rem
  ) !important;
  padding-inline-end: clamp(2rem, -27.4754rem + 39.3333vw, 9.375rem) !important;
  padding-block-start: 40px !important;
  padding-block-end: 40px !important;
}

/* Faq Css End */
/* === faq css end === */
/* === editor css start === */
/* Code edit css start */
.ql-toolbar.ql-snow {
  border: 0 !important;
  border-bottom: 1px solid var(--border-color) !important;
  border-radius: inherit !important;
}

.ql-container.ql-snow {
  border: 0 !important;
  border-radius: inherit !important;
}

.ql-editor {
  border-radius: 12px;
  min-height: 600px;
}

.ql-picker-label,
.ql-formats {
  color: var(--text-primary-light);
  z-index: 2;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--text-secondary-light);
}

.ql-snow .ql-stroke {
  stroke: var(--text-secondary-light);
}

/* Code edit css end */
/* === editor css end === */
/* === editor css start === */
.chat-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
}
.wrap-content {
  width: 40% !important;
}

.chat-wrapper .chat-sidebar {
  width: 100%;
}

@media (min-width: 768px) {
  .chat-wrapper .chat-sidebar {
    width: 300px;
  }
}
@media (min-width: 1200px) {
  .chat-wrapper .chat-sidebar {
    width: 390px;
  }
}
.chat-wrapper .chat-sidebar.profile-setting {
  padding: 1.5rem;
}

.chat-wrapper .chat-main {
  width: 100%;
  margin-top: 1.875rem;
}

@media (min-width: 768px) {
  .chat-wrapper .chat-main {
    width: calc(100% - 324px);
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .chat-wrapper .chat-main {
    width: calc(100% - 414px);
  }
}
.chat-main-profile {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 1.875rem;
  gap: 0.9375rem;
}

.chat-main-profile .img {
  position: relative;
  width: 80px;
  height: 80px;
}

.chat-main-profile .img::after {
  position: absolute;
  content: "";
  bottom: 4px;
  inset-inline-end: 9px;
  width: 12px;
  height: 12px;
  background-color: var(--success-main);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.chat-main-profile .img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.chat-search {
  padding: 0 1.5rem;
  border-top: 1px solid var(--neutral-200);
  border-bottom: 1px solid var(--neutral-200);
  display: flex;
  align-items: center;
}

.chat-search .icon {
  font-size: 1.375rem;
  line-height: 1;
  padding-top: 5px;
}

.chat-search input {
  flex-grow: 1;
  border: none;
  height: 48px;
  background-color: transparent;
  font-size: 1.125rem;
  padding-inline-start: 0.625rem;
}

.chat-all-list {
  max-height: 534px;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .chat-all-list {
    max-height: 400px;
  }
}
@media (min-width: 1400px) {
  .chat-all-list {
    max-height: 534px;
  }
}
.chat-all-list::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white);
}

.chat-all-list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border: 3px solid var(--white);
}

.chat-sidebar-single {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.6875rem 1.5rem;
  cursor: pointer;
}

.chat-sidebar-single.top-profile {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
}

.chat-sidebar-single.active .img::after {
  background-color: var(--success-main);
}

.chat-sidebar-single .img {
  position: relative;
}

.chat-sidebar-single .img::after {
  position: absolute;
  content: "";
  bottom: 1px;
  right: 2px;
  width: 8px;
  height: 8px;
  background-color: var(--neutral-400);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.chat-sidebar-single .img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  -o-object-fit: cover;
}

.chat-sidebar-single .info {
  flex-grow: 1;
  padding: 0 0.75rem;
}

.chat-sidebar-single .action {
  margin-inline-start: auto;
}

.chat-main {
  display: flex;
  flex-flow: column;
}

.chat-main .chat-sidebar-single {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid var(--input-form-light);
}

.chat-main .chat-empty {
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.chat-message-list {
  flex-grow: 1;
  min-height: 350px;
  max-height: 511px;
  padding: 1.5rem;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .chat-message-list {
    max-height: 375px;
  }
}
@media (min-width: 1400px) {
  .chat-message-list {
    max-height: 511px;
  }
}
.chat-message-list::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white);
}

.chat-message-list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border: 3px solid var(--white);
}

.chat-single-message + .chat-single-message {
  margin-top: 2rem;
}

.chat-single-message {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .chat-single-message {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .chat-single-message {
    width: 60%;
  }
}
.chat-single-message.left .chat-message-content {
  background-color: var(--input-bg);
  border-radius: 16px 16px 16px 0;
  -webkit-border-radius: 16px 16px 16px 0;
  -moz-border-radius: 16px 16px 16px 0;
  -ms-border-radius: 16px 16px 16px 0;
  -o-border-radius: 16px 16px 16px 0;
}

.chat-single-message.left .chat-message-content p {
  color: var(--text-primary-light);
}

.chat-single-message.left .chat-message-content p.chat-time {
  color: var(--text-secondary-light);
  text-align: end;
}

.chat-single-message.right {
  margin-inline-start: auto;
}

.chat-single-message.right .chat-message-content {
  width: 100%;
  background-color: var(--brand);
  border-radius: 16px 16px 0 16px;
  -webkit-border-radius: 16px 16px 0 16px;
  -moz-border-radius: 16px 16px 0 16px;
  -ms-border-radius: 16px 16px 0 16px;
  -o-border-radius: 16px 16px 0 16px;
}

.chat-single-message.right .chat-message-content p {
  color: #fff;
}

.chat-single-message.right .chat-message-content p.chat-time {
  color: #fff;
}

.chat-single-message .chat-message-content {
  width: calc(100% - 56px);
  padding: 1.25rem;
}

.chat-single-message .chat-message-content .chat-time {
  font-size: 0.75rem;
}

.chat-message-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.9375rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--neutral-300);
}

.chat-message-box input {
  flex-grow: 1;
}

.chat-message-box-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;
}

.list-style {
  list-style: outside;
  list-style-position: inside;
}

.ai-chat-list {
  max-height: 644px;
  overflow-y: auto;
}

/* === editor css end === */
/* === wizard css start === */
/* ========================= Wizard Css Start ========================= */
.form-wizard .wizard-fieldset {
  display: none;
}

.form-wizard .wizard-fieldset.show {
  display: block !important;
}

.form-wizard .wizard-form-error {
  display: none;
  background-color: transparent;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--danger-main);
  pointer-events: none;
}

.form-wizard-header {
  text-align: center;
}

.form-wizard-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 455px;
}

.form-wizard-list__item {
  width: 20%;
}

.form-wizard-list__item.active .count {
  color: var(--primary-600);
  border-color: var(--primary-600);
  font-weight: 700;
}

.form-wizard-list__item.active .text {
  color: var(--primary-600);
}

.form-wizard-list__item.activated .count {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
  color: #fff;
}

.form-wizard-list__item.activated .text {
  color: var(--primary-600);
}

.form-wizard-list__item.activated .form-wizard-list__line::before {
  width: 100%;
}

.form-wizard-list__item:last-child .form-wizard-list__line::after,
.form-wizard-list__item:last-child .form-wizard-list__line::before {
  display: none;
}

.form-wizard-list__line {
  position: relative;
}

.form-wizard-list__line::after,
.form-wizard-list__line::before {
  background-color: var(--neutral-300);
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  transition: 0.3s linear;
}

.form-wizard-list__line::before {
  width: 0;
  background-color: var(--primary-600);
  z-index: 1;
}

.form-wizard-list .count {
  background-color: var(--white);
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  position: relative;
  text-align: center;
  z-index: 1;
  border: 2px solid var(--neutral-300);
  font-size: 18px;
}

/* Style Two */
.form-wizard-list.style-two {
  gap: 8px;
  min-width: max-content;
}

.form-wizard-list.style-two .form-wizard-list .count {
  height: 32px;
  line-height: 32px;
  width: 32px;
  font-size: 16px;
}

.form-wizard-list.style-two .form-wizard-list__line::after,
.form-wizard-list.style-two .form-wizard-list__line::before {
  display: none;
}

.form-wizard-list.style-two .form-wizard-list__item {
  width: 25%;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: start;
  padding: 8px;
  position: relative;
  clip-path: polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%, 0% 50%);
  background-color: var(--neutral-200);
  border-radius: 50px;
}

.form-wizard-list.style-two .form-wizard-list__item:first-child {
  padding-inline-start: 8px;
}

.form-wizard-list.style-two .form-wizard-list__item:last-child {
  padding-inline-end: 8px;
}

.form-wizard-list.style-two .form-wizard-list__item.active .count {
  background-color: var(--primary-600);
  color: #fff;
}

.form-wizard-list.style-two .form-wizard-list__item.activated {
  background-color: var(--primary-600);
}

.form-wizard-list.style-two .form-wizard-list__item.activated .count {
  background-color: #fff;
  color: var(--primary-600);
}

.form-wizard-list.style-two .form-wizard-list__item.activated .text {
  color: #fff;
}

.form-wizard-list.style-two .form-wizard-list__item .text {
  font-size: 13px !important;
  font-weight: 700 !important;
}

/* Style Three */
.form-wizard-list.style-three {
  min-width: max-content;
}

.form-wizard-list.style-three .form-wizard-list__line::after,
.form-wizard-list.style-three .form-wizard-list__line::before {
  display: none;
}

.form-wizard input::placeholder {
  font-size: 14px !important;
  color: var(--neutral-400);
}

.gif-image {
  max-width: 60px;
}

/* ========================= Wizard Css End ========================= */
/* === wizard css end === */
/* === file upload css start === */
/* ============================ Image Upload Css Start ==================== */
.image-upload {
  border-radius: 8px !important;
  overflow: hidden;
  position: relative;
  border-radius: inherit;
  border: 1px dashed var(--input-form-light) !important;
  cursor: pointer;
  transition: 0.2s;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 180px;
}

.image-upload:hover {
  background-color: var(--neutral-100);
}

.image-upload__box {
  border-radius: inherit !important;
  cursor: pointer;
  padding: 16px !important;
  flex-direction: column;
  justify-content: center;
}

.image-upload__boxInner {
  padding: 0 !important;
  border: 0 !important;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  width: 100% !important;
  margin: 0 auto;
  position: relative;
  border-radius: inherit;
}

.image-upload__icon {
  font-size: 32px !important;
  color: var(--primary-600);
  line-height: 1;
}

.image-upload__image {
  border-radius: inherit;
  max-width: 100%;
}

.image-upload__imageName {
  margin-top: 4px;
}

.image-upload__deleteBtn {
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 1;
  background: var(--neutral-100);
  color: hsl(var(--neutral-900));
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload__deleteBtn:hover {
  background: var(--danger-600);
  transform: scale(1.1);
  color: #fff;
}

.image-upload__anotherFileIcon {
  font-size: 16px !important;
  color: hsl(var(--body-color)) !important;
  width: 40px;
  height: 40px;
  border: 1px solid var(--neutral-100);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
}

.image-upload__boxInner p,
.image-upload__boxInner span {
  line-height: 1.2 !important;
}

/* ============================ Image Upload Css End ==================== */
/* === file upload css end === */
/* === file upload css start === */
.ppq-audio-player .play-pause-btn .play-pause-icon:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ppq-audio-player .play-pause-btn .play-pause-icon:after,
.ppq-audio-player.player-playing .play-pause-icon:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAASCAMAAADrP+ckAAABNVBMVEUAAAAAmIcAmYgAm4sAnIsAnIwAnI0AnY0AnY4Ano4Ano8An5AAoJEAoZEAoZIAopMBn5ABoJABoJEBoZEBoZIBoZMBopMCoZICopMCopQCo5QDoJEDoZEDo5QDo5UFopMGo5QKpJYLpJYMpZcMppgNpZcNppgNp5gOppcOppgOp5gOp5kQqJoSp5kSqJoTqZsbrJ4drJ8irZ8mrqEnsKMrsqUtsqYytKg0tak5t6tHvLJJvbNLvbNqycBrysFsycFwy8Jwy8N3zsZ+0MiA0cqD0suH1M2L1c6P1tCk3tmm39mv4t285+PE6ebH6ufN7erS7+zV7+3W8O3X8O7a8u/k9fPs+Pft+Pfx+fjx+fnx+vny+vr1+/v2+/v5/fz7/f38/f38/v38/v79/v7+/v7+//////+ALAV1AAAAAXRSTlMAQObYZgAAATxJREFUKM+F0tlWwjAQBuAgQgHTRFFrqaK4IALiiuKugPsKrqUo0DbO+z+CDYHIFZ279nznP3P+DHLh4+L8HWybAQM+qDdlUgGXMRcqpIwYnIwoG7ct6LjAfgdUKlZoAmPQLMRSCBrLZH4idPAEbqcb11eamm1w1ciqGgJrFVPNiMydfkLH8X5LhXMWV1YOc7WCKSGGHty6A9Z2ZRbFGaEymApFKaHJceXoFRxnmPKcZoQXL79huPLijKng/ouP4nFJZcdXUWJML/gpLyo6du2zPTUmQ3uPw7cnWiK8dPUDzn+rUvVaFXUdv4Fjy+51nBcqj3XxQl71o9v33eqlipO0yZWZJnEE5pqqJZTZsy/xjHKvYqBk82+7FCgiaO1GZqKHz/2TkKpWrYv7qldryIGH9c2bFrR75yXV4PwBrZh1OjRqx9oAAAAASUVORK5CYII=);
  background-size: 37px 18px;
}

.ppq-audio-player {
  line-height: 111px;
  position: relative;
  overflow: hidden;
  height: 111px;
  margin: 0 auto;
  background: #f4f4f4;
}

.ppq-audio-player audio {
  position: absolute;
  vertical-align: baseline;
}

.ppq-audio-player .play-pause-btn {
  float: left;
  margin: 29px 0 0 12px;
}

.ppq-audio-player .play-pause-btn .play-pause-icon {
  position: relative;
  display: block;
  width: 47px;
  height: 47px;
  border: 3px solid #00a293;
  border-radius: 100%;
  background-color: #fff;
}

.ppq-audio-player .play-pause-btn .play-pause-icon:after {
  display: block;
  content: "";
  background-position: 0 0;
  width: 17px;
  height: 18px;
}

.ppq-audio-player.player-playing .play-pause-icon:after {
  background-position: -25px 0;
  width: 12px;
  height: 17px;
}

.ppq-audio-player .player-time {
  float: left;
  width: 51px;
  margin-right: 8px;
  text-align: right;
}

.ppq-audio-player .player-time-duration {
  float: right;
  margin: 0 0 0 8px;
  text-align: left;
}

.ppq-audio-player .player-bar {
  position: relative;
  overflow: hidden;
  height: 5px;
  margin-top: 53px;
  background-color: #fff;
}

.ppq-audio-player .player-bar .player-bar-loaded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #ddd;
}

.ppq-audio-player .player-bar .player-bar-played {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 3px;
  background: #00a293;
}

/* === file upload css end === */
/* === radial progress bar start === */
/* ====================== Animated Radial Progress bar Css Start ======================== */
.radial-progress {
  height: auto;
  max-width: 44px;
  transform: rotate(-90deg);
  width: 100%;
}

.radial-progress circle {
  fill: rgba(0, 0, 0, 0);
  stroke: #fff;
  stroke-dashoffset: 219.9114857513;
  stroke-width: 4;
  stroke: var(--primary-600);
  stroke-linecap: round;
}

.radial-progress circle.incomplete {
  opacity: 0.2;
}

.radial-progress circle.complete {
  stroke-dasharray: 219.9114857513;
}

.radial-progress text {
  fill: var(--text-secondary-light);
  font-size: 24px !important;
  text-anchor: middle;
}

/* ====================== Animated Radial Progress bar Css End ======================== */
/* === radial progress bar end === */
/* === NFT start === */
/* Nft Promo Card Css Start */
.nft-page .card {
  border-radius: 16px !important;
  overflow: hidden;
}

.nft-promo-card {
  padding: 28px clamp(1.5rem, -7.5935rem + 10.4vw, 4.75rem);
  border-radius: 12px !important;
}

.nft-promo-card__inner {
  gap: clamp(1.5rem, -9.2425rem + 12vw, 5rem);
}

@media (max-width: 576px) {
  .nft-promo-card__inner {
    flex-direction: column;
  }
}
@media (max-width: 430px) {
  .chat-wrapper {
    width: 100% !important;
  }
  .wrap-content {
    width: 100% !important;
  }
}
.nft-promo-card__thumb {
  min-width: 200px;
}

/* Nft Promo Card Css End */
/* === NFT end === */
/* === navbar css start === */
.navbar-header {
  height: 4.5rem;
  background-color: var(--white);
  position: sticky;
  top: 0;
  padding: 1rem 1.5rem;
  z-index: 2;
}

.sidebar-toggle,
.sidebar-mobile-toggle {
  line-height: 1.2;
  color: var(--text-primary-light);
}

.sidebar-toggle .icon,
.sidebar-mobile-toggle .icon {
  font-size: 1.375rem;
}

.sidebar-toggle {
  display: none;
}

@media (min-width: 1200px) {
  .sidebar-toggle {
    display: inline-block;
  }
}
.sidebar-toggle.active .icon.non-active {
  display: none;
}

.sidebar-toggle.active .icon.active {
  display: inline-block;
}

.sidebar-toggle .icon.active {
  display: none;
}

.sidebar-mobile-toggle {
  display: inline-block;
}

@media (min-width: 1200px) {
  .sidebar-mobile-toggle {
    display: none;
  }
}
.navbar-search {
  position: relative;
  display: none;
}

@media (min-width: 992px) {
  .navbar-search {
    display: inline-block;
  }
}
.navbar-search input {
  width: 24.25rem;
  height: 2.5rem;
  background-color: var(--neutral-50);
  border: 1px solid var(--input-form-light);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  padding-block: 0.3125rem;
  padding-inline-start: 2.625rem;
  padding-inline-end: 1.25rem;
  color: var(--text-primary-light);
}

.navbar-search input:focus {
  border-color: var(--brand);
}

.navbar-search .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  inset-inline-start: 0.9375rem;
  font-size: 1.125rem;
  color: var(--black);
}

[data-theme-toggle] {
  font-size: 0;
  position: relative;
}

[data-theme-toggle]::after {
  position: absolute;
  content: "\f1bf";
  font-family: remixicon;
  font-style: normal;
  font-size: 1.25rem;
  color: var(--text-primary-light);
}

[data-theme-toggle][aria-label="dark"]::after {
  content: "\ef6f";
  color: #fff;
}

/* === navbar css end === */
/* === sidebar css start === */
.sidebar {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: -100%;
  width: 15.625rem;
  height: 100vh;
  background-color: var(--white);
  transition: all 0.3s;
  z-index: 3;
}

@media (min-width: 1200px) {
  .sidebar {
    inset-inline-start: 0;
    width: 13.75rem;
  }
}
@media (min-width: 1400px) {
  .sidebar {
    width: 17.1875rem;
  }
}
@media (min-width: 1650px) {
  .sidebar {
    width: 19.5rem;
  }
}
.sidebar-close-btn {
  position: absolute;
  top: 0.625rem;
  inset-inline-end: 0.625rem;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid var(--input-form-light);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .sidebar-close-btn {
    display: none;
  }
}
.sidebar.sidebar-open {
  inset-inline-start: 0;
}

.sidebar.active {
  width: auto;
}

.sidebar.active .sidebar-menu li a i {
  margin-inline-end: 0;
}

@media (min-width: 1200px) {
  .sidebar.active:hover {
    inset-inline-start: 0;
  }
}
@media (min-width: 1400px) {
  .sidebar.active:hover {
    width: 17.1875rem;
  }
}
@media (min-width: 1650px) {
  .sidebar.active:hover {
    width: 19.5rem;
  }
}
.sidebar.active:hover .sidebar-logo img.light-logo {
  display: inline-block;
}

.sidebar.active:hover .sidebar-logo img.logo-icon {
  display: none;
}

.sidebar.active:hover .sidebar-menu li a span {
  display: inline-block;
}

.sidebar.active:hover .sidebar-menu li a .menu-icon {
  margin-inline-end: 0.5rem;
}

.sidebar.active:hover .sidebar-menu li.dropdown.dropdown-open .sidebar-submenu,
.sidebar.active:hover .sidebar-menu li.dropdown.open .sidebar-submenu {
  display: block !important;
}

.sidebar.active:hover .sidebar-menu li.sidebar-menu-group-title {
  display: inline-block;
}

.sidebar.active:hover .sidebar-menu li.dropdown a::after {
  display: inline-block;
}

.sidebar.active .sidebar-logo img.light-logo,
.sidebar.active .sidebar-logo img.dark-logo {
  display: none;
}

.sidebar.active .sidebar-logo img.logo-icon {
  display: inline-block;
}

.sidebar.active .sidebar-menu li a span {
  display: none;
}

.sidebar.active .sidebar-menu li a .menu-icon {
  margin-inline-end: 0;
}

.sidebar.active .sidebar-menu li.sidebar-menu-group-title {
  display: none;
}

.sidebar.active .sidebar-menu li.dropdown.dropdown-open .sidebar-submenu,
.sidebar.active .sidebar-menu li.dropdown.open .sidebar-submenu {
  display: none !important;
}

.sidebar.active .sidebar-menu li.dropdown a::after {
  display: none;
}

.sidebar-logo {
  height: 4.5rem;
  padding: 0.875rem 1rem;
  border-inline-end: 1px solid var(--neutral-200);
  border-block-end: 1px solid var(--neutral-200);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sidebar-logo img {
  max-height: 3.4375rem;
}

.sidebar-logo img.dark-logo {
  display: none;
}

.sidebar-logo img.logo-icon {
  display: none;
}

.sidebar-menu-area {
  height: calc(100vh - 72px);
  padding: 0.75rem 1rem;
  overflow-y: auto;
  border-inline-end: 1px solid var(--neutral-200);
}

.sidebar-menu-area:hover::-webkit-scrollbar-thumb {
  background-color: var(--neutral-200);
}

.sidebar-menu-area::-webkit-scrollbar {
  width: 6px;
  background-color: var(--white);
}

.sidebar-menu-area::-webkit-scrollbar-thumb {
  background-color: var(--white);
}

[data-theme="dark"] .sidebar.active:hover .sidebar-logo img.light-logo {
  display: none;
}

[data-theme="dark"] .sidebar.active:hover .sidebar-logo img.dark-logo {
  display: inline-block;
}

[data-theme="dark"] .sidebar.active .sidebar-logo img.light-logo {
  display: none;
}

[data-theme="dark"] .sidebar-logo img.light-logo {
  display: none;
}

[data-theme="dark"] .sidebar-logo img.dark-logo {
  display: inline-block;
}

.sidebar-menu li.dropdown > a {
  position: relative;
}

.sidebar-menu li.dropdown > a::after {
  position: absolute;
  content: "\ea6e";
  font-family: remixicon;
  font-style: normal;
  inset-block-start: 50%;
  inset-inline-end: 0.75rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.125rem;
  /* -webkit-transition: all 0.3s; */
  /* -o-transition: all 0.3s; */
  /* transition: all 0.3s; */
}

@media (min-width: 1650px) {
  .sidebar-menu li.dropdown > a::after {
    font-size: 1.375rem;
  }
}
.sidebar-menu li.dropdown.open > a,
.sidebar-menu li.dropdown.dropdown-open > a {
  background-color: var(--brand);
  color: #fff;
}

.sidebar-menu li.dropdown.open > a:hover,
.sidebar-menu li.dropdown.dropdown-open > a:hover {
  color: #fff;
}

.sidebar-menu li.dropdown.open > a::after,
.sidebar-menu li.dropdown.dropdown-open > a::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.sidebar-menu li.dropdown.open .sidebar-submenu {
  display: block;
}

.sidebar-menu li > a.active-page {
  background-color: var(--brand);
  color: #fff;
}

.sidebar-menu li > a.active-page:hover {
  color: #fff;
}

.sidebar-menu li a {
  padding: 0.625rem 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--text-secondary-light);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-size: 0.875rem;
}

@media (min-width: 1650px) {
  .sidebar-menu li a {
    font-size: 1rem;
  }
}
.sidebar-menu li a:hover {
  color: var(--brand);
}

.sidebar-menu li a .menu-icon {
  font-size: 1.125rem;
  margin-inline-end: 0.5rem;
}

@media (min-width: 1650px) {
  .sidebar-menu li a .menu-icon {
    font-size: 1.375rem;
    margin-inline-end: 0.75rem;
  }
}
.sidebar-menu li a i {
  line-height: 1.2;
  width: 24px;
  margin-inline-end: 0.75rem;
  font-size: 1.375rem;
}

.sidebar-menu li a .circle-icon {
  font-size: 0.625rem;
}

.sidebar-menu .sidebar-submenu {
  padding-block-start: 0.75rem;
  display: none;
  padding-inline-start: 1.5rem;
  transition: none;
}

@media (min-width: 1650px) {
  .sidebar-menu .sidebar-submenu {
    padding-inline-start: 2.75rem;
  }
}
.sidebar-menu .sidebar-submenu li.active-page a {
  background-color: var(--button-secondary);
  color: var(--text-primary-light);
}

.sidebar-menu .sidebar-submenu li a {
  padding: 0.4375rem 0.75rem;
}

.sidebar-menu .sidebar-menu-group-title {
  color: var(--neutral-500);
  font-weight: 600;
  margin-block: 0.5rem;
  font-size: 0.875rem;
}

@media (min-width: 1650px) {
  .sidebar-menu .sidebar-menu-group-title {
    font-size: 1rem;
  }
}
/* === sidebar css end === */
/* === breadcrumb css start === */
/* === breadcrumb css end === */
/* === dashboard body css start === */
.dashboard-main {
  margin-inline-start: 0;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  min-height: 100vh;
  transition: all 0.3s;
}

@media (min-width: 1200px) {
  .dashboard-main {
    margin-inline-start: 13.75rem;
  }
}
@media (min-width: 1400px) {
  .dashboard-main {
    margin-inline-start: 17.1875rem;
  }
}
@media (min-width: 1650px) {
  .dashboard-main {
    margin-inline-start: 19.5rem;
  }
}
.dashboard-main-body {
  padding: 0.9375rem;
}

@media (min-width: 1400px) {
  .dashboard-main-body {
    padding: 1.5rem;
  }
}
.dashboard-main.active {
  margin-inline-start: 5.375rem;
}

/* === dashboard body css end === */
/* === footer css start === */
.d-footer {
  padding: 1.375rem 1.5rem;
  background-color: var(--white);
  margin-block-start: auto;
}

/* === footer css end === */
